import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//MUI
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PasswordRoundedIcon from "@mui/icons-material/PasswordRounded";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

//Imports
import MyButton from "../utility/MyButton";
import logo from "../images/logo.png";
import NavInner from "./NavInner";
//AWS
import { Auth } from "aws-amplify";
import { DataStore } from "aws-amplify";
import { User } from "../models";
import { GuestList } from "../models";
import { Event } from "../models";

const styles = (theme) => ({
  ...theme.spreadThis,
  logo: { height: 60 },
  contentContainer: { marginTop: 50 },
  inputSection: { textAlign: "left", marginBottom: 30 },
  labelTxt: { paddingBottom: 10 },
});

const initialFormState = {
  email: "",
  name: "",
  oldPass: "",
  newPass: "",
  confirmPass: "",
  planner: "",
  show: false,
};

const Settings = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [error2, setError2] = useState(null);
  const [message2, setMessage2] = useState(null);
  const [getData, setgetDate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [id, setId] = useState(null);
  const [myName, setMyname] = useState(null);
  const [email, setEmail] = useState(null);
  const [account, setAccount] = useState(null);
  const [planner, setPlanner] = useState(null);
  const [numEvent, setNumevents] = useState(null);
  const [guestCount, setGuestsCount] = useState(null);
  const [formState, updateFormState] = useState(initialFormState);

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const userId = localStorage.getItem("userId");
      const user = await Auth.currentAuthenticatedUser();
      const allUsers = await DataStore.query(User);
      const allEvent = await DataStore.query(Event);
      const allGuests = await DataStore.query(GuestList);

      if (user == null) {
        setgetDate(false);
        navigate("/");
      } else {
        await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
          .then((user) => {
            setEmail(user.attributes.email);
            const filteredId = allUsers
              .filter((use) => use.email === user.attributes.email)
              .map((amm) => amm.id);
            setId(String(filteredId));

            const filteredName = allUsers
              .filter((use) => use.email === user.attributes.email)
              .map((amm) => amm.fullname);
            setMyname(String(filteredName));

            const filteredPlanner = allUsers
              .filter((use) => use.email === user.attributes.email)
              .map((amm) => amm.planner);
            setPlanner(String(filteredPlanner));

            const filteredAccount = allUsers
              .filter((use) => use.email === user.attributes.email)
              .map((amm) => amm.account);
            setAccount(String(filteredAccount));

            const filterEvents = allEvent.filter(
              (evn) => evn.userId === userId && evn.creator === true
            );

            setNumevents(filterEvents.length);

            const filterGuests = allGuests.filter(
              (evn) => evn.userId === userId
            );
            setGuestsCount(filterGuests.length);
          })
          .catch((err) => setError(err));
        setgetDate(false);
      }
    } catch (error) {
      setgetDate(false);
      console.log("error confirming:", error);
    }
  }

  function onChange(e) {
    // e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  }

  function showMe() {
    setShow(!show);
  }

  async function updateSettings(id) {
    try {
      setLoading(true);

      setMessage(null);

      setError(null);

      const { name, planner } = formState;

      const myccc = await DataStore.query(User, id);

      const conName = name === "" ? myName : name;

      await DataStore.save(
        User.copyOf(myccc, (myUpdate) => {
          myUpdate.fullname = conName;
          myUpdate.planner = planner;
        })
      );
      setMessage("Profile update successful");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  async function changePasswords() {
    try {
      setLoading2(true);

      setMessage2(null);

      setError2(null);

      const { oldPass, newPass, confirmPass } = formState;

      if (newPass === confirmPass) {
        Auth.currentAuthenticatedUser()
          .then((user) => {
            return Auth.changePassword(user, oldPass, newPass);
          })
          .then((data) => {
            setMessage2("Password change successful");
            console.log("RESET", data);
            setLoading2(false);
          })
          .catch((err) => {
            setError2("Value of previous password failed");
            console.log("RESET", err);
            setLoading2(false);
          });
      } else {
        setError2("Password does not match");
      }
    } catch (error) {
      setLoading2(false);
      setError2("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  return getData ? (
    <Fragment>
      <Grid container>
        <Grid item md={4} align="center"></Grid>
        <Grid item md={4} align="center" style={{ padding: "7%" }}>
          <img
            src={logo}
            className={classes.icon}
            style={{ width: "100%" }}
            alt="loading"
          />
          <LinearProgress
            color="primary"
            style={{ width: "100%", marginTop: 10 }}
          />
        </Grid>
        <Grid item md={4} align="center"></Grid>
      </Grid>
    </Fragment>
  ) : (
    <Fragment>
      <NavInner />
      <Container maxWidth="lg">
        <Grid
          container
          alignItems="center"
          className={classes.contentContainer}
        >
          <Grid item sm={8} xs={12} style={{ padding: 10 }}>
            <div style={{ marginTop: "30px" }}>
              <Paper elevation={2} style={{ minHeight: 50, padding: 50 }}>
                <div style={{ marginBottom: "10px", textAlign: "left" }}>
                  <Typography variant="h5" style={{ fontWeight: "bolder" }}>
                    <PersonOutlineRoundedIcon /> Profile Settings
                  </Typography>
                </div>
                <hr />
                <div className={classes.inputSection} style={{ marginTop: 20 }}>
                  <Typography
                    variant="caption"
                    style={{
                      color: "#707070",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      letterSpacing: 1,
                    }}
                  >
                    Email Address
                  </Typography>
                  <TextField
                    size="small"
                    color="primary"
                    type="email"
                    name="email"
                    value={email}
                    onChange={onChange}
                    variant="standard"
                    autoFocus
                    fullWidth
                    disabled
                  />
                </div>

                <div className={classes.inputSection}>
                  <Typography
                    variant="caption"
                    style={{
                      color: "#707070",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      letterSpacing: 1,
                    }}
                  >
                    Name
                  </Typography>
                  <TextField
                    size="small"
                    color="primary"
                    variant="outlined"
                    type="text"
                    name="name"
                    defaultValue={myName}
                    onChange={onChange}
                    fullWidth
                    required
                  />
                </div>

                <div className={classes.inputSection}>
                  <Typography
                    variant="caption"
                    style={{
                      color: "#707070",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      letterSpacing: 1,
                    }}
                  >
                    I'm an event planner
                  </Typography>

                  <Select
                    variant="outlined"
                    size="small"
                    name="planner"
                    defaultValue={planner}
                    onChange={onChange}
                    fullWidth
                  >
                    <MenuItem value={true}>Yes, I'm</MenuItem>
                    <MenuItem value={false}>No, I'm not</MenuItem>
                  </Select>
                </div>

                {error === null ? (
                  message === null ? null : (
                    <div
                      style={{
                        marginBottom: 20,
                        marginTop: "-10px",
                        textAlign: "right",
                      }}
                    >
                      <Typography variant="caption" color="primary">
                        {message}
                      </Typography>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      marginBottom: 20,
                      marginTop: "-10px",
                      textAlign: "right",
                    }}
                  >
                    <Typography variant="caption" color="error">
                      {error}
                    </Typography>
                  </div>
                )}

                <div
                  className={classes.inputSection}
                  style={{ textAlign: "right" }}
                >
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    style={{ fontSize: 13, marginBottom: 20 }}
                    disabled={loading}
                    onClick={() => updateSettings(id)}
                  >
                    Save Changes
                    {loading && (
                      <CircularProgress
                        color="primary"
                        size={30}
                        className={classes.progress}
                      />
                    )}
                  </Button>
                </div>
              </Paper>
            </div>
          </Grid>

          <Grid item sm={4} xs={12} style={{ padding: 10 }}>
            <div style={{ marginTop: "30px" }}>
              <Paper elevation={2} style={{ minHeight: 50, padding: 50 }}>
                <div style={{ marginBottom: "10px", textAlign: "left" }}>
                  <Typography variant="h5" style={{ fontWeight: "bolder" }}>
                    <PasswordRoundedIcon /> Password Settings
                  </Typography>
                </div>

                <hr />
                <div className={classes.inputSection}>
                  <Typography
                    variant="caption"
                    style={{
                      color: "#707070",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      letterSpacing: 1,
                    }}
                  >
                    Old Password
                  </Typography>

                  <TextField
                    size="small"
                    color="primary"
                    variant="outlined"
                    type={show ? "text" : "password"}
                    name="oldPass"
                    onChange={onChange}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {show ? (
                            <MyButton onClick={showMe} tip="View Password">
                              <VisibilityOffIcon color="disabled" />
                            </MyButton>
                          ) : (
                            <MyButton onClick={showMe} tip="View Password">
                              <VisibilityIcon color="disabled" />
                            </MyButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </div>

                <div className={classes.inputSection}>
                  <Typography
                    variant="caption"
                    style={{
                      color: "#707070",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      letterSpacing: 1,
                    }}
                  >
                    New Password
                  </Typography>
                  <TextField
                    size="small"
                    name="newPass"
                    color="primary"
                    variant="outlined"
                    type={show ? "text" : "password"}
                    onChange={onChange}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {show ? (
                            <MyButton onClick={showMe} tip="View Password">
                              <VisibilityOffIcon color="disabled" />
                            </MyButton>
                          ) : (
                            <MyButton onClick={showMe} tip="View Password">
                              <VisibilityIcon color="disabled" />
                            </MyButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </div>

                <div className={classes.inputSection}>
                  <Typography
                    variant="caption"
                    style={{
                      color: "#707070",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      letterSpacing: 1,
                    }}
                  >
                    Confirm Password
                  </Typography>
                  <TextField
                    size="small"
                    name="confirmPass"
                    color="primary"
                    variant="outlined"
                    type="password"
                    onChange={onChange}
                    fullWidth
                    required
                  />
                </div>

                {error2 === null ? (
                  message2 === null ? null : (
                    <div
                      style={{
                        marginBottom: 20,
                        marginTop: "-10px",
                        textAlign: "right",
                      }}
                    >
                      <Typography variant="caption" color="primary">
                        {message2}
                      </Typography>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      marginBottom: 20,
                      marginTop: "-10px",
                      textAlign: "right",
                    }}
                  >
                    <Typography variant="caption" color="error">
                      {error2}
                    </Typography>
                  </div>
                )}

                <div className={classes.inputSection}>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={loading2}
                    style={{ fontSize: 13, marginBottom: 20 }}
                    onClick={changePasswords}
                    fullWidth
                  >
                    Change Password
                    {loading2 && (
                      <CircularProgress
                        color="primary"
                        size={30}
                        className={classes.progress}
                      />
                    )}
                  </Button>
                </div>
              </Paper>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          className={classes.contentContainer}
          style={{ marginBottom: 50 }}
        >
          <Grid item sm={12} xs={12} style={{ padding: 10 }}>
            <Paper elevation={2} style={{ minHeight: 50, padding: 50 }}>
              <div style={{ marginBottom: "10px", textAlign: "left" }}>
                <Typography variant="h5" style={{ fontWeight: "bolder" }}>
                  <InfoOutlinedIcon /> Profile Details
                </Typography>
              </div>
              <hr />

              <Grid container alignItems="center">
                <Grid item sm={4} xs={12} style={{ padding: 10 }}>
                  <div style={{ marginBottom: "10px", textAlign: "left" }}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold" }}
                    >
                      Current Plan
                    </Typography>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: "bolder" }}
                      color="primary"
                    >
                      {account}
                    </Typography>
                    {account === "Free" ? (
                      <Fragment>
                        <Typography variant="caption" color="GrayText">
                          You have no active paid subscription.
                        </Typography>
                        <div style={{ marginTop: 10 }}>
                          <Button
                            size="small"
                            variant="contained"
                            component={Link}
                            to="/pricing"
                            color="primary"
                            style={{ fontSize: 13, marginBottom: 20 }}
                          >
                            VIEW & SWITCH PLANS
                          </Button>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Typography variant="caption" color="GrayText">
                          You have an active paid subscription.
                        </Typography>
                        <div style={{ marginTop: 10 }}>
                          <Button
                            size="small"
                            variant="contained"
                            component={Link}
                            to="/pricing"
                            color="primary"
                            style={{ fontSize: 13, marginBottom: 20 }}
                          >
                            VIEW & SWITCH PLANS
                          </Button>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </Grid>

                <Grid
                  item
                  sm={8}
                  xs={12}
                  style={{ padding: 10, borderLeft: "1px solid #E1E1E1" }}
                >
                  <div style={{ textAlign: "left" }}>
                    <Typography
                      variant="h6"
                      style={{
                        color: "#333",
                        fontSize: 13,
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      Active Events
                    </Typography>

                    {account === "Free" ? (
                      <Fragment>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#707070",
                            float: "right",
                            fontSize: 13,
                            marginTop: "-25px",
                            letterSpacing: 1,
                          }}
                        >
                          {numEvent} of 1 used
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={numEvent === 1 ? "100" : "0"}
                          color="primary"
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#707070",
                            float: "right",
                            fontSize: 13,
                            marginTop: "-25px",
                            letterSpacing: 1,
                          }}
                        >
                          {numEvent} of Unlimited used
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={numEvent}
                          color="primary"
                        />
                      </Fragment>
                    )}
                  </div>

                  <div
                    style={{
                      textAlign: "left",
                      marginTop: 30,
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        color: "#333",
                        fontSize: 13,
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      RSVPs
                    </Typography>

                    {account === "Free" ? (
                      <Fragment>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#707070",
                            float: "right",
                            fontSize: 13,
                            marginTop: "-25px",
                            letterSpacing: 1,
                          }}
                        >
                          {guestCount} of 50 used
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={guestCount * 2}
                          color="primary"
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#707070",
                            float: "right",
                            fontSize: 13,
                            marginTop: "-25px",
                            letterSpacing: 1,
                          }}
                        >
                          {guestCount} of Unlimited
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={guestCount / 100}
                          color="primary"
                        />
                      </Fragment>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);
