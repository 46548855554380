import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//MUI
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import PersonIcon from "@mui/icons-material/Person";
import EventNoteIcon from "@mui/icons-material/EventNote";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import GradingIcon from "@mui/icons-material/Grading";
import Divider from "@mui/material/Divider";
//Import
import Nav from "./Nav";
import Footer from "./Footer";
//AWS
import { Auth } from "aws-amplify";
//Images
import centreImg from "../images/backgroundimg.jpg";
import sampleinvite from "../images/sampleinvite.png";
import features from "../images/features.jpg";
import menu from "../images/menu.png";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const Home = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user != null) {
        navigate("/dashboard");
      }
    } catch (error) {
      console.log("error confirming:", error);
    }
  }

  return (
    <Fragment>
      <Nav />
      <div
        style={{
          background: `url(${centreImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "75vh",
          marginTop: 60,
          width: "100%",
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            alignItems="center"
            style={{ minHeight: "75vh", padding: 30 }}
          >
            <Grid item lg={6}>
              <Typography
                variant="h3"
                color="white"
                style={{
                  fontWeight: "bold",
                  lineHeight: 1.3,
                  marginTop: "-50px",
                }}
              >
                Enterprise-grade Guest Management Tool
              </Typography>
              <Typography variant="subtitle1" color="white">
                Premium guest management tool. Power your event from invitation,
                to online RSVP & seat charting, to check-in.
              </Typography>
              <div style={{ marginTop: 20 }}>
                <Button
                  size="large"
                  color="secondary"
                  variant="contained"
                  disableElevation
                  component={Link}
                  to="/signup"
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    fontWeight: "bold",
                    fontSize: 13,
                    color: "#000",
                  }}
                >
                  Get Started
                </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          minHeight: "75vh",
          width: "100%",
        }}
      >
        <Container maxWidth="xl">
          <Grid container style={{ minHeight: "50vh", padding: 30 }}>
            <Grid item lg={6} style={{ padding: 50 }}>
              <div style={{ marginBottom: 20 }}>
                <EventNoteIcon
                  style={{
                    fontSize: 40,
                    padding: 20,
                    background: "#6665A7",
                    borderRadius: "100%",
                  }}
                  color="secondary"
                />
              </div>
              <Typography
                variant="h4"
                style={{
                  fontWeight: "bold",
                  lineHeight: 1.3,
                  color: "#000",
                }}
              >
                Manage event guests with ease
              </Typography>
              <Typography variant="subtitle1">
                Tools needed to manage audience online or offline from start to
                finish
              </Typography>
              <img src={features} style={{ width: "100%" }} alt="features" />
            </Grid>
            <Grid item lg={6}>
              <img src={sampleinvite} style={{ width: "100%" }} alt="preview" />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          minHeight: "75vh",

          width: "100%",
        }}
      >
        <Container maxWidth="xl">
          <Grid container style={{ minHeight: "50vh", padding: 30 }}>
            <Grid item lg={7}>
              <img src={menu} style={{ width: "100%" }} alt="menuSample" />
            </Grid>
            <Grid item lg={5} style={{ padding: 50 }}>
              <div style={{ marginBottom: 20, textAlign: "right" }}>
                <DynamicFormIcon
                  style={{
                    fontSize: 40,
                    padding: 20,
                    color: "#6665A7",
                    background: "#F1F97A",
                    borderRadius: "100%",
                  }}
                />
              </div>
              <Typography
                variant="h4"
                style={{
                  fontWeight: "bold",
                  lineHeight: 1.3,
                  color: "#000",
                }}
              >
                Create standard customizable online RSVP forms
              </Typography>
              <Typography variant="subtitle1">
                From layout, getting the perfect data needed, online invitation
                to online payment collection, Guestpin gives you lots of control
                over your event's RSVP.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>

      <div
        style={{
          minHeight: "75vh",
          background: "#E0E0F5",
          width: "100%",
        }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            style={{ minHeight: "50vh", padding: 30, textAlign: "center" }}
          >
            <Grid item lg={12}>
              <GradingIcon
                style={{
                  fontSize: 40,
                  padding: 20,
                  color: "#F1F97A",
                  background: "#000",
                  borderRadius: "100%",
                }}
              />
              <Typography
                variant="h4"
                style={{
                  fontWeight: "bold",
                  lineHeight: 1.3,
                  color: "#000",
                }}
              >
                Users Feedback
              </Typography>

              <Grid container style={{ marginTop: 20 }}>
                <Grid item lg={4} style={{ padding: 30 }}>
                  <div
                    style={{
                      background: "#fff",
                      borderRadius: 5,
                      minHeight: 400,
                      padding: 10,
                    }}
                  >
                    <PersonIcon
                      style={{
                        fontSize: 40,
                        padding: 20,
                        color: "#6665A6",
                        background: "#FDFFD0",
                        borderRadius: "100%",
                        marginTop: "-40px",
                      }}
                    />
                    <div style={{ padding: 15 }}>
                      <Typography
                        variant="body1"
                        style={{ color: "#707070", lineHeight: 2 }}
                      >
                        A great product to use for your events! I was impressed
                        by the online invitation & the RSVP feature. Their
                        customer care team is helpful with any questions you may
                        have and are ready to support with tips.
                      </Typography>
                    </div>
                    <div style={{ padding: 50 }}>
                      <Divider variant="middle" />
                      <Typography variant="body2" style={{ marginTop: 10 }}>
                        Erica Samuel
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{
                          color: "GrayText",
                        }}
                      >
                        Planner
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item lg={4} style={{ padding: 30 }}>
                  <div
                    style={{
                      background: "#fff",
                      borderRadius: 5,
                      minHeight: 400,
                      padding: 10,
                    }}
                  >
                    <PersonIcon
                      style={{
                        fontSize: 40,
                        padding: 20,
                        color: "#6665A6",
                        background: "#FDFFD0",
                        borderRadius: "100%",
                        marginTop: "-40px",
                      }}
                    />
                    <div style={{ padding: 15 }}>
                      <Typography
                        variant="body1"
                        style={{ color: "#707070", lineHeight: 2 }}
                      >
                        Guestpin saved me the hassle of chasing people down to
                        confirm if they were attending our wedding by
                        simplifying the process of connecting with all
                        attendees. I was able to import all the contact info and
                        just hit send.
                      </Typography>
                    </div>
                    <div style={{ padding: 50 }}>
                      <Divider variant="middle" />
                      <Typography variant="body2" style={{ marginTop: 10 }}>
                        Parisa & Frank
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{
                          color: "GrayText",
                        }}
                      >
                        Intending Couples
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item lg={4} style={{ padding: 30 }}>
                  <div
                    style={{
                      background: "#fff",
                      borderRadius: 5,
                      minHeight: 400,
                      padding: 10,
                    }}
                  >
                    <PersonIcon
                      style={{
                        fontSize: 40,
                        padding: 20,
                        color: "#6665A6",
                        background: "#FDFFD0",
                        borderRadius: "100%",
                        marginTop: "-40px",
                      }}
                    />
                    <div style={{ padding: 15 }}>
                      <Typography
                        variant="body1"
                        style={{ color: "#707070", lineHeight: 2 }}
                      >
                        We used Guestpin for our Christmas Party this year. The
                        software allowed our users to enter their details and
                        provided summarized information we could use. The
                        software is so easy to use and helped us organize well.
                      </Typography>
                    </div>

                    <div style={{ padding: 50 }}>
                      <Divider variant="middle" />
                      <Typography variant="body2" style={{ marginTop: 10 }}>
                        David Omacon
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{
                          color: "GrayText",
                        }}
                      >
                        Event Owner
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>

      <Footer />
    </Fragment>
  );
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);

/*


*/
