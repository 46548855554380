import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import moment from "moment";
//MUI
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
//Imports
import logo from "../images/logo.png";
import confirmrsvp from "../images/confirmrsvp.png";

//AWS
import { DataStore } from "aws-amplify";
import { User } from "../models";
import { Event } from "../models";
import { Menu } from "../models";
import { GuestList } from "../models";

const styles = (theme) => ({
  ...theme.spreadThis,
  logo: { height: 60 },
  contentContainer: { marginTop: 50 },
  inputSection: { textAlign: "left", marginBottom: 20 },
  labelTxt: { paddingBottom: 10 },
});

const initialFormState = {
  titleName: "",
  firstName: "",
  status: "",
  type: "",
  email: "",
  phone: "",
  search: "",
};

const Rsvp = (props) => {
  const { classes } = props;
  const { eventUrl } = useParams();
  const [error, setError] = useState(null);
  const [publicState, setPublicState] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [mealActive, setMealActive] = useState(null);
  const [alGuest, setAlGuest] = useState([]);
  const [alUser, setAlUser] = useState([]);
  const [allGuest, setAllGuest] = useState([]);
  const [myEvent, setMyEvent] = useState([]);
  const [myGuest, setMyGuest] = useState([]);
  const [eventGuest, setEventGuest] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [finish, setFinish] = useState(false);
  const [sorted, setSort] = useState("des");
  const [getData, setgetDate] = useState(true);
  const [addedGuest, setAddedGuest] = useState(null);
  const [menuId, setMenuId] = useState(null);
  const [menuNum, setMenuNum] = useState(null);
  const [mealValue, setMealValue] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [guestId, setGuestId] = useState(null);
  const [guestType, setGuestType] = useState(null);
  const [formState, updateFormState] = useState(initialFormState);

  useEffect(() => {
    obersation();
    checkInfo();
  }, []);

  async function checkInfo() {
    try {
      const allUser = await DataStore.query(User);
      const allEvent = await DataStore.query(Event);
      const allMenu = await DataStore.query(Menu);
      const allGuests = await DataStore.query(GuestList);

      setAlGuest(allGuests);
      setAlUser(allUser);

      const filteredUrl = allEvent
        .filter((evn) => evn.eventUrl === eventUrl)
        .map((amm) => amm.id);
      setEventId(String(filteredUrl));

      const filterGuest = allGuests.filter(
        (evn) => evn.eventId === String(filteredUrl) && evn.title !== "+1"
      );
      const sortedGuest = filterGuest.sort((a, b) => {
        const mySort = sorted === "des" ? 1 : -1;
        return mySort * a.firstName.localeCompare(b.firstName);
      });
      setEventGuest(sortedGuest);

      const filteredEvent = allEvent.filter(
        (evn) => evn.id === String(filteredUrl)
      );
      setMyEvent(filteredEvent);

      const filteredMenu = allMenu.filter(
        (evn) => evn.eventId === String(filteredUrl)
      );
      setMenuItems(filteredMenu);

      const filteredPub = allEvent
        .filter((evn) => evn.id === String(filteredUrl))
        .map((amm) => amm.publicState);
      setPublicState(String(filteredPub));

      const filteredMeal = allEvent
        .filter((evn) => evn.id === String(filteredUrl))
        .map((amm) => amm.mealRequired);
      setMealActive(String(filteredMeal));

      const filteredID = allEvent
        .filter((evn) => evn.id === String(filteredUrl))
        .map((amm) => amm.userId);
      setUserId(String(filteredID));

      const filterAllGuest = allGuests.filter(
        (evn) => evn.eventId === String(filteredUrl)
      );
      setAllGuest(filterAllGuest);
      setgetDate(false);
    } catch (error) {
      console.log("error confirming:", error);
    }
  }

  function obersation() {
    DataStore.observe(GuestList).subscribe((msg) => {
      checkInfo();
    });
  }

  function onChange(e) {
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  }

  //Working Search Code
  function onChangeSearch(e) {
    const conde = e.target.value;
    const newSearch = eventGuest.filter((news) =>
      news.firstName
        .toLocaleLowerCase()

        .includes(conde.toLocaleLowerCase())
    );
    setMyGuest(newSearch);
    if (conde === "") {
      setMyGuest([]);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const editGuestInfo = (id, type, createdAt) => {
    setError(null);
    setEditOpen(true);

    setGuestId(id);
    setGuestType(type);

    //updateFormState(() => ({ ...formState, status: "" }));
    updateFormState(() => ({ ...formState, email: "" }));
    updateFormState(() => ({ ...formState, phone: "" }));

    if (type === "Admit two") {
      const filteredAdd = allGuest
        .filter((use) => use.firstName === createdAt)
        .map((amm) => amm.id);
      setAddedGuest(String(filteredAdd));
    }
  };

  const handleClose = () => {
    setEditOpen(false);
  };

  async function handleChange(event) {
    setMealValue(event.target.value);

    const allMenu = await DataStore.query(Menu);

    const filteredMenu = allMenu.filter(
      (evn) =>
        evn.eventId === eventId && evn.mealCategory === event.target.value
    );

    filteredMenu.map((mell) => {
      const id = mell.id;
      const number = mell.guestNumber;
      const newNumber = number + 1;
      setMenuId(id);
      setMenuNum(newNumber);
    });
  }

  async function addGuest() {
    try {
      const { titleName, firstName, status, type, email, phone } = formState;

      if (firstName === "" || status === "" || email === "" || phone === "") {
        setError(
          "An error occuried, check that all fields are filled correctly"
        );
      } else {
        setLoading(true);
        setError(null);

        const newGuest = {
          userId: userId,
          eventId: eventId,
          title: titleName,
          firstName: firstName,
          status: status,
          type: type,
          email: email,
          phone: phone,
          mealPreference: mealValue,
          tableNumber: 0,
          checkIn: false,
          createdAt: new Date().toISOString(),
        };

        const newGuest2 = {
          userId: userId,
          eventId: eventId,
          title: "+1",
          firstName: new Date().toISOString(),
          status: "Attending",
          type: "",
          email: "",
          phone: "",
          mealPreference: "",
          tableNumber: 0,
          checkIn: false,
          createdAt: new Date().toISOString(),
        };

        const filteredAccount = alUser
          .filter((evn) => evn.id === userId)
          .map((amm) => amm.account);

        const filteredGuest = alGuest.filter((evn) => evn.usrId === userId);

        if (filteredAccount === "Free") {
          if (filteredGuest.length >= 50) {
            setError(
              "Oops!!!Event creator as exceeded the number of RSVPs you can manage"
            );
          } else {
            if (type === "Admit two") {
              await DataStore.save(new GuestList(newGuest));
              await DataStore.save(new GuestList(newGuest2));
              setFinish(true);
              setLoading(false);
            } else {
              await DataStore.save(new GuestList(newGuest));
              setFinish(true);
              setLoading(false);
            }
            if (mealValue != null) {
              const myMenu = await DataStore.query(Menu, menuId);
              await DataStore.save(
                Menu.copyOf(myMenu, (myUpd) => {
                  myUpd.guestNumber = menuNum;
                })
              );
            }
          }
        } else {
          if (type === "Admit two") {
            await DataStore.save(new GuestList(newGuest));
            await DataStore.save(new GuestList(newGuest2));
            setFinish(true);
            setLoading(false);
          } else {
            await DataStore.save(new GuestList(newGuest));
            setFinish(true);
            setLoading(false);
          }

          if (mealValue != null) {
            const myMenu = await DataStore.query(Menu, menuId);
            await DataStore.save(
              Menu.copyOf(myMenu, (myUpd) => {
                myUpd.guestNumber = menuNum;
              })
            );
          }
        }
      }
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  async function updateGuest() {
    try {
      const { status, email, phone } = formState;

      const myGst = await DataStore.query(GuestList, guestId);

      const newStatus = status === "" ? "Pending" : status;

      if (email === "" || phone === "") {
        setError(
          "An error occuried, check that all fields are filled correctly"
        );
      } else {
        setLoading(true);
        setError(null);

        if (mealValue != null) {
          const myMenu = await DataStore.query(Menu, menuId);

          await DataStore.save(
            Menu.copyOf(myMenu, (myUpd) => {
              myUpd.guestNumber = menuNum;
            })
          );
        }

        await DataStore.save(
          GuestList.copyOf(myGst, (myUpdate) => {
            myUpdate.status = newStatus;
            myUpdate.email = email;
            myUpdate.phone = phone;
            myUpdate.mealPreference = mealValue;
          })
        );

        if (addedGuest != null) {
          const myGst1 = await DataStore.query(GuestList, addedGuest);
          await DataStore.save(
            GuestList.copyOf(myGst1, (myUpdate) => {
              myUpdate.status = newStatus;
            })
          );
        }

        setLoading(false);
        setFinish(true);
      }
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  let eventDetails = myEvent.map((evnt) => {
    const eventName = evnt.eventName;
    const startDate = evnt.startDate;
    const newStartDate = moment(startDate).format("MMMM Do YYYY");

    return (
      <Grid container>
        <Grid item sm={12} xs={12} align="center" style={{ marginBottom: 30 }}>
          <div>
            <a href="https://guestpin.com" target="_blank" rel="noreferrer">
              <img src={logo} style={{ height: 60 }} alt="loading" />
            </a>
          </div>
          <Grid container>
            <Grid item sm={12} xs={12} style={{ textAlign: "center" }}>
              <Typography variant="h6" style={{ fontWeight: "bold" }}>
                {eventName}
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12} style={{ textAlign: "center" }}>
              <Typography
                variant="body2"
                color="gray"
                style={{ fontWeight: "bold" }}
              >
                {newStartDate}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  let cardType = myEvent.map((evnt) => {
    const publicState = evnt.publicState;
    return (
      <Grid container>
        <Grid item sm={12} xs={12}>
          {publicState ? (
            <Fragment>
              <Typography
                variant="subtitle1"
                style={{
                  textAlign: "left",
                  fontWeight: "bold",
                  letterSpacing: 2,
                }}
              >
                RSVP CARD
              </Typography>
              <hr />
              <div className={classes.inputSection}>
                <Grid container alignItems="center">
                  <Grid item md={4} xs={12}>
                    <Typography
                      variant="caption"
                      style={{
                        color: "#333",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      TITLE
                    </Typography>
                    <TextField
                      size="small"
                      color="primary"
                      variant="outlined"
                      type="text"
                      placeholder="Mr. & Mrs"
                      name="titleName"
                      onChange={onChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <div style={{ padding: 5 }}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#333",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        Full Name
                      </Typography>
                      <TextField
                        size="small"
                        color="primary"
                        variant="outlined"
                        type="text"
                        name="firstName"
                        onChange={onChange}
                        fullWidth
                        required
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.inputSection}>
                <Grid container alignItems="center">
                  <Grid item md={6} xs={12}>
                    <div style={{ padding: 5 }}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#333",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        Status
                      </Typography>
                      <Select
                        variant="outlined"
                        size="small"
                        name="status"
                        onChange={onChange}
                        required
                        fullWidth
                      >
                        <MenuItem value="Attending">
                          I would be attending this event
                        </MenuItem>
                        <MenuItem value="Not Sure">
                          Not sure I would be attending this event
                        </MenuItem>
                        <MenuItem value="Absent">
                          I wouldn't be attending this event
                        </MenuItem>
                      </Select>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div style={{ padding: 5 }}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#333",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        Would you be coming with an extra guest?
                      </Typography>
                      <Select
                        variant="outlined"
                        size="small"
                        name="type"
                        onChange={onChange}
                        required
                        fullWidth
                      >
                        <MenuItem value="Admit two">Yes</MenuItem>
                        <MenuItem value="Admit one">No</MenuItem>
                      </Select>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.inputSection}>
                <Grid container alignItems="center">
                  <Grid item md={6} xs={12}>
                    <div style={{ padding: 5 }}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#333",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        EMAIL ADDRESS
                      </Typography>
                      <TextField
                        size="small"
                        color="primary"
                        variant="outlined"
                        type="email"
                        name="email"
                        onChange={onChange}
                        fullWidth
                        required
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div style={{ padding: 5 }}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#333",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        PHONE NUMBER
                      </Typography>
                      <TextField
                        size="small"
                        color="primary"
                        variant="outlined"
                        type="text"
                        name="phone"
                        onChange={onChange}
                        fullWidth
                        required
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              {mealActive === "true" ? (
                <div className={classes.inputSection}>
                  <Typography
                    variant="caption"
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      letterSpacing: 1,
                    }}
                  >
                    Meal Preference
                  </Typography>

                  <FormControl style={{ width: "100%" }}>
                    <RadioGroup onChange={handleChange}>
                      {menuItems.map((mnu, idx) => {
                        const title = mnu.mealCategory;
                        const description = mnu.description;

                        return (
                          <Fragment>
                            <FormControlLabel
                              value={title}
                              control={<Radio />}
                              label={title}
                              key={idx}
                            />
                            <Typography
                              component="div"
                              color="gray"
                              variant="body2"
                            >
                              {description}
                            </Typography>
                          </Fragment>
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </div>
              ) : null}

              <div
                className={classes.inputSection}
                style={{ marginTop: 50, textAlign: "right" }}
              >
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  style={{ fontSize: 13, marginBottom: 10 }}
                  disabled={loading}
                  onClick={addGuest}
                >
                  Submit
                  {loading && (
                    <CircularProgress
                      color="primary"
                      size={30}
                      className={classes.progress}
                    />
                  )}
                </Button>
                {error === null ? null : (
                  <Typography
                    variant="body2"
                    color="red"
                    gutterBottom
                    component="div"
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    {error}
                  </Typography>
                )}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <Typography
                variant="h4"
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                }}
              >
                GuestList
              </Typography>
              <hr />
              <Typography
                variant="body2"
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                Step 1 - Enter your name in the search box below
              </Typography>
              <Typography
                variant="body2"
                style={{
                  marginBottom: 30,
                }}
              >
                Step 2 - Click on the "PENDING BUTTON" after search to make
                reservation
              </Typography>

              <div style={{ marginBottom: 20 }}>
                <TextField
                  id="search"
                  variant="filled"
                  size="large"
                  name="search"
                  type="text"
                  autoFocus
                  onChange={onChangeSearch}
                  color="primary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter name"
                  fullWidth
                />
              </div>

              {myGuest.length === 0 ? (
                <Typography
                  variant="caption"
                  component="div"
                  color="error"
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  Note - If your name doesn't appear, you are not on the
                  GuestList. Kindly Contact event organizers
                </Typography>
              ) : null}
            </Fragment>
          )}
        </Grid>
      </Grid>
    );
  });

  let guestStruct =
    myGuest.length === 0
      ? null
      : myGuest
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((gest, idx) => {
            const id = gest.id;
            const titleName = gest.title;
            const firstName = gest.firstName;
            const status = gest.status;
            const type = gest.type;
            const createdAt = gest.createdAt;
            const tableNumber = gest.tableNumber;

            return (
              <TableBody key={idx}>
                <TableRow hover role="none" tabIndex={-1}>
                  <TableCell align="left">
                    <Typography variant="body2">{titleName}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">{firstName}</Typography>
                  </TableCell>

                  <TableCell align="right">
                    {status === "Pending" ? (
                      <Button
                        onClick={() => editGuestInfo(id, type, createdAt)}
                        variant="contained"
                        style={{
                          width: "100%",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                        size="small"
                        color="warning"
                      >
                        {status}
                      </Button>
                    ) : (
                      <Typography variant="body2" color="error">
                        Table {tableNumber}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          });

  return getData ? (
    <Fragment>
      <Grid container>
        <Grid item md={4} align="center"></Grid>
        <Grid item md={4} align="center" style={{ padding: "7%" }}>
          <img
            src={logo}
            className={classes.icon}
            style={{ width: "100%" }}
            alt="loading"
          />
          <LinearProgress
            color="primary"
            style={{ width: "100%", marginTop: 10 }}
          />
        </Grid>
        <Grid item md={4} align="center"></Grid>
      </Grid>
    </Fragment>
  ) : finish ? (
    <Fragment>
      <Grid
        container
        alignItems="center"
        style={{
          height: "100vh",
        }}
      >
        <Grid item sm={2} xs={1}></Grid>
        <Grid item sm={8} xs={10}>
          <Paper elevation={2} style={{ padding: 30 }}>
            <Grid container>
              <Grid item sm={2} xs={1}></Grid>
              <Grid item sm={8} xs={10} align="center">
                <div>
                  <img src={logo} style={{ height: 60 }} alt="loading" />
                </div>
                <div>
                  <img
                    src={confirmrsvp}
                    style={{ width: "80%" }}
                    alt="loading"
                  />
                </div>
                <Typography variant="h5" style={{}}>
                  Thank you, we received your reservation for this event
                </Typography>
                <Typography variant="subtitle1" style={{ color: "#333" }}>
                  A mail has been sent to your mailbox containing details of
                  your reservation
                </Typography>
              </Grid>
              <Grid item sm={2} xs={1}></Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={2} xs={1}></Grid>
      </Grid>
    </Fragment>
  ) : publicState === null ? (
    <Fragment>
      <Grid container>
        <Grid item md={4} align="center"></Grid>
        <Grid item md={4} align="center" style={{ padding: "7%" }}>
          <img
            src={logo}
            className={classes.icon}
            style={{ width: "100%" }}
            alt="loading"
          />
          <LinearProgress
            color="primary"
            style={{ width: "100%", marginTop: 10 }}
          />
        </Grid>
        <Grid item md={4} align="center"></Grid>
      </Grid>
    </Fragment>
  ) : (
    <Fragment>
      <Grid
        container
        alignItems="center"
        style={{
          height: "100vh",
        }}
      >
        <Grid item sm={2} xs={1}></Grid>
        <Grid
          item
          sm={8}
          xs={10}
          style={{
            padding: "50px 0px",
          }}
        >
          <Paper
            elevation={2}
            style={{
              padding: 10,
            }}
          >
            {eventDetails}
            {cardType}
            {publicState === "true" ? null : myGuest.length === 0 ? null : (
              <div style={{ minHeight: 300 }}>
                <TableContainer style={{ height: "40vh" }}>
                  <Table stickyHeader sx={{ width: "100%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            fontSize: 13,
                            color: "#707070",
                          }}
                        >
                          TITLE
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontWeight: "bold",
                            fontSize: 13,
                            color: "#707070",
                          }}
                        >
                          Full Name
                        </TableCell>

                        <TableCell
                          align="right"
                          style={{
                            fontWeight: "bold",
                            fontSize: 13,
                            color: "#707070",
                          }}
                        >
                          REPLY
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {guestStruct}
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={eventGuest.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            )}
          </Paper>
        </Grid>
        <Grid item sm={2} xs={1}></Grid>
      </Grid>

      <Dialog open={editOpen} onClose={handleClose}>
        <DialogContent>
          <Fragment>
            <div className={classes.inputSection}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "bold",
                  letterSpacing: 2,
                }}
              >
                RSVP CARD
              </Typography>
              <hr />
            </div>

            <div
              className={classes.inputSection}
              style={{ textAlign: "center", fontWeight: "bold" }}
            >
              <Button
                size="large"
                variant="outlined"
                style={{
                  marginBottom: 10,
                  letterSpacing: 2,
                  fontWeight: "bold",
                }}
                fullWidth
              >
                Access Type - {guestType}
              </Button>
              {guestType === "Admit one" ? (
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Note: Your access type admits only you to this event
                </Typography>
              ) : (
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Note: Your access type admits two persons to this event
                </Typography>
              )}
            </div>

            <div className={classes.inputSection}>
              <Typography
                variant="caption"
                style={{
                  color: "#707070",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  letterSpacing: 1,
                }}
              >
                Reply
              </Typography>
              <Select
                variant="outlined"
                size="small"
                name="status"
                onChange={onChange}
                placeholder="Select Response"
                required
                fullWidth
              >
                <MenuItem value="Attending">
                  I would be attending this event
                </MenuItem>
                <MenuItem value="Not Sure">
                  Not sure I would be attending this event
                </MenuItem>
                <MenuItem value="Absent">
                  I wouldn't be attending this event
                </MenuItem>
              </Select>
            </div>

            <div className={classes.inputSection}>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <div style={{ padding: 5 }}>
                    <Typography
                      variant="caption"
                      style={{
                        color: "#707070",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      Email Address
                    </Typography>
                    <TextField
                      size="small"
                      color="primary"
                      variant="outlined"
                      type="text"
                      name="email"
                      onChange={onChange}
                      fullWidth
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ padding: 5 }}>
                    <Typography
                      variant="caption"
                      style={{
                        color: "#707070",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      Phone Number
                    </Typography>
                    <TextField
                      size="small"
                      color="primary"
                      variant="outlined"
                      type="text"
                      name="phone"
                      onChange={onChange}
                      fullWidth
                      required
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            {mealActive === "true" ? (
              <div className={classes.inputSection}>
                <Typography
                  variant="caption"
                  style={{
                    color: "#707070",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    letterSpacing: 1,
                  }}
                >
                  Meal Preference
                </Typography>

                <FormControl>
                  <RadioGroup onChange={handleChange}>
                    {menuItems.map((mnu, idx) => {
                      const title = mnu.mealCategory;
                      const description = mnu.description;

                      return (
                        <Fragment>
                          <FormControlLabel
                            value={title}
                            control={<Radio />}
                            label={title}
                            key={idx}
                          />
                          <Typography
                            component="div"
                            color="gray"
                            variant="body2"
                          >
                            {description}
                          </Typography>
                        </Fragment>
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </div>
            ) : null}

            <div
              className={classes.inputSection}
              style={{ textAlign: "right" }}
            >
              <Button
                size="large"
                color="primary"
                variant="contained"
                style={{ fontSize: 13, marginBottom: 10 }}
                disabled={loading}
                onClick={updateGuest}
              >
                Submit
                {loading && (
                  <CircularProgress
                    color="primary"
                    size={30}
                    className={classes.progress}
                  />
                )}
              </Button>
              {error === null ? null : (
                <Typography
                  variant="body2"
                  color="red"
                  gutterBottom
                  component="div"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  {error}
                </Typography>
              )}
            </div>
          </Fragment>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

Rsvp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Rsvp);
