import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
//MUI
import withStyles from "@mui/styles/withStyles";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";

//utility
import MyButton from "../utility/MyButton";

const styles = (theme) => ({
  ...theme.spreadThis,
});

const Footer = (props) => {
  const showCurrentYear = new Date().getFullYear();
  return (
    <Fragment>
      <div
        style={{
          minHeight: "30vh",
          background: "#6665A6",
          width: "100%",
        }}
      >
        <Grid container style={{ padding: 40 }}>
          <Grid item lg={4}>
            <Typography
              variant="h6"
              style={{ fontWeight: "bold", color: "#F1F97A" }}
            >
              Guestpin.com
            </Typography>
            <Typography
              variant="body2"
              color="white"
              style={{ lineHeight: 2, marginBottom: 20 }}
            >
              This is a premium guest management system that provides you with
              tools you need to manage your audience both online or offline from
              start to finish.
            </Typography>

            <Typography variant="caption" color="white">
              © {showCurrentYear} Guestpin | All right reserved
            </Typography>
          </Grid>

          <Grid item lg={8}>
            <Grid container>
              <Grid item lg={4} style={{ padding: "0px 50px" }}>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", color: "#FFF" }}
                >
                  About Us
                </Typography>
                <List>
                  <ListItem component={Link} to="#">
                    <ListItemText primary="FAQs" style={{ color: "#E0E1F4" }} />
                  </ListItem>

                  <ListItem component={Link} to="#">
                    <ListItemText
                      primary="Privacy policy"
                      style={{ color: "#E0E1F4" }}
                    />
                  </ListItem>
                  <ListItem component={Link} to="#">
                    <ListItemText
                      primary="Terms of use"
                      style={{ color: "#E0E1F4" }}
                    />
                  </ListItem>
                </List>
              </Grid>

              <Grid item lg={4} style={{ padding: "0px 50px" }}>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", color: "#FFF" }}
                >
                  Sales & Support
                </Typography>
                <List>
                  <ListItem component={Link} to="#">
                    <ListItemText
                      primary="Sales Inquiry"
                      style={{ color: "#E0E1F4" }}
                    />
                  </ListItem>

                  <ListItem component={Link} to="#">
                    <ListItemText
                      primary="Open support ticket"
                      style={{ color: "#E0E1F4" }}
                    />
                  </ListItem>

                  <ListItem component={Link} to="#">
                    <ListItemText
                      primary="Contact Us"
                      style={{ color: "#E0E1F4" }}
                    />
                  </ListItem>
                </List>
              </Grid>

              <Grid item lg={4} style={{ padding: "0px 50px" }}>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    color: "#FFF",
                    textAlign: "left",
                  }}
                >
                  Follow Us
                </Typography>
                <Grid container>
                  <Grid item lg={4} style={{ textAlign: "center" }}>
                    <a
                      href="https://www.facebook.com/Guestpin-110474984960848"
                      target="blank"
                    >
                      <MyButton placement="bottom" tip="Facebook">
                        <FacebookOutlinedIcon
                          color="secondary"
                          fontSize="large"
                        />
                      </MyButton>
                    </a>
                  </Grid>

                  <Grid item lg={4} style={{ textAlign: "center" }}>
                    <a
                      href="https://www.instagram.com/guestpinapp/"
                      target="blank"
                    >
                      <MyButton placement="bottom" tip="Instagram">
                        <InstagramIcon color="secondary" fontSize="large" />
                      </MyButton>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
