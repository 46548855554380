import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

export default ({
  children,
  onClick,
  tip,
  btnClassName,
  tipClassName,
  edge,
  placement,
}) => (
  <Tooltip title={tip} placement={placement} className={tipClassName}>
    <IconButton edge={edge} onClick={onClick} className={btnClassName}>
      {children}
    </IconButton>
  </Tooltip>
);
