import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
//MUI
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import RsvpIcon from "@mui/icons-material/Rsvp";
import Paper from "@mui/material/Paper";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import RedeemIcon from "@mui/icons-material/Redeem";
import EventSeatIcon from "@mui/icons-material/EventSeat";

//Imports
import Nav from "./Nav";
import Footer from "./Footer";

const styles = (theme) => ({
  ...theme.spreadThis,
  logo: { height: 60 },
  contentContainer: { marginTop: 100 },
  contentContainers: { marginTop: 20 },
  inputSection: { textAlign: "left", marginBottom: 30 },
  labelTxt: { paddingBottom: 10 },
});

const Features = (props) => {
  const { classes } = props;

  return (
    <Fragment>
      <Nav />
      <Container
        maxWidth="xl"
        style={{
          minHeight: "75vh",
          marginTop: 70,
          paddingBottom: 70,
        }}
      >
        <Grid
          container
          alignItems="center"
          className={classes.contentContainer}
        >
          <Grid item lg={12} style={{ textAlign: "center" }}>
            <Typography
              variant="h3"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              Guest Management App Features
            </Typography>
            <Typography
              variant="body1"
              style={{ marginTop: 5, marginBottom: 10 }}
            >
              Premium guest management tool. Power your event from invitation,
              to online RSVP & seat charting, to check-in.
            </Typography>
            <Divider />

            <Grid container alignItems="center" style={{ marginTop: 30 }}>
              <Grid
                item
                lg={4}
                style={{ textAlign: "center", padding: 20, minHeight: 150 }}
              >
                <Paper elevation={2} style={{ padding: 20, borderRadius: 20 }}>
                  <RsvpIcon
                    style={{
                      fontSize: 30,
                      padding: 5,
                      background: "#6665A7",
                      borderRadius: "100%",
                    }}
                    color="secondary"
                  />
                  <Typography
                    variant="body1"
                    style={{
                      marginBottom: 5,
                      marginTop: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Online RSVP
                  </Typography>
                  <Divider />
                  <Typography
                    variant="body1"
                    style={{ marginTop: 10, lineHeight: 1.8 }}
                  >
                    Get to know and manage who is going to be at your event,
                    collect RSVP for your private or public event
                  </Typography>
                </Paper>
              </Grid>

              <Grid item lg={4} style={{ textAlign: "center", padding: 20 }}>
                <Paper elevation={2} style={{ padding: 20, borderRadius: 20 }}>
                  <PersonAddAlt1Icon
                    style={{
                      fontSize: 30,
                      padding: 5,
                      background: "#6665A7",
                      borderRadius: "100%",
                    }}
                    color="secondary"
                  />
                  <Typography
                    variant="body1"
                    style={{
                      marginBottom: 5,
                      marginTop: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Event Registration
                  </Typography>
                  <Divider />
                  <Typography
                    variant="body1"
                    style={{ marginTop: 10, lineHeight: 1.8 }}
                  >
                    Manage an event registation process online, let your
                    attendees experience a stress free onboarding
                  </Typography>
                </Paper>
              </Grid>

              <Grid item lg={4} style={{ textAlign: "center", padding: 20 }}>
                <Paper elevation={2} style={{ padding: 20, borderRadius: 20 }}>
                  <RestaurantIcon
                    style={{
                      fontSize: 30,
                      padding: 5,
                      background: "#6665A7",
                      borderRadius: "100%",
                    }}
                    color="secondary"
                  />
                  <Typography
                    variant="body1"
                    style={{
                      marginBottom: 5,
                      marginTop: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Meal & Menu Preferences
                  </Typography>
                  <Divider />
                  <Typography
                    variant="body1"
                    style={{ marginTop: 10, lineHeight: 1.8 }}
                  >
                    Easily ask your guest to choice their meal option from a
                    standard or custom made menu
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          className={classes.contentContainers}
        >
          <Grid item lg={12} style={{ textAlign: "center" }}>
            <Grid container alignItems="center">
              <Grid
                item
                lg={4}
                style={{ textAlign: "center", padding: 20, minHeight: 150 }}
              >
                <Paper elevation={2} style={{ padding: 20, borderRadius: 20 }}>
                  <ContactMailIcon
                    style={{
                      fontSize: 30,
                      padding: 5,
                      background: "#6665A7",
                      borderRadius: "100%",
                    }}
                    color="secondary"
                  />
                  <Typography
                    variant="body1"
                    style={{
                      marginBottom: 5,
                      marginTop: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Email Invitation
                  </Typography>
                  <Divider />
                  <Typography
                    variant="body1"
                    style={{ marginTop: 10, lineHeight: 1.8 }}
                  >
                    Create branded emails for save the date, invitation,
                    updates, and reminder emails
                  </Typography>
                </Paper>
              </Grid>

              <Grid item lg={4} style={{ textAlign: "center", padding: 20 }}>
                <Paper elevation={2} style={{ padding: 20, borderRadius: 20 }}>
                  <PersonPinIcon
                    style={{
                      fontSize: 30,
                      padding: 5,
                      background: "#6665A7",
                      borderRadius: "100%",
                    }}
                    color="secondary"
                  />
                  <Typography
                    variant="body1"
                    style={{
                      marginBottom: 5,
                      marginTop: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Guest Management
                  </Typography>
                  <Divider />
                  <Typography
                    variant="body1"
                    style={{ marginTop: 10, lineHeight: 1.8 }}
                  >
                    Everything you need to manage your guests from the first
                    invitation to the last thank you note
                  </Typography>
                </Paper>
              </Grid>

              <Grid item lg={4} style={{ textAlign: "center", padding: 20 }}>
                <Paper elevation={2} style={{ padding: 20, borderRadius: 20 }}>
                  <HowToRegIcon
                    style={{
                      fontSize: 30,
                      padding: 5,
                      background: "#6665A7",
                      borderRadius: "100%",
                    }}
                    color="secondary"
                  />
                  <Typography
                    variant="body1"
                    style={{
                      marginBottom: 5,
                      marginTop: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Guests Check-in
                  </Typography>
                  <Divider />
                  <Typography
                    variant="body1"
                    style={{ marginTop: 10, lineHeight: 1.8 }}
                  >
                    Handel guest check-in from a mobile device, tablet or
                    desktop PC as they arrive at your event
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          className={classes.contentContainers}
        >
          <Grid item lg={12} style={{ textAlign: "center" }}>
            <Grid container alignItems="center">
              <Grid
                item
                lg={4}
                style={{ textAlign: "center", padding: 20, minHeight: 150 }}
              >
                <Paper elevation={2} style={{ padding: 20, borderRadius: 20 }}>
                  <EventSeatIcon
                    style={{
                      fontSize: 30,
                      padding: 5,
                      background: "#6665A7",
                      borderRadius: "100%",
                    }}
                    color="secondary"
                  />
                  <Typography
                    variant="body1"
                    style={{
                      marginBottom: 5,
                      marginTop: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Seat Charting
                  </Typography>
                  <Divider />
                  <Typography
                    variant="body1"
                    style={{ marginTop: 10, lineHeight: 1.8 }}
                  >
                    Use our seat charting tool to organize your event guests
                    into tables or groups with ease
                  </Typography>
                </Paper>
              </Grid>

              <Grid item lg={4} style={{ textAlign: "center", padding: 20 }}>
                <Paper elevation={2} style={{ padding: 20, borderRadius: 20 }}>
                  <RedeemIcon
                    style={{
                      fontSize: 30,
                      padding: 5,
                      background: "#6665A7",
                      borderRadius: "100%",
                    }}
                    color="secondary"
                  />
                  <Typography
                    variant="body1"
                    style={{
                      marginBottom: 5,
                      marginTop: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Gift & Donation Collection
                  </Typography>
                  <Divider />
                  <Typography
                    variant="body1"
                    style={{ marginTop: 10, lineHeight: 1.8 }}
                  >
                    Seamlessly collect cash donations or integrate 3rd party
                    gift registry platforms to your event
                  </Typography>
                </Paper>
              </Grid>

              <Grid item lg={4} style={{ textAlign: "center", padding: 20 }}>
                <Paper elevation={2} style={{ padding: 20, borderRadius: 20 }}>
                  <ConfirmationNumberIcon
                    style={{
                      fontSize: 30,
                      padding: 5,
                      background: "#6665A7",
                      borderRadius: "100%",
                    }}
                    color="secondary"
                  />
                  <Typography
                    variant="body1"
                    style={{
                      marginBottom: 5,
                      marginTop: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Ticket Sales
                  </Typography>
                  <Divider />
                  <Typography
                    variant="body1"
                    style={{ marginTop: 10, lineHeight: 1.8 }}
                  >
                    Handel guest check-in from a mobile device, tablet or
                    desktop PC as they arrive at your event
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Fragment>
  );
};

Features.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Features);
