import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import copy from "copy-to-clipboard";
import { Link } from "react-router-dom";
import moment from "moment";
//MUI
import Hidden from "@mui/material/Hidden";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CircularProgress from "@mui/material/CircularProgress";
import InfoIcon from "@mui/icons-material/Info";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import BusinessIcon from "@mui/icons-material/Business";
import PaidIcon from "@mui/icons-material/Paid";
import MicIcon from "@mui/icons-material/Mic";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import StrollerOutlinedIcon from "@mui/icons-material/StrollerOutlined";
import AllInclusiveOutlinedIcon from "@mui/icons-material/AllInclusiveOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Snackbar from "@mui/material/Snackbar";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

//Imports
import logo from "../images/logo.png";
import EventMenu from "./EventMenu";
import EventMenulg from "./EventMenulg";
import MyButton from "../utility/MyButton";

//AWS
import { Auth } from "aws-amplify";
import { DataStore } from "aws-amplify";
import { Event } from "../models";
import { GuestList, SeatChart } from "../models";

const styles = (theme) => ({
  ...theme.spreadThis,
  logo: { height: 60 },
  contentContainer: { marginTop: 20 },
  inputSection: { textAlign: "left", marginBottom: 25 },
  labelTxt: { paddingBottom: 10 },
});

const initialFormState = {
  eventNameb: "",
  eventTypeb: "",
  startDateb: "",
  endDateb: "",
  guestNumberb: "",
  publicStateb: false,
};

const EventInfo = (props) => {
  const { classes } = props;
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [getData, setgetDate] = useState(true);
  const [eventName, setEventName] = useState(null);
  const [eventUrl, setEventUrl] = useState(null);
  const [edit, setEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openCopy, setOpenCopy] = useState(false);
  const [error, setError] = useState(null);
  const [myGuests, setMyGuests] = useState(null);
  const [event, setEvent] = useState([]);
  const [formState, updateFormState] = useState(initialFormState);
  const [totalGuest, setTotalGuest] = useState([]);
  const [allMyGuests, setAllMyGuests] = useState([]);
  const [seatedGuest, setSeatedGuest] = useState([]);
  const [plus, setPlus] = useState([]);
  const [one, setOne] = useState([]);
  const [numTable, setNumTable] = useState(0);
  const [numPerTable, setNumPerTable] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [tableId, setTableId] = useState(null);
  const [checkMeal, setCheckMeal] = useState(null);
  const [checkSeat, setCheckSeat] = useState(null);
  const [checkList, setCheckList] = useState(null);

  useEffect(() => {
    checkUser();
    obersation();
  }, []);

  function obersation() {
    DataStore.observe(Event).subscribe((msg) => {
      checkUser();
    });
  }

  async function checkUser() {
    try {
      const allEvent = await DataStore.query(Event);
      const allSeats = await DataStore.query(SeatChart);
      const user = await Auth.currentAuthenticatedUser();
      const allGuests = await DataStore.query(GuestList);

      if (user == null) {
        setgetDate(false);
        navigate("/");
      } else {
        await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
          .then((user) => {
            const filteredEventName = allEvent
              .filter((use) => use.id === eventId)
              .map((amm) => amm.eventName);
            setEventName(String(filteredEventName));

            const filteredEventUrl = allEvent
              .filter((use) => use.id === eventId)
              .map((amm) => amm.eventUrl);
            setEventUrl(String(filteredEventUrl));

            const filterEvent = allEvent.filter((evn) => evn.id === eventId);
            setEvent(filterEvent);

            const allMyGuests = allGuests.filter(
              (evn) => evn.eventId === eventId
            );
            setAllMyGuests(allMyGuests);

            const filterTotalGuest = allGuests.filter(
              (evn) => evn.eventId === eventId && evn.status === "Attending"
            );
            setTotalGuest(filterTotalGuest);

            const filterSeatedGuest = allGuests.filter(
              (evn) => evn.eventId === eventId && evn.checkIn === true
            );
            setSeatedGuest(filterSeatedGuest);

            const filterAdmitTwo = allGuests.filter(
              (evn) => evn.eventId === eventId && evn.type === "Admit two"
            );
            setPlus(filterAdmitTwo);

            const filterAdmitOne = allGuests.filter(
              (evn) => evn.eventId === eventId && evn.type === "Admit one"
            );
            setOne(filterAdmitOne);

            /*  const filterPlus = allGuests.filter(
               (evn) =>
                 evn.eventId === eventId &&
                 evn.title === "+1" &&
                 evn.status === "Attending"
             );
             setPlus(filterPlus);*/

            const filteredNumTableId = allSeats
              .filter((use) => use.eventId === eventId)
              .map((amm) => amm.id);
            setTableId(parseInt(filteredNumTableId));

            const filteredNumTable = allSeats
              .filter((use) => use.eventId === eventId)
              .map((amm) => amm.numberOfTables);
            setNumTable(parseInt(filteredNumTable));

            const filteredNumPerTable = allSeats
              .filter((use) => use.eventId === eventId)
              .map((amm) => amm.seatsPerTable);
            setNumPerTable(parseInt(filteredNumPerTable));

            const filterAllGuest = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.guestNumber);
            setMyGuests(String(filterAllGuest));

            const filteredMeal = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.mealRequired);
            setCheckMeal(String(filteredMeal));

            const filteredSeat = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.seatChart);
            setCheckSeat(String(filteredSeat));

            const filteredList = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.checkList);
            setCheckList(String(filteredList));
          })
          .catch((err) => setError(err));
        setgetDate(false);
      }
    } catch (error) {
      console.log("error confirming:", error);
    }
  }

  function onChange(e) {
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  }

  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  function conChecked(e) {
    updateFormState(() => ({
      ...formState,
      [e.target.name]: e.target.checked,
    }));
  }

  function editClick() {
    setEdit(false);
  }

  function stopEdit() {
    setEdit(true);
  }

  function shareRsvp() {
    copy(`https://guestpin.com/rsvp/${eventUrl}`);
    setOpenCopy(true);
  }

  async function updateEvent(
    id,
    eventName,
    eventType,
    startDate,
    endDate,
    guestNumber,
    publicState
  ) {
    try {
      setLoading(true);
      setError(null);
      const {
        eventNameb,
        eventTypeb,
        startDateb,
        endDateb,
        guestNumberb,
        publicStateb,
      } = formState;

      const myEvt = await DataStore.query(Event, id);

      const newEventName = eventNameb === "" ? eventName : eventNameb;

      const newEventType = eventTypeb === "" ? eventType : eventTypeb;

      const newStateDate =
        startDateb === "" ? startDate : new Date(startDateb).toISOString();

      const newEndDate =
        endDateb === "" ? endDate : new Date(endDateb).toISOString();

      const newGuestNumber = guestNumberb === "" ? guestNumber : guestNumberb;

      const newPublicState = publicStateb === "" ? publicState : publicStateb;

      await DataStore.save(
        Event.copyOf(myEvt, (myUpdate) => {
          myUpdate.eventName = newEventName;
          myUpdate.eventType = newEventType;
          myUpdate.startDate = newStateDate;
          myUpdate.endDate = newEndDate;
          myUpdate.guestNumber = newGuestNumber;
          myUpdate.publicState = newPublicState;
        })
      );

     /* if (newGuestNumber !== guestNumber) {
        
        const newGuestPerTable = parseInt(numPerTable);

        const realNumber = parseInt(newGuestNumber);

        const numberOfTable = parseInt(realNumber / newGuestPerTable);

        const myEvts = await DataStore.query(SeatChart, tableId);

        await DataStore.save(
          SeatChart.copyOf(myEvts, (myUpdate) => {
            myUpdate.numberOfTables = numberOfTable;
          })
        );
      }*/

      setEdit(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  async function deleteGuests(guestId) {
    DataStore.delete(await DataStore.query(GuestList, guestId));
  }

  async function deleteEvent() {
    try {
      if (allMyGuests.length === 0) {
        const todelete = await DataStore.query(Event, deleteId);
        DataStore.delete(todelete);
        navigate("/dashboard");
      } else {
        allMyGuests.map((dstGuest) => {
          const guestId = dstGuest.id;
          deleteGuests(guestId);
        });
        const todelete = await DataStore.query(Event, deleteId);
        DataStore.delete(todelete);
        navigate("/dashboard");
      }
    } catch (error) {
      console.log("Delete Error", error);
    }
  }

  let generalInfo = event.map((evnt) => {
    const id = evnt.id;
    const eventName = evnt.eventName;
    const eventType = evnt.eventType;
    const startDate = evnt.startDate;
    const endDate = evnt.endDate;
    const guestNumber = evnt.guestNumber;
    const newStartDate = moment(startDate).format("MMMM Do YYYY");
    const newEndDate = moment(endDate).format("MMMM Do YYYY");
    const publicState = evnt.publicState;

    return (
      <Fragment>
        <Typography
          variant="body2"
          style={{ marginBottom: 10, letterSpacing: 1 }}
        >
          GENERAL
        </Typography>
        <div className={classes.inputSection}>
          <Typography
            variant="caption"
            style={{
              color: "#707070",
              fontWeight: "bold",
              letterSpacing: 1,
            }}
          >
            Event Name
          </Typography>
          <TextField
            size="small"
            color="primary"
            variant="standard"
            defaultValue={eventName}
            type="text"
            name="eventNameb"
            onChange={onChange}
            disabled={edit}
            fullWidth
            required
          />
        </div>

        <div className={classes.inputSection}>
          <Typography
            variant="caption"
            style={{
              color: "#707070",
              fontWeight: "bold",
              letterSpacing: 1,
            }}
          >
            Event Type
          </Typography>

          <Select
            variant="outlined"
            size="small"
            name="eventTypeb"
            defaultValue={eventType}
            disabled={edit}
            onChange={onChange}
            fullWidth
          >
            <MenuItem value="Business">
              <ListItemIcon>
                <BusinessIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Business</ListItemText>
            </MenuItem>
            <MenuItem value="Gala or Fundriser">
              <ListItemIcon>
                <PaidIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Gala or Fundriser</ListItemText>
            </MenuItem>
            <MenuItem value="Wedding">
              <ListItemIcon>
                <CircleOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Wedding</ListItemText>
            </MenuItem>
            <MenuItem value="Birthday">
              <ListItemIcon>
                <CakeOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Birthday</ListItemText>
            </MenuItem>
            <MenuItem value="Baby Shower">
              <ListItemIcon>
                <StrollerOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Baby Shower</ListItemText>
            </MenuItem>
            <MenuItem value="Show or Performance">
              <ListItemIcon>
                <MicIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Show or Performance</ListItemText>
            </MenuItem>
            <MenuItem value="Others">
              <ListItemIcon>
                <AllInclusiveOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Others</ListItemText>
            </MenuItem>
          </Select>
        </div>

        <div className={classes.inputSection}>
          <Typography
            variant="caption"
            style={{
              color: "#707070",
              fontWeight: "bold",
              letterSpacing: 1,
            }}
          >
            Number of Guests
          </Typography>
          <Select
            variant="outlined"
            size="small"
            defaultValue={guestNumber}
            name="guestNumberb"
            disabled={edit}
            onChange={onChange}
            fullWidth
          >
            <MenuItem value="50"> 50 or fewer guests</MenuItem>
            <MenuItem value="100"> 100 guests</MenuItem>
            <MenuItem value="150"> 150 guests</MenuItem>
            <MenuItem value="200"> 200 guests</MenuItem>
            <MenuItem value="300">300 guests</MenuItem>
            <MenuItem value="400">400 guests</MenuItem>
            <MenuItem value="500">500 guests</MenuItem>
            <MenuItem value="600">600 guests</MenuItem>
            <MenuItem value="700">700 guests</MenuItem>
            <MenuItem value="800">800 guests</MenuItem>
            <MenuItem value="900">900 guests</MenuItem>
            <MenuItem value="1000">1000 guests</MenuItem>
            <MenuItem value="2000">2000 guests</MenuItem>
            <MenuItem value="3000">3000 guests</MenuItem>
            <MenuItem value="4000">4000 guests</MenuItem>
            <MenuItem value="5000">5000 guests or more guests</MenuItem>
          </Select>
        </div>

        <div className={classes.inputSection}>
          <Typography
            variant="caption"
            style={{
              color: "#707070",
              fontWeight: "bold",
              letterSpacing: 1,
            }}
          >
            Start Date
          </Typography>

          {edit ? (
            <TextField
              size="small"
              color="primary"
              variant="standard"
              value={newStartDate}
              type="text"
              disabled={edit}
              fullWidth
            />
          ) : (
            <Fragment>
              <TextField
                size="small"
                color="primary"
                variant="standard"
                type="date"
                name="startDateb"
                onChange={onChange}
                fullWidth
              />
              <Typography color="gray" variant="caption">
                Current start date: {newStartDate}
              </Typography>
            </Fragment>
          )}
        </div>

        <div className={classes.inputSection}>
          <Typography
            variant="caption"
            style={{
              color: "#707070",
              fontWeight: "bold",
              letterSpacing: 1,
            }}
          >
            End Date
          </Typography>

          {edit ? (
            <TextField
              size="small"
              color="primary"
              variant="standard"
              value={newEndDate}
              type="text"
              disabled={edit}
              fullWidth
            />
          ) : (
            <Fragment>
              <TextField
                size="small"
                color="primary"
                variant="standard"
                type="date"
                name="endDateb"
                onChange={onChange}
                fullWidth
              />
              <Typography color="gray" variant="caption">
                Current end date: {newEndDate}
              </Typography>
            </Fragment>
          )}
        </div>

        <div className={classes.inputSection}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked={publicState} />}
              label="Public"
              color="primary"
              onChange={conChecked}
              name="publicStateb"
              disabled={edit}
            />
          </FormGroup>
        </div>

        <div style={{ textAlign: "right", marginTop: "-20px" }}>
          {edit ? null : (
            <Fragment>
              <Button
                size="large"
                variant="contained"
                color="primary"
                style={{ fontSize: 13 }}
                disabled={loading}
                fullWidth
                onClick={() =>
                  updateEvent(
                    id,
                    eventName,
                    eventType,
                    startDate,
                    endDate,
                    guestNumber,
                    publicState
                  )
                }
              >
                Save
                {loading && (
                  <CircularProgress
                    color="primary"
                    size={30}
                    className={classes.progress}
                  />
                )}
              </Button>

              <div style={{ textAlign: "center", marginTop: 20 }}>
                <MyButton
                  tip="Delete Event"
                  onClick={() => handleOpenDelete(id)}
                >
                  <DeleteIcon color="error" fontSize="medium" />
                </MyButton>
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  });

  return getData ? (
    <Fragment>
      <Grid container>
        <Grid item md={4} align="center"></Grid>
        <Grid item md={4} align="center" style={{ padding: "7%" }}>
          <img
            src={logo}
            className={classes.icon}
            style={{ width: "100%" }}
            alt="loading"
          />
          <LinearProgress
            color="primary"
            style={{ width: "100%", marginTop: 10 }}
          />
        </Grid>
        <Grid item md={4} align="center"></Grid>
      </Grid>
    </Fragment>
  ) : (
    <Fragment>
      <Grid container>
        <Hidden lgDown>
          <Grid
            item
            md={12}
            lg={2}
            style={{ height: "100vh", background: "#6665A6" }}
          >
            <EventMenu />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={10} style={{ padding: 30 }}>
          <Hidden lgUp>
            <EventMenulg />
            <br />
            <br />
          </Hidden>
          <Grid
            container
            alignItems="center"
            className={classes.contentContainer}
          >
            <Grid item sm={12} xs={12}>
              <div style={{ textAlign: "right", marginBottom: 10 }}>
                <Link to={`/guestlist/${eventId}`}>
                  <Typography
                    variant="caption"
                    style={{ letterSpacing: 1, fontWeight: "bold" }}
                  >
                    {checkSeat === "true" ||
                    checkMeal === "true" ||
                    checkList === "true"
                      ? 1 + 1 + 1 + 1
                      : checkMeal === "true" ||
                        checkSeat === "false" ||
                        checkList === "false"
                      ? 1 + 1 + 0 + 0
                      : checkList === "false" ||
                        checkSeat === "false" ||
                        checkMeal === "false"
                      ? 1 + 0 + 0 + 0
                      : 0}
                    OF 4 COMPLETE | NEXT - GUESTLIST
                  </Typography>
                </Link>
              </div>

              <Paper
                elevation={2}
                style={{ height: "80vh", padding: 30, overflow: "none" }}
              >
                <div
                  style={{
                    marginBottom: 10,
                    textAlign: "left",
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ fontWeight: "bolder", marginBottom: 10 }}
                  >
                    <InfoIcon fontSize="small" /> {eventName} - Event Info
                  </Typography>
                  <div style={{ float: "right", marginTop: "-40px" }}>
                    {edit ? (
                      <Button
                        size="small"
                        variant="contained"
                        onClick={editClick}
                      >
                        Edit
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        variant="contained"
                        color="success"
                        onClick={stopEdit}
                      >
                        Done
                      </Button>
                    )}
                  </div>
                </div>

                <Grid container>
                  <Hidden mdDown>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      style={{
                        height: "70vh",
                        position: "sticky",
                        top: 0,
                        overflow: "auto",
                        padding: 20,
                      }}
                    >
                      {generalInfo}
                    </Grid>
                  </Hidden>

                  <Grid item xs={12} md={8} style={{ padding: 15 }}>
                    <div
                      className={classes.inputSection}
                      style={{
                        textAlign: "center",
                        background: "#f1f1f1",
                        padding: 10,
                        marginTop: 5,
                      }}
                    >
                      <Typography
                        variant="caption"
                        style={{
                          color: "#333",
                          fontWeight: "bold",
                          letterSpacing: 2,
                        }}
                      >
                        RSVP Link - {`https://guestpin.com/rsvp/${eventUrl}`}
                      </Typography>

                      <MyButton onClick={shareRsvp} tip="Copy Link">
                        <FileCopyIcon color="inherit" fontSize="small" />
                      </MyButton>
                    </div>

                    <div
                      className={classes.inputSection}
                      style={{ textAlign: "center" }}
                    >
                      <Typography
                        variant="caption"
                        style={{
                          color: "#707070",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        EVENT INFO
                      </Typography>

                      <div
                        style={{
                          background: "#f1f1f1",
                          padding: 10,
                          marginTop: 5,
                        }}
                      >
                        <Grid
                          container
                          alignItems="center"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <Grid item xs={4}>
                            <Typography
                              variant="h5"
                              style={{ fontWeight: "bold" }}
                              component="div"
                            >
                              {myGuests}
                            </Typography>
                            <Typography variant="caption" component="div">
                              Guests
                            </Typography>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography
                              variant="h5"
                              style={{ fontWeight: "bold" }}
                              component="div"
                            >
                              {one.length}
                            </Typography>
                            <Typography variant="caption" component="div">
                              Admit One Invites
                            </Typography>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography
                              variant="h5"
                              style={{ fontWeight: "bold" }}
                              component="div"
                            >
                              {plus.length}
                            </Typography>
                            <Typography variant="caption" component="div">
                              Admit Two Invites
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>

                      <div
                        style={{
                          background: "#f1f1f1",
                          padding: 10,
                          marginTop: 20,
                        }}
                      >
                        <Grid
                          container
                          alignItems="center"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <Grid item xs={4}>
                            <Typography
                              variant="h5"
                              style={{ fontWeight: "bold" }}
                              component="div"
                            >
                              {numTable > 0 ? numTable : 0}
                            </Typography>
                            <Typography variant="caption" component="div">
                              Tables
                            </Typography>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography
                              variant="h5"
                              style={{ fontWeight: "bold" }}
                              component="div"
                            >
                              {numPerTable > 0 ? numPerTable : 0}
                            </Typography>
                            <Typography variant="caption" component="div">
                              Seats Per Table
                            </Typography>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography
                              variant="h5"
                              style={{ fontWeight: "bold" }}
                              component="div"
                            >
                              {seatedGuest.length}
                            </Typography>
                            <Typography variant="caption" component="div">
                              Seated Guests
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </div>

                    <div style={{ marginTop: 100, textAlign: "center" }}>
                      {allMyGuests.length === 0 ? (
                        <Fragment>
                          <Typography>
                            Let's get you started? Check out{" "}
                            <Link
                              style={{ fontWeight: "bold", color: "#6665A6" }}
                              to={`/guestList/${eventId}`}
                            >
                              GuestList
                            </Link>
                          </Typography>
                          <Typography variant="caption">
                            Click the "EDIT" button above to change event
                            details
                          </Typography>
                        </Fragment>
                      ) : null}
                    </div>

                    <Snackbar
                      open={openCopy}
                      autoHideDuration={1000}
                      onClose={() => setOpenCopy(false)}
                      message="Link Copied!!!"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={openDelete} onClose={handleDeleteClose}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this event?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={deleteEvent} color="error">
            Yes
          </Button>
          <Button onClick={handleDeleteClose}>NO</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

EventInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventInfo);
