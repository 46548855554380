import React, { Fragment, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
//MUI
import withStyles from "@mui/styles/withStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
//utility
import MyButton from "../utility/MyButton";
//Images
import logo from "../images/logo.png";

const drawerWidth = 200;

const styles = (theme) => ({
  ...theme.spreadThis,

  //New
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    //marginRight: drawerWidth,
  },
  logo: { height: 40 },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
});

const Nav = (props) => {
  const { classes } = props;
  const [open, setOpen] = useState(false);

  function drawOpen() {
    setOpen(true);
  }

  function drawClose() {
    setOpen(false);
  }

  return (
    <Fragment>
      <Hidden lgDown>
        <AppBar color="inherit" position="fixed">
          <Container maxWidth="xl">
            <Grid container alignItems="center">
              <Grid item sm={2}>
                <Link to="/">
                  <img src={logo} style={{ height: 40 }} alt="logo" />
                </Link>
              </Grid>

              <Grid item sm={10}>
                <Grid container justifyContent={"end"}>
                  <Toolbar>
                    <Button
                      size="large"
                      variant="text"
                      component={Link}
                      to="/features"
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        textTransform: "none",
                        fontSize: 13,
                        fontWeight: "bold",
                        color: "#474674",
                      }}
                    >
                      Features
                    </Button>

                    <Button
                      size="large"
                      variant="text"
                      component={Link}
                      to="/pricing"
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        textTransform: "none",
                        fontSize: 13,
                        fontWeight: "bold",
                        color: "#474674",
                      }}
                    >
                      Pricing
                    </Button>
                    <Button
                      size="large"
                      variant="text"
                      component={Link}
                      to="/signin"
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        color: "#474674",
                        textTransform: "none",
                        fontSize: 13,
                        fontWeight: "bold",
                      }}
                    >
                      Sign in
                    </Button>
                    <Button
                      size="large"
                      color="secondary"
                      variant="contained"
                      disableElevation
                      component={Link}
                      to="/signup"
                      style={{
                        paddingLeft: 40,
                        paddingRight: 40,
                        fontWeight: "bold",
                        marginLeft: 10,
                        textTransform: "none",
                        fontSize: 13,
                      }}
                    >
                      Free - Sign Up
                    </Button>
                  </Toolbar>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </AppBar>
      </Hidden>

      <Hidden lgUp>
        <AppBar
          position="fixed"
          color="inherit"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Link to="/">
                  <img
                    src={logo}
                    className={classes.logo}
                    style={{ flexGrow: 1 }}
                    alt="loading"
                  />
                </Link>
              </Grid>

              <Grid item xs={10}>
                <Grid container justifyContent={"end"}>
                  <MyButton onClick={drawOpen} tip="Drawer">
                    <MenuIcon
                      color="primary"
                      aria-label="open drawer"
                      edge="end"
                      className={clsx(open && classes.hide)}
                    />
                  </MyButton>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <MyButton onClick={drawClose} tip="Close Menu">
              <ChevronLeftIcon />
            </MyButton>
            <Typography onClick={drawClose} style={{ cursor: "pointer" }}>
              Menu
            </Typography>
          </div>
          <Divider />
          <List>
            <ListItem button component={Link} to="/features">
              <ListItemText primary="Features" />
            </ListItem>

            <ListItem button component={Link} to="/pricing">
              <ListItemText primary="Pricing" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <Button
              size="large"
              color="primary"
              variant="text"
              disableElevation
              component={Link}
              to="/signin"
              style={{
                width: "100%",
                textTransform: "none",
                fontSize: 13,
                fontWeight: "bold",
                color: "#474674",
                marginBottom: 10,
              }}
            >
              Sign In
            </Button>
            <Button
              size="large"
              color="secondary"
              variant="contained"
              disableElevation
              component={Link}
              to="/signup"
              style={{
                width: "100%",
                textTransform: "none",
                fontSize: 13,
                fontWeight: "bold",
                color: "#474674",
              }}
            >
              Sign Up Free
            </Button>
          </List>
        </Drawer>
      </Hidden>
    </Fragment>
  );
};

Nav.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Nav);
