export default {
  palette: {
    primary: {
      light: "#8483b7",
      main: "#6665A6",
      dark: "#474674",
      contrastText: "#fff",
    },
    secondary: {
      light: "#f3fa94",
      main: "#F1F97A",
      dark: "#a8ae55",
      contrastText: "#000",
    },
    tertiary: {
      light: "#f5f5f5",
      main: "#e5e7eb",
      dark: "#e1e1e1",
      contrastText: "#292849",
    },
  },
  // the object to be spread
  spreadThis: {
    typography: {
      useNextVariants: true,
    },
    form: {
      textAlign: "center",
    },
    image: {
      margin: "20px auto",
      width: "60px",
    },
    pageTitle: {
      fontSize: "23px;",
      lineHeight: "35px",
      fontWeight: "regular",
      color: "#333",
    },
    textField: {
      margin: "10px auto",
    },
    button: {
      margin: "20px auto",
      position: "relative",
    },
    customError: {
      color: "red",
      fontSize: "0.8rem",
      marginTop: "10px",
    },
    progress: {
      position: "absolute",
    },
  },
};
