import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
//MUI
import Hidden from "@mui/material/Hidden";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SettingsIcon from "@mui/icons-material/Settings";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TableBarRoundedIcon from "@mui/icons-material/TableBarRounded";
import EventSeatRoundedIcon from "@mui/icons-material/EventSeatRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PersonOffRoundedIcon from "@mui/icons-material/PersonOffRounded";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AirlineSeatReclineNormalRoundedIcon from "@mui/icons-material/AirlineSeatReclineNormalRounded";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
//Imports
import logo from "../images/logo.png";
import seat from "../images/seat.png";
import EventMenu from "./EventMenu";
import EventMenulg from "./EventMenulg";
import MyButton from "../utility/MyButton";

//AWS
import { Auth } from "aws-amplify";
import { DataStore } from "aws-amplify";
import { Event, SeatChart, GuestList } from "../models";
import { User } from "../models";
import EventSeatIcon from "@mui/icons-material/EventSeat";

const styles = (theme) => ({
  ...theme.spreadThis,
  logo: { height: 60 },
  contentContainer: { marginTop: 20 },
  inputSection: { textAlign: "left", marginBottom: 30 },
  labelTxt: { paddingBottom: 10 },
});

const initialFormState = {
  guestPerTable: 0,
  guestNumber: 0,
  tableNumber: 0,
  seatChart: false,
};

const SeatCharting = (props) => {
  const { classes } = props;
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [getData, setgetDate] = useState(true);
  const [chooseTableNum, setChooseTableNum] = useState(false);
  const [openSetting, setOpenSettings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assign, setAssign] = useState(false);
  const [account, setAccount] = useState(null);
  const [eventName, setEventName] = useState(null);
  const [seatActive, setSeatActive] = useState(null);
  const [event, setEvent] = useState([]);
  const [seatsSetting, setSeatSetting] = useState([]);
  const [error, setError] = useState(null);
  const [formState, updateFormState] = useState(initialFormState);
  const [message, setMessage] = useState(null);
  const [eventGuests, setEventGuests] = useState([]);
  const [presentGuests, setPresentGuests] = useState([]);
  const [assignGuests, setAssignGuests] = useState([]);
  const [notAssignGuests, setNotAssignGuests] = useState([]);
  const [myTables, setMyTables] = useState(0);
  const [mySeatPerTable, setMySeatPerTable] = useState(0);
  const [sorted, setSort] = useState("des");
  const [guests, setGuests] = useState([]);
  const [nguests, setNguests] = useState([]);
  const [currentGuestId, setCurrentGuestId] = useState(null);
  const [currentTable, setCurrentTable] = useState(0);
  const [addedGuest, setAddedGuest] = useState(null);
  const [guestNumber, setGuestNumber] = useState(null);

  const [checkMeal, setCheckMeal] = useState(null);
  const [checkSeat, setCheckSeat] = useState(null);
  const [checkList, setCheckList] = useState(null);

  useEffect(() => {
    checkUser();
    obersation();
  }, []);

  function obersation() {
    DataStore.observe(SeatChart).subscribe((msg) => {
      checkUser();
    });

    DataStore.observe(Event).subscribe((msg) => {
      checkUser();
    });

    DataStore.observe(GuestList).subscribe((msg) => {
      checkUser();
    });
  }

  function handleOpen() {
    setMessage(null);
    setError(null);
    setOpenSettings(true);
  }

  function tableNumUpdate(guestId, table, type, createdAt) {
    setChooseTableNum(true);
    setCurrentGuestId(guestId);
    setCurrentTable(table);

    if (type === "Admit two") {
      const filteredAdd = eventGuests
        .filter((use) => use.firstName === createdAt)
        .map((amm) => amm.id);
      setAddedGuest(String(filteredAdd));
    }
  }

  function handleClose() {
    setOpenSettings(false);
    setChooseTableNum(false);
    setMessage(null);
    setError(null);
  }

  function assignMe() {
    setAssign(true);
  }

  function notassignMe() {
    setAssign(false);
  }

  //Working Search Code
  function onChangeSearch(e) {
    const conde = e.target.value;
    if (assign === true) {
      const newSearch = guests.filter((news) =>
        news.firstName.toLocaleLowerCase().includes(conde.toLocaleLowerCase())
      );
      setAssignGuests(newSearch);
    } else {
      const newSearch = nguests.filter((news) =>
        news.firstName.toLocaleLowerCase().includes(conde.toLocaleLowerCase())
      );
      setNotAssignGuests(newSearch);
    }
  }

  function conChecked(e) {
    updateFormState(() => ({
      ...formState,
      [e.target.name]: e.target.checked,
    }));
  }

  function onChange(e) {
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  }

  async function checkUser() {
    try {
      const allUsers = await DataStore.query(User);
      const allEvent = await DataStore.query(Event);
      const allSeat = await DataStore.query(SeatChart);
      const allGuests = await DataStore.query(GuestList);
      const user = await Auth.currentAuthenticatedUser();

      if (user == null) {
        setgetDate(false);
        navigate("/");
      } else {
        await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
          .then((user) => {
            const filteredAccount = allUsers
              .filter((use) => use.email === user.attributes.email)
              .map((amm) => amm.account);
            setAccount(String(filteredAccount));

            const filterEventGuest = allGuests.filter(
              (evn) => evn.eventId === eventId && evn.status === "Attending"
            );
            setEventGuests(filterEventGuest);

            const filterPresentGuest = allGuests.filter(
              (evn) => evn.eventId === eventId && evn.checkIn === true
            );
            setPresentGuests(filterPresentGuest);

            const filterAssignGuest = allGuests.filter(
              (evn) =>
                evn.eventId === eventId &&
                evn.tableNumber !== 0 &&
                evn.title !== "+1" &&
                evn.status === "Attending"
            );

            const sortedAssigned = filterAssignGuest.sort((a, b) => {
              const mySort = sorted === "des" ? 1 : -1;
              return mySort * a.firstName.localeCompare(b.firstName);
            });

            setAssignGuests(sortedAssigned);
            setGuests(sortedAssigned);

            const filterNotAssignGuest = allGuests.filter(
              (evn) =>
                evn.eventId === eventId &&
                evn.tableNumber === 0 &&
                evn.title !== "+1" &&
                evn.status === "Attending"
            );

            const sortedNotAssigned = filterNotAssignGuest.sort((a, b) => {
              const mySort = sorted === "des" ? 1 : -1;
              return mySort * a.firstName.localeCompare(b.firstName);
            });

            setNotAssignGuests(sortedNotAssigned);
            setNguests(sortedNotAssigned);

            const filteredEventName = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.eventName);
            setEventName(String(filteredEventName));

            const filteredSeatChart = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.seatChart);
            setSeatActive(String(filteredSeatChart));

            const filterEvent = allEvent.filter((evn) => evn.id === eventId);
            setEvent(filterEvent);

            const filterSeat = allSeat.filter((evn) => evn.eventId === eventId);
            setSeatSetting(filterSeat);

            const filterNoSeat = allSeat
              .filter((evn) => evn.eventId === eventId)
              .map((amm) => amm.numberOfTables);
            setMyTables(parseInt(filterNoSeat));

            const filterNoPerSeat = allSeat
              .filter((evn) => evn.eventId === eventId)
              .map((amm) => amm.seatsPerTable);
            setMySeatPerTable(parseInt(filterNoPerSeat));

            const filteredMeal = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.mealRequired);
            setCheckMeal(String(filteredMeal));

            const filteredSeat = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.seatChart);
            setCheckSeat(String(filteredSeat));

            const filteredList = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.checkList);
            setCheckList(String(filteredList));

            const filteredGuestNum = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.guestNumber);
            setGuestNumber(String(filteredGuestNum));
          })
          .catch((err) => setError(err));
        setgetDate(false);
      }
    } catch (error) {
      console.log("error confirming:", error);
    }
  }

  async function updateEvent(id) {
    try {
      setLoading(true);
      setError(null);

      if (account === "Gold") {
        const { seatChart } = formState;
        const myEvt = await DataStore.query(Event, id);
        await DataStore.save(
          Event.copyOf(myEvt, (myUpdate) => {
            myUpdate.seatChart = seatChart;
          })
        );
      } else {
        setError(
          "Oops!!!You have to be on the GOLD subscription plan to use this feature"
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  async function updateTableNumber() {
    try {
      setLoading(true);
      setError(null);

      const { tableNumber } = formState;

      const newTable = parseInt(tableNumber);

      if (newTable > myTables) {
        setLoading(false);
        setError("Opps!!! You've exceeded number of tables available");
      } else {
        const checkTableNum = eventGuests.filter(
          (evn) => evn.tableNumber === newTable
        );

        if (checkTableNum.length >= mySeatPerTable) {
          setLoading(false);
          setError("Opps!!! You've exceeded number of seats on this table");
        } else {
          const myEvt = await DataStore.query(GuestList, currentGuestId);

          await DataStore.save(
            GuestList.copyOf(myEvt, (myUpdate) => {
              myUpdate.tableNumber = newTable;
            })
          );

          if (addedGuest != null) {
            const myGst1 = await DataStore.query(GuestList, addedGuest);
            await DataStore.save(
              GuestList.copyOf(myGst1, (myUpdate) => {
                myUpdate.tableNumber = newTable;
              })
            );
          }
          setLoading(false);
          setMessage("Update successful");
        }
      }
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  async function seatBtn(guestNumber) {
    try {
      setLoading(true);

      setError(null);

      setMessage(null);

      const { guestPerTable } = formState;

      const newGuestPerTable = parseInt(guestPerTable);

      const newGuestNumber = parseInt(guestNumber);

      const numberOfTable = parseInt(newGuestNumber / newGuestPerTable);

      const newSeatSetting = {
        numberOfTables: numberOfTable,
        seatsPerTable: newGuestPerTable,
        eventId: eventId,
      };

      if (newGuestPerTable === 0) {
        setLoading(false);
        setError("Opps!!!Make all fields are filled");
      } else {
        await DataStore.save(new SeatChart(newSeatSetting));
        setLoading(false);
        navigate(`/seatchart/${eventId}`);
      }
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  async function updateSeat(id, guestNumber) {
    try {
      setLoading(true);

      setError(null);

      const { guestPerTable } = formState;

      const newGuestPerTable = parseInt(guestPerTable);

      const newGuestNumber = parseInt(guestNumber);

      const numberOfTable = parseInt(newGuestNumber / newGuestPerTable);

      const myEvt = await DataStore.query(SeatChart, id);

      if (newGuestPerTable === 0) {
        setLoading(false);

        setError("Opps!!!Make all fields are filled");
      } else {
        await DataStore.save(
          SeatChart.copyOf(myEvt, (myUpdate) => {
            myUpdate.seatsPerTable = newGuestPerTable;
            myUpdate.numberOfTables = numberOfTable;
          })
        );
        setLoading(false);
        setMessage("Update Successful");
      }
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  return getData ? (
    <Fragment>
      <Grid container>
        <Grid item md={4} align="center"></Grid>
        <Grid item md={4} align="center" style={{ padding: "7%" }}>
          <img
            src={logo}
            className={classes.icon}
            style={{ width: "100%" }}
            alt="loading"
          />
          <LinearProgress
            color="primary"
            style={{ width: "100%", marginTop: 10 }}
          />
        </Grid>
        <Grid item md={4} align="center"></Grid>
      </Grid>
    </Fragment>
  ) : (
    <Fragment>
      <Grid container>
        <Hidden lgDown>
          <Grid
            item
            md={12}
            lg={2}
            style={{ height: "100vh", background: "#6665A6" }}
          >
            <EventMenu />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={10} style={{ padding: 30 }}>
          <Hidden lgUp>
            <EventMenulg />
            <br />
            <br />
          </Hidden>
          <Grid
            container
            alignItems="center"
            className={classes.contentContainer}
          >
            <Grid item sm={12} xs={12}>
              <div style={{ textAlign: "right", marginBottom: 10 }}>
                <Link to={`/checklist/${eventId}`}>
                  <Typography
                    variant="caption"
                    style={{ letterSpacing: 1, fontWeight: "bold" }}
                  >
                    {checkSeat === "true" ||
                    checkMeal === "true" ||
                    checkList === "true"
                      ? 1 + 1 + 1 + 1
                      : checkMeal === "true" ||
                        checkSeat === "false" ||
                        checkList === "false"
                      ? 1 + 1 + 0 + 0
                      : checkList === "false" ||
                        checkSeat === "false" ||
                        checkMeal === "false"
                      ? 1 + 0 + 0 + 0
                      : 0}
                    OF 4 COMPLETE | NEXT - CHECKLIST
                  </Typography>
                </Link>
              </div>

              <Paper
                elevation={2}
                style={{ height: "80vh", padding: 30, overflow: "none" }}
              >
                <Grid
                  container
                  style={{
                    marginBottom: 10,
                    borderBottom: "1px solid #e1e1e1",
                    height: "5vh",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <Grid item xs={12}>
                    <div>
                      <Typography
                        variant="h5"
                        style={{ fontWeight: "bolder", marginBottom: 10 }}
                      >
                        <EventSeatIcon /> {eventName} - Seat Cheating
                        <MyButton onClick={handleOpen} tip="Settings">
                          <SettingsIcon color="error" fontSize="medium" />
                        </MyButton>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>

                <Grid container>
                  {seatActive === "false" ? (
                    <Fragment>
                      <Grid item md={3}></Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ padding: 20, textAlign: "center" }}
                      >
                        <img
                          src={seat}
                          style={{ width: "100%" }}
                          alt="loading"
                        />

                        <Typography>
                          Click on the button below to get started with this
                          feature
                        </Typography>

                        <div style={{ marginTop: 10 }}>
                          <Button
                            variant="contained"
                            onClick={handleOpen}
                            color="primary"
                          >
                            ACTIVATE
                          </Button>
                        </div>
                      </Grid>
                      <Grid item md={3}></Grid>
                    </Fragment>
                  ) : seatsSetting.length === 1 ? (
                    <Fragment>
                      <Grid
                        item
                        xs={12}
                        style={{ height: "10vh", position: "sticky", top: 50 }}
                      >
                        <Grid container>
                          <Grid item xs={12} md={12}>
                            <Grid container>
                              <Grid item xs={12} sm={4} style={{ padding: 10 }}>
                                <div
                                  style={{
                                    background: "#f5f5f5",
                                    textAlign: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="h4"
                                    color="primary"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    <Typography
                                      variant="caption"
                                      color="primary"
                                      style={{ marginRight: 5 }}
                                    >
                                      <TableBarRoundedIcon />
                                    </Typography>
                                    {myTables}
                                    <Typography
                                      variant="caption"
                                      color="primary"
                                      style={{
                                        letterSpacing: 1,
                                        marginLeft: 5,
                                      }}
                                    >
                                      TABLES
                                    </Typography>
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={4} style={{ padding: 10 }}>
                                <div
                                  style={{
                                    background: "#f5f5f5",
                                    textAlign: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="h4"
                                    color="primary"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    <Typography
                                      variant="caption"
                                      color="primary"
                                      style={{ marginRight: 5 }}
                                    >
                                      <EventSeatRoundedIcon />
                                    </Typography>
                                    {mySeatPerTable}
                                    <Typography
                                      variant="caption"
                                      color="primary"
                                      style={{
                                        letterSpacing: 1,
                                        marginLeft: 5,
                                      }}
                                    >
                                      SEATS | TABLES
                                    </Typography>
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={4} style={{ padding: 10 }}>
                                <div
                                  style={{
                                    background: "#f5f5f5",
                                    textAlign: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="h4"
                                    color="primary"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    <Typography
                                      variant="caption"
                                      color="primary"
                                      style={{ marginRight: 5 }}
                                    >
                                      <AirlineSeatReclineNormalRoundedIcon fontSize="medium" />
                                    </Typography>
                                    {presentGuests.length} /{" "}
                                    {eventGuests.length}
                                    <Typography
                                      variant="caption"
                                      color="primary"
                                      style={{
                                        letterSpacing: 1,
                                        marginLeft: 5,
                                      }}
                                    >
                                      SEATED
                                    </Typography>
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Hidden mdDown>
                        <Grid
                          item
                          xs={4}
                          style={{
                            padding: 10,
                          }}
                        >
                          <Grid
                            container
                            style={{
                              height: "10vh",
                              position: "sticky",
                              overflow: "auto",
                              top: 0,
                            }}
                          >
                            <Grid item xs={9}>
                              <Typography
                                style={{ fontWeight: "bold", color: "#707070" }}
                                variant="subtitle2"
                              >
                                GUESTS
                              </Typography>
                            </Grid>
                            <Grid item xs={3} style={{ marginTop: "-8px" }}>
                              <MyButton onClick={assignMe} tip="Assigned">
                                <PersonRoundedIcon
                                  fontSize="small"
                                  color="primary"
                                />
                              </MyButton>
                              <MyButton
                                onClick={notassignMe}
                                tip="Not Assigned"
                              >
                                <PersonOffRoundedIcon
                                  fontSize="small"
                                  color="error"
                                />
                              </MyButton>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                id="search"
                                variant="filled"
                                size="small"
                                name="search"
                                type="text"
                                onChange={onChangeSearch}
                                color="primary"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                placeholder="Enter guest name"
                                fullWidth
                              />
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            style={{
                              height: "50vh",
                              position: "sticky",
                              overflow: "auto",
                              top: 120,
                            }}
                          >
                            <Grid item xs={12}>
                              {assign ? (
                                assignGuests.length === 0 ? (
                                  <Typography
                                    variant="subtitle2"
                                    color="gray"
                                    style={{ marginTop: 10 }}
                                  >
                                    No guests assigned
                                  </Typography>
                                ) : (
                                  assignGuests.map((pg, id) => {
                                    const guestId = pg.id;
                                    const guestTitle = pg.title;
                                    const type = pg.type;
                                    const guestName = pg.firstName;
                                    const table = pg.tableNumber;
                                    const createdAt = pg.createdAt;

                                    return (
                                      <Grid
                                        container
                                        style={{ padding: "10px 0px" }}
                                      >
                                        <Grid item xs={8}>
                                          <Typography variant="subtitle2">
                                            {guestTitle} {guestName}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                          <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() =>
                                              tableNumUpdate(
                                                guestId,
                                                table,
                                                type,
                                                createdAt
                                              )
                                            }
                                            color="inherit"
                                            style={{ textTransform: "none" }}
                                            fullWidth
                                          >
                                            Table {table}
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    );
                                  })
                                )
                              ) : notAssignGuests.length === 0 ? (
                                <Typography
                                  variant="subtitle2"
                                  color="gray"
                                  style={{ marginTop: 10 }}
                                >
                                  No pending guests
                                </Typography>
                              ) : (
                                notAssignGuests.map((pg, id) => {
                                  const guestId = pg.id;
                                  const guestTitle = pg.title;
                                  const type = pg.type;
                                  const guestName = pg.firstName;
                                  const table = pg.tableNumber;
                                  const createdAt = pg.createdAt;

                                  return (
                                    <Grid
                                      container
                                      style={{ padding: "10px 0px" }}
                                    >
                                      <Grid item xs={8}>
                                        <Typography variant="subtitle2">
                                          {guestTitle} {guestName}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <Button
                                          variant="contained"
                                          onClick={() =>
                                            tableNumUpdate(
                                              guestId,
                                              table,
                                              type,
                                              createdAt
                                            )
                                          }
                                          size="small"
                                          color="inherit"
                                          style={{ textTransform: "none" }}
                                          fullWidth
                                        >
                                          Select
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  );
                                })
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Hidden>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        style={{
                          borderLeft: "1px solid #e1e1e1",
                          overflow: "auto",
                          height: "65vh",
                          position: "sticky",
                          top: 120,
                        }}
                      >
                        <Grid container>
                          <Grid
                            container
                            item
                            style={{ justifyContent: "center" }}
                            xs={12}
                          >
                            {Array.from(Array(myTables), (e, i) => {
                              const num = i + 1;
                              const guestDetails = guests.filter(
                                (evn) => evn.tableNumber === num
                              );

                              return (
                                <Fragment>
                                  <Accordion
                                    style={{
                                      width: 300,
                                      margin: 20,
                                      maxWidth: "100%",
                                    }}
                                    key={i}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <TableBarRoundedIcon />
                                      <Typography
                                        variant="subtitle2"
                                        style={{
                                          letterSpacing: 1,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        - Table {num}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      style={{ marginTop: "-30px" }}
                                    >
                                      {guestDetails.length === 0 ? (
                                        <Typography
                                          variant="subtitle2"
                                          color="gray"
                                          style={{
                                            textAlign: "center",
                                            margin: "20px 0px",
                                          }}
                                        >
                                          No guests assigned
                                        </Typography>
                                      ) : (
                                        guestDetails.map((detail, idx) => {
                                          const title = detail.title;
                                          const name = detail.firstName;
                                          const type = detail.type;

                                          return (
                                            <Grid
                                              container
                                              style={{
                                                margin: "20px 0px",
                                                borderBottom:
                                                  "1px solid #e1e1e1",
                                              }}
                                              key={idx}
                                            >
                                              <Grid item xs={8}>
                                                <Typography variant="body2">
                                                  {title} {name}
                                                </Typography>
                                              </Grid>

                                              <Grid item xs={4}>
                                                <Typography
                                                  color="primary"
                                                  variant="body2"
                                                  textAlign="right"
                                                >
                                                  {type}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          );
                                        })
                                      )}
                                    </AccordionDetails>
                                  </Accordion>
                                </Fragment>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Grid item md={3}></Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ padding: 20, textAlign: "center" }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={seat}
                            style={{ width: "100%" }}
                            alt="loading"
                          />
                          <Typography
                            variant="body1"
                            style={{ marginBottom: 10 }}
                          >
                            How many number of guests do you want on a table?
                          </Typography>
                          <Select
                            variant="outlined"
                            size="small"
                            name="guestPerTable"
                            onChange={onChange}
                            fullWidth
                            required
                          >
                            <MenuItem value="3">3</MenuItem>
                            <MenuItem value="4">4</MenuItem>
                            <MenuItem value="5">5</MenuItem>
                            <MenuItem value="6">6</MenuItem>
                            <MenuItem value="7">7</MenuItem>
                            <MenuItem value="8">8</MenuItem>
                            <MenuItem value="9">9</MenuItem>
                            <MenuItem value="10">10</MenuItem>
                          </Select>
                        </div>

                        <div style={{ textAlign: "center", marginTop: 10 }}>
                          <Typography
                            variant="body1"
                            style={{ marginBottom: 10 }}
                          >
                            Total number of guest?
                          </Typography>
                          <TextField
                            size="small"
                            color="primary"
                            variant="outlined"
                            type="text"
                            onChange={onChange}
                            value={guestNumber}
                            fullWidth
                            disabled
                          />
                        </div>

                        <div style={{ marginTop: 20, textAlign: "center" }}>
                          <Button
                            onClick={() => seatBtn(guestNumber)}
                            size="medium"
                            variant="contained"
                            fullWidth
                          >
                            save
                            {loading && (
                              <CircularProgress
                                color="primary"
                                size={30}
                                className={classes.progress}
                              />
                            )}
                          </Button>
                        </div>

                        {error === null ? (
                          <Typography
                            variant="body2"
                            color="primary"
                            gutterBottom
                            component="div"
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              marginTop: 10,
                            }}
                          >
                            {message}
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            color="red"
                            gutterBottom
                            component="div"
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              marginTop: 10,
                            }}
                          >
                            {error}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item md={3}></Grid>
                    </Fragment>
                  )}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={openSetting} onClose={handleClose}>
        <DialogActions>
          <div style={{ borderBottom: "1px solid #e1e1e1", width: "100%" }}>
            <Grid container>
              <Grid item xs={10} style={{ textAlign: "left" }}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Seat Chart Settings
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ textAlign: "right", marginTop: "-10px" }}
              >
                <MyButton onClick={handleClose} tip="Close">
                  <CloseIcon fontSize="large" />
                </MyButton>
              </Grid>
            </Grid>
          </div>
        </DialogActions>
        <DialogContent>
          <DialogContentText>
            {event.map((mel) => {
              const id = mel.id;
              const seatActive = mel.seatChart;

              return account === "Gold" ? (
                seatsSetting.length === 1 ? (
                  seatsSetting.map((set) => {
                    const id = set.id;
                    const noSeats = set.seatsPerTable;

                    return (
                      <div>
                        <div className={classes.inputSection}>
                          <Typography>Number of guests per table?</Typography>
                          <Select
                            variant="outlined"
                            size="small"
                            name="guestPerTable"
                            defaultValue={noSeats}
                            onChange={onChange}
                            fullWidth
                            required
                          >
                            <MenuItem value="3">3</MenuItem>
                            <MenuItem value="4">4</MenuItem>
                            <MenuItem value="5">5</MenuItem>
                            <MenuItem value="6">6</MenuItem>
                            <MenuItem value="7">7</MenuItem>
                            <MenuItem value="8">8</MenuItem>
                            <MenuItem value="9">9</MenuItem>
                            <MenuItem value="10">10</MenuItem>
                          </Select>
                        </div>

                        <div className={classes.inputSection}>
                          <Typography>Total number of guest</Typography>
                          <TextField
                            size="small"
                            color="primary"
                            variant="outlined"
                            type="text"
                            onChange={onChange}
                            value={guestNumber}
                            fullWidth
                            disabled
                          />
                        </div>

                        <div style={{ marginTop: 20, textAlign: "center" }}>
                          <Button
                            onClick={() => updateSeat(id, guestNumber)}
                            size="medium"
                            variant="contained"
                          >
                            save
                            {loading && (
                              <CircularProgress
                                color="primary"
                                size={30}
                                className={classes.progress}
                              />
                            )}
                          </Button>
                        </div>

                        {error === null ? (
                          <Typography
                            variant="body2"
                            color="primary"
                            gutterBottom
                            component="div"
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              marginTop: 10,
                            }}
                          >
                            {message}
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            color="red"
                            gutterBottom
                            component="div"
                            style={{
                              textAlign: "center",
                              fontWeight: "bold",
                              marginTop: 10,
                            }}
                          >
                            {error}
                          </Typography>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <Fragment>
                    <div>
                      <Typography>
                        By activating seat charting, you would be able to assign
                        table numbers to guests and manage seating arrangments
                      </Typography>
                      <hr />
                      <div
                        className={classes.inputSection}
                        style={{ textAlign: "center" }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={<Switch defaultChecked={seatActive} />}
                            label="Activate Seat Charting"
                            color="primary"
                            onChange={conChecked}
                            name="seatChart"
                          />
                        </FormGroup>
                      </div>

                      <div style={{ marginTop: 20, textAlign: "center" }}>
                        <Button
                          onClick={() => updateEvent(id)}
                          size="medium"
                          variant="contained"
                        >
                          save
                          {loading && (
                            <CircularProgress
                              color="primary"
                              size={30}
                              className={classes.progress}
                            />
                          )}
                        </Button>
                      </div>

                      {error === null ? null : (
                        <Typography
                          variant="body2"
                          color="red"
                          gutterBottom
                          component="div"
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            marginTop: 10,
                          }}
                        >
                          {error}
                        </Typography>
                      )}
                    </div>
                  </Fragment>
                )
              ) : (
                <div>
                  <Typography>
                    Opps!!! you have to upgrade your account type to activate
                    the seat charting option.{account}
                  </Typography>
                  <div style={{ marginTop: 20, textAlign: "center" }}>
                    <Button
                      component={Link}
                      to="/pricing"
                      size="medium"
                      variant="contained"
                    >
                      Upgrade
                    </Button>
                  </div>
                </div>
              );
            })}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={chooseTableNum} onClose={handleClose}>
        <DialogActions>
          <div style={{ borderBottom: "1px solid #e1e1e1", width: "100%" }}>
            <Grid container>
              <Grid item xs={10} style={{ textAlign: "left" }}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Assign Table Number
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ textAlign: "right", marginTop: "-10px" }}
              >
                <MyButton onClick={handleClose} tip="Close">
                  <CloseIcon fontSize="large" />
                </MyButton>
              </Grid>
            </Grid>
          </div>
        </DialogActions>
        <DialogContent>
          <div className={classes.inputSection}>
            <TextField
              size="small"
              color="primary"
              name="tableNumber"
              variant="outlined"
              type="number"
              onChange={onChange}
              defaultValue={currentTable}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span style={{ letterSpacing: 2 }}>Table</span>
                  </InputAdornment>
                ),
              }}
            />

            <Typography
              component="div"
              variant="caption"
              style={{
                textTransform: "uppercase",
                textAlign: "center",
                letterSpacing: 1,
                marginTop: 5,
              }}
            >
              Select between{" "}
              <span style={{ color: "#6665A4", fontWeight: "bold" }}>
                Table 1 - {myTables}
              </span>
            </Typography>
          </div>

          <div style={{ marginTop: 20, textAlign: "center" }}>
            <Button
              onClick={updateTableNumber}
              size="medium"
              variant="contained"
              fullWidth
            >
              save
              {loading && (
                <CircularProgress
                  color="primary"
                  size={30}
                  className={classes.progress}
                />
              )}
            </Button>
          </div>

          {error === null ? (
            <Typography
              variant="body2"
              color="primary"
              gutterBottom
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              {message}
            </Typography>
          ) : (
            <Typography
              variant="body2"
              color="red"
              gutterBottom
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginTop: 10,
              }}
            >
              {error}
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

SeatCharting.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SeatCharting);
