import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//MUI
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

//Images
import logo from "../images/logo.png";
import MyButton from "../utility/MyButton";
//AWS
import { Auth } from "aws-amplify";

const styles = (theme) => ({
  ...theme.spreadThis,
  logo: { height: 60 },
  contentContainer: { marginTop: 50 },
  inputSection: { textAlign: "left", marginBottom: 30 },
  labelTxt: { paddingBottom: 10 },
});

const initialFormState = {
  email: "",
  password: "",
  confirmPassword: "",
  authCode: "",
  show: false,
  formType: "forgot",
};

const ForgotPassword = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [getData, setgetDate] = useState(true);
  const [formState, updateFormState] = useState(initialFormState);
  const [handleOpen, setHandleOpen] = useState(false);

  useEffect(() => {
    checkUser();
  }, []);

  const { formType } = formState;

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user != null) {
        setgetDate(false);
        navigate("/dashboard");
      } else {
        setgetDate(false);
      }
    } catch (error) {
      setgetDate(false);
      console.log("error confirming:", error);
    }
  }

  function onChange(e) {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  }

  function showMe() {
    setShow(!show);
  }

  async function forgetPassword(e) {
    try {
      e.preventDefault();
      setError(null);
      setLoading(true);
      const { email } = formState;
      Auth.forgotPassword(email)
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
      setLoading(false);
      updateFormState(() => ({ ...formState, formType: "changePassword" }));
    } catch (error) {
      setLoading(false);
      setError("Email doesn't exist");
      console.log("error confirming:", error);
    }
  }

  async function changePass() {
    try {
      setError(null);
      setLoading(true);
      const { email, authCode, password, confirmPassword } = formState;
      // Collect confirmation code and new password, then
      if (password != confirmPassword) {
        setLoading(false);
        setError("Password does not match");
      } else {
        Auth.forgotPasswordSubmit(email, authCode, password)
          .then((data) => console.log(data))
          .catch((err) => console.log(err));
        setLoading(false);
        setHandleOpen(true);
      }
    } catch (error) {
      setLoading(false);
      setError(" An error occuried, try again");
      console.log("error confirming:", error);
    }
  }

  return getData ? (
    <Fragment>
      <Grid container>
        <Grid item sm={3} xs={3} align="center"></Grid>
        <Grid item sm={6} xs={12} align="center" style={{ padding: "7%" }}>
          <img
            src={logo}
            className={classes.icon}
            style={{ width: "100%" }}
            alt="loading"
          />
          <LinearProgress
            color="primary"
            style={{ width: "100%", marginTop: 10 }}
          />
        </Grid>
        <Grid item sm={3} xs={3} align="center"></Grid>
      </Grid>
    </Fragment>
  ) : (
    <Fragment>
      <Dialog open={handleOpen} style={{ padding: 30 }}>
        <DialogTitle>Password Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have successfully changed your password, kindly click on the
            button below to proceed to login to your account
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            component={Link}
            to="/signin"
            color="primary"
            style={{ fontSize: 13, marginBottom: 20 }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      <Container maxWidth="lg">
        <Grid
          container
          alignItems="center"
          className={classes.contentContainer}
        >
          <Grid item sm={3} xs={3}></Grid>
          <Grid item sm={6} xs={12}>
            <div style={{ textAlign: "center" }}>
              <Link to="/">
                <img src={logo} className={classes.logo} alt="loading" />
              </Link>
            </div>

            <div style={{ marginTop: "10px", textAlign: "center" }}>
              <Typography variant="h5" style={{ fontWeight: "bolder" }}>
                Reset your password
              </Typography>
            </div>

            {formType === "forgot" && (
              <Fragment>
                <div style={{ marginTop: "30px" }}>
                  <Paper elevation={2} style={{ minHeight: 50, padding: 50 }}>
                    <form onSubmit={forgetPassword}>
                      <div className={classes.inputSection}>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#707070",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            letterSpacing: 1,
                          }}
                        >
                          Email Address
                        </Typography>
                        <TextField
                          size="small"
                          color="primary"
                          variant="outlined"
                          type="email"
                          name="email"
                          onChange={onChange}
                          fullWidth
                          required
                        />
                      </div>

                      {error === null ? null : (
                        <div
                          style={{
                            marginBottom: 30,
                            marginTop: "-20px",
                            textAlign: "right",
                          }}
                        >
                          <Typography variant="caption" color="error">
                            {error}
                          </Typography>
                        </div>
                      )}

                      <div style={{ marginBottom: 20, marginTop: "-30px" }}>
                        <Typography variant="caption" color="gray">
                          A password reset code would be sent to your email i.e
                          Check your spam box if code is not found in inbox
                        </Typography>
                      </div>

                      <div
                        className={classes.inputSection}
                        style={{ textAlign: "right" }}
                      >
                        <Button
                          size="large"
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ fontSize: 13, marginBottom: 20 }}
                          disabled={loading}
                          fullWidth
                        >
                          Submit
                          {loading && (
                            <CircularProgress
                              color="primary"
                              size={30}
                              className={classes.progress}
                            />
                          )}
                        </Button>

                        <Button
                          size="large"
                          variant="contained"
                          disableElevation
                          color="tertiary"
                          style={{ fontSize: 13 }}
                          fullWidth
                          component={Link}
                          to="/signin"
                        >
                          Back to Login
                        </Button>
                      </div>
                    </form>
                  </Paper>
                </div>
              </Fragment>
            )}

            {formType === "changePassword" && (
              <Fragment>
                <div style={{ marginTop: "30px" }}>
                  <Paper elevation={2} style={{ minHeight: 50, padding: 50 }}>
                    <div className={classes.inputSection}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#707070",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        Confirmation Code
                      </Typography>

                      <TextField
                        size="small"
                        color="primary"
                        variant="outlined"
                        type="text"
                        name="authCode"
                        onChange={onChange}
                        fullWidth
                        required
                        autoFocus
                      />
                    </div>

                    <div className={classes.inputSection}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#707070",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        Enter New Password
                      </Typography>
                      <TextField
                        size="small"
                        name="password"
                        color="primary"
                        variant="outlined"
                        type={show ? "text" : "password"}
                        onChange={onChange}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {show ? (
                                <MyButton onClick={showMe} tip="View Password">
                                  <VisibilityOffIcon color="disabled" />
                                </MyButton>
                              ) : (
                                <MyButton onClick={showMe} tip="View Password">
                                  <VisibilityIcon color="disabled" />
                                </MyButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        required
                      />
                    </div>

                    <div className={classes.inputSection}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#707070",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        Confirm Password
                      </Typography>
                      <TextField
                        size="small"
                        color="primary"
                        variant="outlined"
                        name="confirmPassword"
                        type="password"
                        onChange={onChange}
                        fullWidth
                        required
                      />
                    </div>

                    {error === null ? null : (
                      <div
                        style={{
                          marginBottom: 10,
                          marginTop: "-10px",
                          textAlign: "right",
                        }}
                      >
                        <Typography variant="caption" color="error">
                          {error}
                        </Typography>
                      </div>
                    )}

                    <div
                      className={classes.inputSection}
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        style={{ fontSize: 13 }}
                        disabled={loading}
                        onClick={changePass}
                      >
                        Reset Password
                        {loading && (
                          <CircularProgress
                            color="primary"
                            size={30}
                            className={classes.progress}
                          />
                        )}
                      </Button>
                    </div>
                  </Paper>
                </div>
              </Fragment>
            )}
          </Grid>

          <Grid item sm={3} xs={3}></Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ForgotPassword);
