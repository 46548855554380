import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
//MUI
import Hidden from "@mui/material/Hidden";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import SettingsIcon from "@mui/icons-material/Settings";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DeleteIcon from "@mui/icons-material/Delete";

//Imports
import logo from "../images/logo.png";
import chef from "../images/chef.png";
import EventMenu from "./EventMenu";
import EventMenulg from "./EventMenulg";
import MyButton from "../utility/MyButton";

//AWS
import { Auth } from "aws-amplify";
import { DataStore } from "aws-amplify";
import { Event, Menu } from "../models";
import { User } from "../models";

const styles = (theme) => ({
  ...theme.spreadThis,
  logo: { height: 60 },
  contentContainer: { marginTop: 20 },
  inputSection: { textAlign: "left", marginBottom: 30 },
  labelTxt: { paddingBottom: 10 },
});

const initialFormState = {
  mealRequired: false,
  mealCategory: "",
  description: "",
};

const Mealpreference = (props) => {
  const { classes } = props;
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [getData, setgetDate] = useState(true);
  const [openSetting, setOpenSettings] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [openMeal, setOpenMeal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const [eventName, setEventName] = useState(null);
  const [menuActive, setMenuActive] = useState(null);
  const [event, setEvent] = useState([]);
  const [eventMenu, setEventMenu] = useState([]);
  const [error, setError] = useState(null);
  const [formState, updateFormState] = useState(initialFormState);
  const [message, setMessage] = useState(null);
  const [oneMeal, setOneMeal] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  const [checkMeal, setCheckMeal] = useState(null);
  const [checkSeat, setCheckSeat] = useState(null);
  const [checkList, setCheckList] = useState(null);

  useEffect(() => {
    checkUser();
    obersation();
  }, []);

  function obersation() {
    DataStore.observe(Menu).subscribe((msg) => {
      checkUser();
    });

    DataStore.observe(Event).subscribe((msg) => {
      checkUser();
    });
  }

  async function checkUser() {
    try {
      const allUsers = await DataStore.query(User);
      const allEvent = await DataStore.query(Event);
      const allMenu = await DataStore.query(Menu);
      const user = await Auth.currentAuthenticatedUser();

      if (user == null) {
        setgetDate(false);
        navigate("/");
      } else {
        await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
          .then((user) => {
            const filteredAccount = allUsers
              .filter((use) => use.email === user.attributes.email)
              .map((amm) => amm.account);
            setAccount(String(filteredAccount));

            const filteredEventName = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.eventName);
            setEventName(String(filteredEventName));

            const filteredMenuActive = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.mealRequired);
            setMenuActive(String(filteredMenuActive));

            const filterEvent = allEvent.filter((evn) => evn.id === eventId);
            setEvent(filterEvent);

            const filterMenu = allMenu.filter((evn) => evn.eventId === eventId);
            setEventMenu(filterMenu);

            const filteredMeal = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.mealRequired);
            setCheckMeal(String(filteredMeal));

            const filteredSeat = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.seatChart);
            setCheckSeat(String(filteredSeat));

            const filteredList = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.checkList);
            setCheckList(String(filteredList));
          })
          .catch((err) => setError(err));
        setgetDate(false);
      }
    } catch (error) {
      console.log("error confirming:", error);
    }
  }

  function conChecked(e) {
    updateFormState(() => ({
      ...formState,
      [e.target.name]: e.target.checked,
    }));
  }

  function onChange(e) {
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  }

  function handleOpen() {
    setMessage(null);
    setError(null);
    setOpenSettings(true);
  }

  function handleOpenMeal() {
    setOpenMeal(true);
  }

  function handleClose() {
    setOpenSettings(false);
    setOpenMeal(false);
    setEditOpen(false);
    setOpenDelete(false);
  }

  function handleDeleteClose() {
    setOpenDelete(false);
  }

  async function updateMeal() {
    try {
      setLoading(true);
      setError(null);
      setMessage(null);
      const { mealCategory, description } = formState;

      const newMeal = {
        mealCategory: mealCategory,
        description: description,
        guestNumber: 0,
        eventId: eventId,
      };

      await DataStore.save(new Menu(newMeal));
      setMessage("Meal added successfully");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };

  async function deleteMeal() {
    try {
      const todelete = await DataStore.query(Menu, deleteId);
      DataStore.delete(todelete);
      handleClose();
    } catch (error) {
      console.log("Delete Error", error);
    }
  }

  async function updateEvent(id) {
    try {
      setLoading(true);
      setError(null);

      const { mealRequired } = formState;
      const myEvt = await DataStore.query(Event, id);
      await DataStore.save(
        Event.copyOf(myEvt, (myUpdate) => {
          myUpdate.mealRequired = mealRequired;
        })
      );

      if (eventMenu.length === 0) {
        const defaultFood2 = {
          mealCategory: "Nigerian Dish",
          description:
            "Jollof Rice, Fried rice, Ofada Rice, Yam porridge, Amala, Pounded Yam etc",
          guestNumber: 0,
          eventId: eventId,
        };

        const defaultFood1 = {
          mealCategory: "Continental Dish",
          description:
            "Chinese fried rice, Stir fried Singapore noodles, Diced chicken in curry veggie sauce etc",
          guestNumber: 0,
          eventId: eventId,
        };
        await DataStore.save(new Menu(defaultFood1));
        await DataStore.save(new Menu(defaultFood2));
      }

      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  async function editMeal(id, mealCategoryb, descriptionb) {
    try {
      setLoading(true);
      setError(null);
      setMessage(null);
      const { mealCategory, description } = formState;
      const myMeal = await DataStore.query(Menu, id);

      const newMealCategory =
        mealCategory === "" ? mealCategoryb : mealCategory;

      const newDescription = description === "" ? descriptionb : description;

      await DataStore.save(
        Menu.copyOf(myMeal, (myUpdate) => {
          myUpdate.mealCategory = newMealCategory;
          myUpdate.description = newDescription;
        })
      );
      setMessage("Update Successful");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  const mealInfo = (idx) => {
    setEditOpen(true);
    setMessage(null);
    setError(null);
    const thisMeal = [eventMenu[idx]];
    setOneMeal(thisMeal);
  };

  let menuMockup = eventMenu.map((men, idx) => {
    const mealCategory = men.mealCategory;
    const description = men.description;
    const guestNumber = men.guestNumber;

    return (
      <Fragment>
        <Card
          onClick={() => mealInfo(idx)}
          variant="elevation"
          style={{
            width: 490,
            margin: 10,
            maxWidth: "100%",
            cursor: "pointer",
          }}
          key={idx}
        >
          <CardContent>
            <Grid container alignItems="center">
              <Grid item xs={8}>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold" }}
                  component="div"
                >
                  {mealCategory}
                </Typography>
                <Typography
                  gutterBottom
                  variant="caption"
                  color="primary"
                  component="div"
                >
                  {description}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ textAlign: "center", borderLeft: "1px solid #e1e1e1" }}
              >
                <Typography
                  variant="caption"
                  color="gray"
                  style={{ letterSpacing: 1, fontWeight: "bold" }}
                >
                  INTERESTED
                </Typography>
                <Typography variant="h3" style={{ letterSpacing: 1 }}>
                  {guestNumber}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Fragment>
    );
  });

  return getData ? (
    <Fragment>
      <Grid container>
        <Grid item md={4} align="center"></Grid>
        <Grid item md={4} align="center" style={{ padding: "7%" }}>
          <img
            src={logo}
            className={classes.icon}
            style={{ width: "100%" }}
            alt="loading"
          />
          <LinearProgress
            color="primary"
            style={{ width: "100%", marginTop: 10 }}
          />
        </Grid>
        <Grid item md={4} align="center"></Grid>
      </Grid>
    </Fragment>
  ) : (
    <Fragment>
      <Grid container>
        <Hidden lgDown>
          <Grid
            item
            md={12}
            lg={2}
            style={{ height: "100vh", background: "#6665A6" }}
          >
            <EventMenu />
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={10} style={{ padding: 30 }}>
          <Hidden lgUp>
            <EventMenulg />
            <br />
            <br />
          </Hidden>

          <Grid
            container
            alignItems="center"
            className={classes.contentContainer}
          >
            <Grid item sm={12} xs={12}>
              <div style={{ textAlign: "right", marginBottom: 10 }}>
                <Link to={`/seatchart/${eventId}`}>
                  <Typography
                    variant="caption"
                    style={{ letterSpacing: 1, fontWeight: "bold" }}
                  >
                    {checkSeat === "true" ||
                    checkMeal === "true" ||
                    checkList === "true"
                      ? 1 + 1 + 1 + 1
                      : checkMeal === "true" ||
                        checkSeat === "false" ||
                        checkList === "false"
                      ? 1 + 1 + 0 + 0
                      : checkList === "false" ||
                        checkSeat === "false" ||
                        checkMeal === "false"
                      ? 1 + 0 + 0 + 0
                      : 0}
                    OF 4 COMPLETE | NEXT - SEAT CHARTING
                  </Typography>
                </Link>
              </div>

              <Paper
                elevation={2}
                style={{ height: "80vh", padding: 30, overflow: "auto" }}
              >
                <Grid
                  container
                  style={{
                    marginBottom: 10,
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  <Grid item xs={8}>
                    <div>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "bolder", marginBottom: 10 }}
                      >
                        <RestaurantIcon fontSize="small" /> {eventName} - Meal
                        Preferences
                        <MyButton onClick={handleOpen} tip="Settings">
                          <SettingsIcon color="error" fontSize="medium" />
                        </MyButton>
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item xs={4}>
                    <div style={{ textAlign: "right" }}>
                      <Button onClick={handleOpenMeal} variant="contained">
                        + MEAL CATEGORY
                      </Button>
                    </div>
                  </Grid>
                </Grid>

                {eventMenu.length === 0 ? (
                  <Fragment>
                    <Grid container>
                      <Grid item md={3}></Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ padding: 20, textAlign: "center" }}
                      >
                        <img
                          src={chef}
                          style={{ width: "100%" }}
                          alt="loading"
                        />
                        <Typography>
                          You have no meal category on your menu
                        </Typography>
                      </Grid>
                      <Grid item md={3}></Grid>
                    </Grid>
                  </Fragment>
                ) : menuActive === "true" ? (
                  <Fragment>
                    <Grid container>
                      <Grid
                        container
                        item
                        style={{ justifyContent: "center" }}
                        xs={12}
                      >
                        {menuMockup}
                      </Grid>
                    </Grid>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Grid container>
                      <Grid item md={3}></Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ padding: 20, textAlign: "center" }}
                      >
                        <img
                          src={chef}
                          style={{ width: "100%" }}
                          alt="loading"
                        />
                        <Typography>Activate menu plan to view menu</Typography>

                        <div style={{ marginTop: 30 }}>
                          <Button
                            variant="outlined"
                            onClick={handleOpen}
                            color="primary"
                          >
                            Settings
                          </Button>
                        </div>
                      </Grid>
                      <Grid item md={3}></Grid>
                    </Grid>
                  </Fragment>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={openSetting} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            {event.map((mel) => {
              const id = mel.id;
              const mealPlan = mel.mealRequired;

              return account === "Free" ? (
                <div>
                  <Typography>
                    Opps!!! you have to upgrade your account type to activate
                    the meal preference option.
                  </Typography>
                  <div style={{ marginTop: 20, textAlign: "center" }}>
                    <Button
                      component={Link}
                      to="/pricing"
                      size="medium"
                      variant="contained"
                    >
                      Upgrade
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <Typography>
                    By activating meal preference, guests would be able to
                    select a preferred meal while responding to the RSVP card
                  </Typography>
                  <hr />
                  <div
                    className={classes.inputSection}
                    style={{ textAlign: "center" }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch defaultChecked={mealPlan} />}
                        label="Activate Meal Preference"
                        color="primary"
                        onChange={conChecked}
                        name="mealRequired"
                      />
                    </FormGroup>
                  </div>

                  <div style={{ marginTop: 20, textAlign: "center" }}>
                    <Button
                      onClick={() => updateEvent(id)}
                      size="medium"
                      disabled={loading}
                      variant="contained"
                    >
                      save
                      {loading && (
                        <CircularProgress
                          color="primary"
                          size={30}
                          className={classes.progress}
                        />
                      )}
                    </Button>
                  </div>

                  {error === null ? (
                    <Typography
                      variant="body2"
                      color="primary"
                      gutterBottom
                      component="div"
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        marginTop: 10,
                      }}
                    >
                      {message}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      color="red"
                      gutterBottom
                      component="div"
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        marginTop: 10,
                      }}
                    >
                      {error}
                    </Typography>
                  )}
                </div>
              );
            })}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={openMeal} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            {event.map((mel) => {
              const mealPlan = mel.mealRequired;

              return mealPlan ? (
                <div>
                  <div style={{ padding: 5 }}>
                    <Typography
                      variant="caption"
                      style={{
                        color: "#707070",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      Meal Category
                    </Typography>
                    <TextField
                      size="small"
                      color="primary"
                      variant="outlined"
                      type="text"
                      name="mealCategory"
                      onChange={onChange}
                      fullWidth
                      required
                    />
                  </div>

                  <div style={{ padding: 5 }}>
                    <Typography
                      variant="caption"
                      style={{
                        color: "#707070",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      Description
                    </Typography>
                    <TextField
                      size="small"
                      color="primary"
                      variant="outlined"
                      type="text"
                      name="description"
                      onChange={onChange}
                      rows={4}
                      fullWidth
                      multiline
                      required
                    />
                  </div>

                  <div style={{ margin: 20, textAlign: "center" }}>
                    <Button
                      onClick={updateMeal}
                      size="medium"
                      disabled={loading}
                      variant="contained"
                    >
                      Add
                      {loading && (
                        <CircularProgress
                          color="primary"
                          size={30}
                          className={classes.progress}
                        />
                      )}
                    </Button>
                  </div>

                  {error === null ? (
                    <Typography
                      variant="body2"
                      color="primary"
                      gutterBottom
                      component="div"
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      {message}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      color="red"
                      gutterBottom
                      component="div"
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      {error}
                    </Typography>
                  )}
                </div>
              ) : (
                <div>
                  <Typography>
                    You have to activate the meal preference option in the
                    setting section before adding a meal
                  </Typography>
                  <div style={{ marginTop: 20, textAlign: "center" }}>
                    <Button
                      onClick={handleClose}
                      size="medium"
                      color="inherit"
                      variant="contained"
                    >
                      Close
                    </Button>
                  </div>
                </div>
              );
            })}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={editOpen} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            {oneMeal.map((mal) => {
              const id = mal.id;
              const mealCategoryb = mal.mealCategory;
              const descriptionb = mal.description;

              return (
                <div>
                  <div style={{ padding: 5 }}>
                    <Typography
                      variant="caption"
                      style={{
                        color: "#707070",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      Meal Category
                    </Typography>
                    <TextField
                      size="small"
                      color="primary"
                      defaultValue={mealCategoryb}
                      variant="outlined"
                      type="text"
                      name="mealCategory"
                      onChange={onChange}
                      fullWidth
                      required
                    />
                  </div>

                  <div style={{ padding: 5 }}>
                    <Typography
                      variant="caption"
                      style={{
                        color: "#707070",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      Description
                    </Typography>
                    <TextField
                      size="small"
                      color="primary"
                      variant="outlined"
                      type="text"
                      defaultValue={descriptionb}
                      name="description"
                      onChange={onChange}
                      rows={4}
                      fullWidth
                      multiline
                      required
                    />
                  </div>

                  <div style={{ margin: 20, textAlign: "center" }}>
                    <Button
                      onClick={() => editMeal(id, mealCategoryb, descriptionb)}
                      size="medium"
                      disabled={loading}
                      variant="contained"
                    >
                      update
                      {loading && (
                        <CircularProgress
                          color="primary"
                          size={30}
                          className={classes.progress}
                        />
                      )}
                    </Button>
                  </div>

                  {error === null ? (
                    <Typography
                      variant="body2"
                      color="primary"
                      gutterBottom
                      component="div"
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      {message}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      color="red"
                      gutterBottom
                      component="div"
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      {error}
                    </Typography>
                  )}

                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <hr />
                    <MyButton onClick={() => handleOpenDelete(id)} tip="Delete">
                      <DeleteIcon color="error" fontSize="medium" />
                    </MyButton>
                  </div>
                </div>
              );
            })}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={openDelete} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>Delete meal category?</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={deleteMeal} color="error">
            Yes
          </Button>
          <Button onClick={handleDeleteClose}>NO</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

Mealpreference.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Mealpreference);
