import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
//MUI
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import CelebrationIcon from "@mui/icons-material/Celebration";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import BusinessIcon from "@mui/icons-material/Business";
import PaidIcon from "@mui/icons-material/Paid";
import MicIcon from "@mui/icons-material/Mic";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import StrollerOutlinedIcon from "@mui/icons-material/StrollerOutlined";
import AllInclusiveOutlinedIcon from "@mui/icons-material/AllInclusiveOutlined";
import InputAdornment from "@mui/material/InputAdornment";

//Images
import logo from "../images/logo.png";
//AWS
import { Auth } from "aws-amplify";
import { DataStore } from "aws-amplify";
import { Event } from "../models";
import { User } from "../models";

import NavInner from "./NavInner";

const styles = (theme) => ({
  ...theme.spreadThis,
  logo: { height: 60 },
  contentContainer: { marginTop: 80, marginBottom: 10 },
  inputSection: { textAlign: "left", marginBottom: 30 },
  labelTxt: { paddingBottom: 10 },
});

const initialFormState = {
  eventName: "",
  eventType: "",
  eventUrl: "",
  startDate: "",
  endDate: "",
  guestNumber: "",
  publicState: true,
};

const CreateEvent = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [getData, setgetDate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const [numEvent, setNumevents] = useState(null);
  const [name, setName] = useState("");
  const [formState, updateFormState] = useState(initialFormState);

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const allUsers = await DataStore.query(User);
      const allEvent = await DataStore.query(Event);
      if (user == null) {
        setgetDate(false);
        navigate("/");
      } else {
        await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
          .then((user) => {
            const filteredAccount = allUsers
              .filter((use) => use.email === user.attributes.email)
              .map((amm) => amm.account);
            setAccount(String(filteredAccount));
            const filterEventsNum = allEvent.filter(
              (evn) =>
                evn.userId === user.attributes.sub && evn.creator === true
            );
            setNumevents(filterEventsNum.length);
          })
          .catch((err) => setError(err));
        setgetDate(false);
      }
    } catch (error) {
      setgetDate(false);
      console.log("error confirming:", error);
    }
  }

  function onChange(e) {
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  }

  const handleChange = (e) => {
    setName(e.target.value.split(" ").join(""));
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };

  async function createdEvent() {
    try {
      setError(null);
      setLoading(true);
      const userId = localStorage.getItem("userId");
      const {
        eventName,
        eventType,
        eventUrl,
        startDate,
        endDate,
        guestNumber,
        publicState,
      } = formState;

      const newStateDate = new Date(startDate).toISOString();
      const newEndDate = new Date(endDate).toISOString();

      const newEvent = {
        userId: userId,
        creator: true,
        eventName: eventName,
        eventUrl: eventUrl,
        eventType: eventType,
        startDate: newStateDate,
        endDate: newEndDate,
        guestNumber: guestNumber,
        rsvpTheme: "Default",
        emailRequired: true,
        phoneRequired: true,
        mealRequired: false,
        seatChart: false,
        checkList: false,
        einvite: false,
        sales: false,
        active: true,
        publicState: publicState,
        createdAt: new Date().toISOString(),
      };

      const creatEvent = await DataStore.save(new Event(newEvent));
      navigate(`/eventinfo/${creatEvent.id}`);
    } catch (error) {
      setLoading(false);
      setError(
        " An error occuried, check that all fields are filled correctly"
      );
      console.log("error signing up:", error);
    }
  }

  function checkEvent() {
    if (account === "Free") {
      if (numEvent >= 1) {
        setError(
          "Oops!!! You have to upgrade your account to manage more events"
        );
      } else {
        createdEvent();
      }
    } else {
      createdEvent();
    }
  }

  return getData ? (
    <Fragment>
      <Grid container>
        <Grid item md={4} align="center"></Grid>
        <Grid item md={4} align="center" style={{ padding: "7%" }}>
          <img
            src={logo}
            className={classes.icon}
            style={{ width: "100%" }}
            alt="loading"
          />
          <LinearProgress
            color="primary"
            style={{ width: "100%", marginTop: 10 }}
          />
        </Grid>
        <Grid item md={4} align="center"></Grid>
      </Grid>
    </Fragment>
  ) : (
    <Fragment>
      <NavInner />
      <Container maxWidth="lg">
        <Grid
          container
          alignItems="center"
          className={classes.contentContainer}
        >
          <Grid item sm={2} xs={2}></Grid>
          <Grid item sm={8} xs={12}>
            <div style={{ marginTop: "30px" }}>
              <Paper elevation={2} style={{ minHeight: 50, padding: 50 }}>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "-30px",
                    marginBottom: 20,
                    borderBottom: "1px solid #e1e1e1",
                  }}
                >
                  <Typography>
                    <CelebrationIcon style={{ fontSize: 100 }} />
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{ fontWeight: "bolder", marginBottom: 10 }}
                  >
                    Create Event
                  </Typography>
                </div>

                <div className={classes.inputSection}>
                  <Typography
                    variant="caption"
                    style={{
                      color: "#707070",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      letterSpacing: 1,
                    }}
                  >
                    Event Type
                  </Typography>

                  <Select
                    variant="outlined"
                    size="small"
                    name="eventType"
                    onChange={onChange}
                    placeholder="Select event type..."
                    fullWidth
                    autoFocus
                  >
                    <MenuItem value="Business">
                      <ListItemIcon>
                        <BusinessIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Business</ListItemText>
                    </MenuItem>
                    <MenuItem value="Gala or Fundriser">
                      <ListItemIcon>
                        <PaidIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Gala or Fundriser</ListItemText>
                    </MenuItem>
                    <MenuItem value="Wedding">
                      <ListItemIcon>
                        <CircleOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Wedding</ListItemText>
                    </MenuItem>
                    <MenuItem value="Birthday">
                      <ListItemIcon>
                        <CakeOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Birthday</ListItemText>
                    </MenuItem>
                    <MenuItem value="Baby Shower">
                      <ListItemIcon>
                        <StrollerOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Baby Shower</ListItemText>
                    </MenuItem>
                    <MenuItem value="Show or Performance">
                      <ListItemIcon>
                        <MicIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Show or Performance</ListItemText>
                    </MenuItem>
                    <MenuItem value="Others">
                      <ListItemIcon>
                        <AllInclusiveOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Others</ListItemText>
                    </MenuItem>
                  </Select>
                </div>

                <div className={classes.inputSection}>
                  <Typography
                    variant="caption"
                    style={{
                      color: "#707070",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      letterSpacing: 1,
                    }}
                  >
                    Event Name
                  </Typography>
                  <TextField
                    size="small"
                    color="primary"
                    placeholder="Type event name"
                    variant="outlined"
                    type="text"
                    name="eventName"
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </div>

                <div className={classes.inputSection}>
                  <Typography
                    variant="caption"
                    style={{
                      color: "#707070",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      letterSpacing: 1,
                    }}
                  >
                    Guestpin Event URL
                  </Typography>
                  <TextField
                    size="small"
                    color="primary"
                    placeholder="myevent"
                    variant="outlined"
                    type="text"
                    name="eventUrl"
                    value={name}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{
                            background: "#333",
                            height: 40,
                            padding: 5,
                            marginLeft: "-13px",
                          }}
                        >
                          <Typography
                            variant="body1"
                            color="white"
                            style={{ letterSpacing: 1 }}
                          >
                            guestpin.com/
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </div>

                <div
                  className={classes.inputSection}
                  style={{ color: "#707070", textAlign: "center" }}
                >
                  <Grid container alignItems="center">
                    <Grid item sm={6} xs={12} style={{ padding: 20 }}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#707070",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        Start Date
                      </Typography>
                      <TextField
                        size="small"
                        color="primary"
                        variant="outlined"
                        type="date"
                        name="startDate"
                        onChange={onChange}
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item sm={6} xs={12} style={{ padding: 20 }}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#707070",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                          textAlign: "right",
                        }}
                      >
                        End Date
                      </Typography>
                      <TextField
                        size="small"
                        color="primary"
                        variant="outlined"
                        type="date"
                        name="endDate"
                        onChange={onChange}
                        fullWidth
                        required
                      />
                    </Grid>
                  </Grid>
                  <Typography variant="caption" style={{ color: "#707070" }}>
                    Note: If it's a same day event, set start and end date to
                    same day
                  </Typography>
                </div>

                <div className={classes.inputSection}>
                  <Typography
                    variant="caption"
                    style={{
                      color: "#707070",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      letterSpacing: 1,
                    }}
                  >
                    About how many guests are you expecting?
                  </Typography>
                  <Select
                    variant="outlined"
                    size="small"
                    name="guestNumber"
                    onChange={onChange}
                    fullWidth
                  >
                    <MenuItem value="50"> 50 or fewer guests</MenuItem>
                    <MenuItem value="100"> 100 guests</MenuItem>
                    <MenuItem value="150"> 150 guests</MenuItem>
                    <MenuItem value="200"> 200 guests</MenuItem>
                    <MenuItem value="300">300 guests</MenuItem>
                    <MenuItem value="400">400 guests</MenuItem>
                    <MenuItem value="500">500 guests</MenuItem>
                    <MenuItem value="600">600 guests</MenuItem>
                    <MenuItem value="700">700 guests</MenuItem>
                    <MenuItem value="800">800 guests</MenuItem>
                    <MenuItem value="900">900 guests</MenuItem>
                    <MenuItem value="1000">1000 guests</MenuItem>
                    <MenuItem value="2000">2000 guests</MenuItem>
                    <MenuItem value="3000">3000 guests</MenuItem>
                    <MenuItem value="4000">4000 guests</MenuItem>
                    <MenuItem value="5000">5000 guests or more guests</MenuItem>
                  </Select>
                </div>

                <div className={classes.inputSection}>
                  <Typography
                    variant="caption"
                    style={{
                      color: "#707070",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      letterSpacing: 1,
                    }}
                  >
                    Is this a public event?
                  </Typography>
                  <Select
                    variant="outlined"
                    size="small"
                    name="publicState"
                    onChange={onChange}
                    fullWidth
                  >
                    <MenuItem value={true}> Yes</MenuItem>
                    <MenuItem value={false}> No</MenuItem>
                  </Select>
                  <Typography variant="caption" style={{ color: "#707070" }}>
                    Note: If an event is "Public", anyone can make reservation
                    for the event
                  </Typography>
                </div>

                {error === null ? null : (
                  <div
                    style={{
                      marginBottom: 20,
                      marginTop: "-10px",
                      textAlign: "right",
                    }}
                  >
                    <Typography
                      variant="caption"
                      style={{ textAlign: "right" }}
                      color="error"
                    >
                      {error}
                    </Typography>
                  </div>
                )}

                <div className={classes.inputSection}>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    style={{ fontSize: 13 }}
                    disabled={loading}
                    onClick={checkEvent}
                    fullWidth
                  >
                    Create
                    {loading && (
                      <CircularProgress
                        color="primary"
                        size={30}
                        className={classes.progress}
                      />
                    )}
                  </Button>
                </div>
              </Paper>
            </div>
          </Grid>
          <Grid item sm={2} xs={2}></Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

CreateEvent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateEvent);
