import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
//MUI
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
//Imports
import Nav from "./Nav";
import NavInner from "./NavInner";
import Footer from "./Footer";
import priceimg1 from "../images/priceimg1.jpg";
import priceimg2 from "../images/priceimg2.jpg";
import priceimg3 from "../images/priceimg3.jpg";
import priceimg4 from "../images/priceimg4.jpg";
import tickets from "../images/tickets.png";
//AWS
import { Auth } from "aws-amplify";

const styles = (theme) => ({
  ...theme.spreadThis,
  logo: { height: 60 },
  contentContainer: { marginTop: 80 },
  priceImg: { width: "80%" },
  inputSection: { textAlign: "left", marginBottom: 30 },
  labelTxt: { paddingBottom: 10 },
});

const Signin = (props) => {
  const { classes } = props;
  const [getData, setgetDate] = useState(false);

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user != null) {
        setgetDate(true);
      } else {
        setgetDate(false);
      }
    } catch (error) {
      setgetDate(false);
      console.log("error confirming:", error);
    }
  }

  return (
    <Fragment>
      {getData ? <NavInner /> : <Nav />}

      <Container maxWidth="lg">
        <Grid container className={classes.contentContainer}>
          <Grid item lg={12} style={{ textAlign: "center" }}>
            <Typography
              variant="h3"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              Pricing Plan
            </Typography>
            <Typography
              variant="body2"
              style={{ marginTop: 5, marginBottom: 10 }}
            >
              Pricing built for all event types with special offers for ticket
              sales
            </Typography>
          </Grid>

          <Grid container alignItems="center">
            <Grid item md={3} style={{ padding: 5 }}>
              <Paper elevation={2} style={{ borderRadius: 20 }}>
                <div style={{ textAlign: "center", padding: "30px 0px" }}>
                  <img
                    src={priceimg2}
                    className={classes.priceImg}
                    alt="loading"
                  />
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Bronze
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      marginTop: 5,
                      marginBottom: 10,
                      fontWeight: "lighter",
                    }}
                  >
                    Price :
                  </Typography>

                  <Typography
                    variant="h5"
                    color="primary"
                    style={{
                      marginTop: 5,
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    FREE
                  </Typography>
                  <Divider />
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">Email invitation</Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">
                    Online RSVP for guests
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2" color="lightgray">
                    Active check list
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2" color="lightgray">
                    Meal perference
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2" color="lightgray">
                    Manage multiple events
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2" color="lightgray">
                    Cash gifts / Integrate gift registry
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2" color="lightgray">
                    Seat Charting
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2" color="lightgray">
                    Onsite Support
                  </Typography>
                  <Typography variant="caption" color="lightgray">
                    Have our team on-ground at your event to manage guests
                  </Typography>
                </div>

                <div
                  style={{
                    textAlign: "center",
                    padding: "30px 0px",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ borderRadius: 20 }}
                  >
                    Try out
                  </Button>
                </div>
              </Paper>
            </Grid>

            <Grid item md={3} style={{ textAlign: "center", padding: 5 }}>
              <Paper elevation={2} style={{ borderRadius: 20 }}>
                <div style={{ textAlign: "center", padding: "30px 0px" }}>
                  <img
                    src={priceimg1}
                    className={classes.priceImg}
                    alt="loading"
                  />
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Silver
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      marginTop: 5,
                      marginBottom: 10,
                      fontWeight: "lighter",
                    }}
                  >
                    Price :
                  </Typography>

                  <Typography
                    variant="h5"
                    color="primary"
                    style={{
                      marginTop: 5,
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    ₦20,000.00<span style={{ fontSize: 13 }}> / Month</span>
                  </Typography>
                  <Divider />
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">Email invitation</Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">
                    Online RSVP for guests
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">Active check list</Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">Meal perference</Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">
                    Manage multiple events
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">
                    Cash gifts / Integrate gift registry
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2" color="lightgray">
                    Seat Charting
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2" color="lightgray">
                    Onsite Support
                  </Typography>
                  <Typography variant="caption" color="lightgray">
                    Have our team on-ground at your event to manage guests
                  </Typography>
                </div>

                <div
                  style={{
                    textAlign: "center",
                    padding: "30px 0px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ borderRadius: 20 }}
                  >
                    Select
                  </Button>
                </div>
              </Paper>
            </Grid>

            <Grid
              item
              md={3}
              style={{
                textAlign: "center",
                padding: 5,
              }}
            >
              <Paper
                elevation={2}
                style={{
                  borderRadius: 20,
                  border: "3px solid #6665A6",
                }}
              >
                <div
                  style={{
                    background: "#6665A6",
                    textAlign: "center",
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                  }}
                >
                  <Typography variant="body1" color="white">
                    BEST VALUE
                  </Typography>
                </div>

                <div style={{ textAlign: "center", padding: "30px 0px" }}>
                  <img
                    src={priceimg3}
                    className={classes.priceImg}
                    alt="loading"
                  />
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Gold
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      marginTop: 5,
                      marginBottom: 10,
                      fontWeight: "lighter",
                    }}
                  >
                    Price :
                  </Typography>

                  <Typography
                    variant="h5"
                    color="primary"
                    style={{
                      marginTop: 5,
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    ₦50,000.00<span style={{ fontSize: 13 }}> / Month</span>
                  </Typography>
                  <Divider />
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">Email invitation</Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">
                    Online RSVP for guests
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">Active check list</Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">Meal perference</Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">
                    Manage multiple events
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">
                    Cash gifts / Integrate gift registry
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">Seat Charting</Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2" color="lightgray">
                    Onsite Support
                  </Typography>
                  <Typography variant="caption" color="lightgray">
                    Have our team on-ground at your event to manage guests
                  </Typography>
                </div>

                <div
                  style={{
                    textAlign: "center",
                    padding: "30px 0px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ borderRadius: 20 }}
                  >
                    Select
                  </Button>
                </div>
              </Paper>
            </Grid>

            <Grid item md={3} style={{ textAlign: "center", padding: 5 }}>
              <Paper elevation={2} style={{ borderRadius: 20 }}>
                <div style={{ textAlign: "center", padding: "30px 0px" }}>
                  <img
                    src={priceimg4}
                    className={classes.priceImg}
                    alt="loading"
                  />

                  <Typography
                    variant="h6"
                    style={{
                      marginTop: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Platinum
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{
                      marginTop: 5,
                      marginBottom: 10,
                      fontWeight: "lighter",
                    }}
                  >
                    Starting at :
                  </Typography>

                  <Typography
                    variant="h5"
                    color="primary"
                    style={{
                      marginTop: 5,
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    ₦1,000,000.00
                  </Typography>
                  <Divider />
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">Email invitation</Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">
                    Online RSVP for guests
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">Active check list</Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">Meal perference</Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">
                    Manage multiple events
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">
                    Cash gifts / Integrate gift registry
                  </Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">Seat Charting</Typography>
                </div>

                <div style={{ textAlign: "center", marginBottom: 15 }}>
                  <Typography variant="body2">Onsite Support</Typography>
                  <Typography variant="caption">
                    Have our team on-ground at your event to manage guests
                  </Typography>
                </div>

                <div
                  style={{
                    textAlign: "center",
                    padding: "30px 0px",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ borderRadius: 20 }}
                  >
                    Contact us
                  </Button>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <div>
        <Grid container style={{ minHeight: "50vh", padding: 30 }}>
          <Grid item lg={6} style={{ padding: 50 }}>
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Selling tickets?
            </Typography>
            <Typography variant="subtitle1" style={{ marginTop: 20 }}>
              Guestpin offers a powerful online ticketing platform unlike no
              other. If you’re selling tickets to your event, here is what you
              get:
            </Typography>

            <Typography variant="subtitle1" style={{ marginTop: 10 }}>
              * Just ₦150 per ticket or donation + 2.5% or choose to transfer
              fees to your customer and pay nothing
            </Typography>

            <Typography variant="subtitle1" style={{ marginTop: 10 }}>
              * Make x2 of Guestpin's subscription plans in ticket sales and get
              a free upgrade to the professional tier with a powerful suite of
              guest management tools for ree
            </Typography>

            <Typography variant="subtitle1" style={{ marginTop: 10 }}>
              * An amazing ticketing experience like no other
            </Typography>

            <Typography
              variant="subtitle1"
              color="primary"
              style={{ marginTop: 30, fontStyle: "italic" }}
            >
              Credit / Debit card processing fees are separate and assessed
              payment processor
            </Typography>

            <div style={{ marginTop: 30 }}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/signup"
                style={{ borderRadius: 20 }}
              >
                Get Started
              </Button>
            </div>
          </Grid>

          <Grid item lg={6} style={{ padding: 50 }}>
            <img src={tickets} style={{ width: "100%" }} alt="preview" />
          </Grid>
        </Grid>
      </div>

      {getData ? null : <Footer />}
    </Fragment>
  );
};

Signin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Signin);
