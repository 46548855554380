import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
//MUI
import Hidden from "@mui/material/Hidden";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SettingsIcon from "@mui/icons-material/Settings";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import ArticleIcon from "@mui/icons-material/Article";

//Imports
import logo from "../images/logo.png";
import EventMenu from "./EventMenu";
import EventMenulg from "./EventMenulg";
import MyButton from "../utility/MyButton";
//AWS
import { Auth } from "aws-amplify";
import { DataStore } from "aws-amplify";
import { Event } from "../models";
import { User } from "../models";
import { GuestList } from "../models";

const styles = (theme) => ({
  ...theme.spreadThis,
  logo: { height: 60 },
  contentContainer: { marginTop: 20 },
  inputSection: { textAlign: "left", marginBottom: 20 },
  labelTxt: { paddingBottom: 10 },
});

const initialFormState = {
  titleName: "",
  firstName: "",
  status: "",
  type: "",
  email: "",
  phone: "",
  search: "",
  phoneRequiredb: false,
};

const Guestlists = (props) => {
  const { classes } = props;
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [getData, setgetDate] = useState(true);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [edit, setEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const [eventName, setEventName] = useState(null);
  const [eventUrl, setEventUrl] = useState(null);
  const [addedGuest, setAddedGuest] = useState(null);
  const [eventState, setEventState] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [allVisitors, setAllVisitors] = useState([]);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [event, setEvent] = useState([]);
  const [eventGuest, setEventGuest] = useState([]);
  const [allGuest, setAllGuest] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [notAttending, setNotAttending] = useState([]);
  const [attending, setAttending] = useState([]);
  const [pending, setPending] = useState([]);
  const [notSure, setNotSure] = useState([]);
  const [formState, updateFormState] = useState(initialFormState);
  const [sorted, setSort] = useState("des");
  const [totalGuest, setTotalGuest] = useState(null);
  const [exGuest, setExGuest] = useState(null);
  const [guestId, setGuestId] = useState(null);
  const [guestTitle, setGuestTitle] = useState(null);
  const [guestName, setGuestName] = useState(null);
  const [guestType, setGuestType] = useState(null);
  const [guestStatus, setGuestStatus] = useState(null);
  const [guestEmail, setGuestEmail] = useState(null);
  const [guestPhone, setGuestPhone] = useState(null);
  const [guestMeal, setGuestMeal] = useState(null);
  const [guestTable, setGuestTable] = useState(null);
  const [showNotAttending, setShowNotAttending] = useState([]);
  const [showAttending, setShowAttending] = useState([]);
  const [showPending, setShowPending] = useState([]);
  const [showNotSure, setShowNotSure] = useState([]);
  const [checkMeal, setCheckMeal] = useState(null);
  const [checkSeat, setCheckSeat] = useState(null);
  const [checkList, setCheckList] = useState(null);

  useEffect(() => {
    checkUser();
    obersation();
  }, []);

  function obersation() {
    DataStore.observe(GuestList).subscribe((msg) => {
      checkUser();
    });
  }

  async function checkUser() {
    try {
      const allUsers = await DataStore.query(User);
      const allEvent = await DataStore.query(Event);
      const allGuests = await DataStore.query(GuestList);
      const user = await Auth.currentAuthenticatedUser();
      const userId = localStorage.getItem("userId");

      if (user == null) {
        setgetDate(false);
        navigate("/");
      } else {
        await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
          .then((user) => {
            const filteredAccount = allUsers
              .filter((use) => use.email === user.attributes.email)
              .map((amm) => amm.account);
            setAccount(String(filteredAccount));

            const filterAllGuest = allGuests.filter(
              (evn) => evn.userId === userId
            );
            setAllGuest(filterAllGuest);

            const filterTotalGuest = allGuests.filter(
              (evn) => evn.eventId === eventId
            );
            setTotalGuest(filterTotalGuest);

            const filterGuest = allGuests.filter(
              (evn) => evn.eventId === eventId && evn.title !== "+1"
            );

            const sortedGuest = filterGuest.sort((a, b) => {
              const mySort = sorted === "des" ? 1 : -1;
              return mySort * a.firstName.localeCompare(b.firstName);
            });

            setEventGuest(sortedGuest);
            setAllVisitors(sortedGuest);

            const filterNotAttending = allGuests.filter(
              (evn) => evn.eventId === eventId && evn.status === "Absent"
            );
            setNotAttending(filterNotAttending);

            const filterotAttending = allGuests.filter(
              (evn) =>
                evn.eventId === eventId &&
                evn.status === "Absent" &&
                evn.title !== "+1"
            );
            const sortedNotAttending = filterotAttending.sort((a, b) => {
              const mySort = sorted === "des" ? 1 : -1;
              return mySort * b.updatedAt.localeCompare(a.updatedAt);
            });
            setShowNotAttending(sortedNotAttending);

            const filterAttending = allGuests.filter(
              (evn) => evn.eventId === eventId && evn.status === "Attending"
            );
            setAttending(filterAttending);

            const filterttending = allGuests.filter(
              (evn) =>
                evn.eventId === eventId &&
                evn.status === "Attending" &&
                evn.title !== "+1"
            );
            const sortedAttending = filterttending.sort((a, b) => {
              const mySort = sorted === "des" ? 1 : -1;
              return mySort * b.updatedAt.localeCompare(a.updatedAt);
            });
            setShowAttending(sortedAttending);

            const filterPending = allGuests.filter(
              (evn) => evn.eventId === eventId && evn.status === "Pending"
            );
            setPending(filterPending);

            const filterending = allGuests.filter(
              (evn) =>
                evn.eventId === eventId &&
                evn.status === "Pending" &&
                evn.title !== "+1"
            );
            setShowPending(filterending);

            const filterNotSure = allGuests.filter(
              (evn) => evn.eventId === eventId && evn.status === "Not Sure"
            );
            setNotSure(filterNotSure);

            const filterotSure = allGuests.filter(
              (evn) =>
                evn.eventId === eventId &&
                evn.status === "Not Sure" &&
                evn.title !== "+1"
            );
            const sortedNotSure = filterotSure.sort((a, b) => {
              const mySort = sorted === "des" ? 1 : -1;
              return mySort * b.updatedAt.localeCompare(a.updatedAt);
            });
            setShowNotSure(sortedNotSure);

            const filterEvent = allEvent.filter((evn) => evn.id === eventId);
            setEvent(filterEvent);

            const filteredEventName = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.eventName);
            setEventName(String(filteredEventName));

            const filteredEventUrl = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.eventUrl);
            setEventUrl(String(filteredEventUrl));

            const filteredEventState = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.publicState);
            setEventState(String(filteredEventState));

            const filteredGuestNum = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.guestNumber);
            setExGuest(String(filteredGuestNum));

            const filteredMeal = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.mealRequired);
            setCheckMeal(String(filteredMeal));

            const filteredSeat = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.seatChart);
            setCheckSeat(String(filteredSeat));

            const filteredList = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.checkList);
            setCheckList(String(filteredList));
          })
          .catch((err) => setError(err));
        setgetDate(false);
      }
    } catch (error) {
      console.log("error confirming:", error);
    }
  }

  function onChange(e) {
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  }

  //Working Search Code
  function onChangeSearch(e) {
    const conde = e.target.value;
    const newSearch = allVisitors.filter((news) =>
      news.firstName.toLocaleLowerCase().includes(conde.toLocaleLowerCase())
    );
    setEventGuest(newSearch);
  }

  function showList(newSearch) {
    setEventGuest(newSearch);
  }

  function conChecked(e) {
    updateFormState(() => ({
      ...formState,
      [e.target.name]: e.target.checked,
    }));
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
    setMessage(null);
    setError(null);
  };

  function editClick() {
    setEdit(false);
  }

  function stopEdit() {
    setEdit(true);
  }

  const handleClose = () => {
    setOpen(false);
    setOpenAdd(false);
    setEditOpen(false);
    setOpenDelete(false);
    setEdit(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  async function updateEvent(id, phoneRequired) {
    try {
      setLoading(true);
      setError(null);
      setMessage(null);
      const { phoneRequiredb } = formState;
      const myEvt = await DataStore.query(Event, id);

      const newPhoneRequired =
        phoneRequiredb === "" ? phoneRequired : phoneRequiredb;

      await DataStore.save(
        Event.copyOf(myEvt, (myUpdate) => {
          myUpdate.phoneRequired = newPhoneRequired;
        })
      );
      setMessage("Update Successful");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  const editGuestInfo = (
    id,
    titleName,
    firstName,
    type,
    status,
    email,
    phone,
    meal,
    table,
    createdAt
  ) => {
    setError(null);
    setMessage(null);
    setEdit(true);
    setEditOpen(true);

    updateFormState(initialFormState);

    setGuestId(id);
    setGuestTitle(titleName);
    setGuestName(firstName);
    setGuestType(type);
    setGuestStatus(status);
    setGuestEmail(email);
    setGuestPhone(phone);
    setGuestMeal(meal);
    setGuestTable(table);

    if (type === "Admit two") {
      const filteredAdd = allGuest
        .filter((use) => use.firstName === createdAt)
        .map((amm) => amm.id);
      setAddedGuest(String(filteredAdd));
    }
  };

  async function updateGuest() {
    try {
      setLoading(true);

      setError(null);

      setMessage(null);

      const { titleName, firstName, status, type, email, phone } = formState;

      const newTitle = titleName === "" ? guestTitle : titleName;

      const newFirstName = firstName === "" ? guestName : firstName;

      const newStatus = status === "" ? guestStatus : status;

      const newType = type === "" ? guestType : type;

      const newEmail = email === "" ? guestEmail : email;

      const newPhone = phone === "" ? guestPhone : phone;

      const myGst = await DataStore.query(GuestList, guestId);

      await DataStore.save(
        GuestList.copyOf(myGst, (myUpdate) => {
          myUpdate.title = newTitle;
          myUpdate.firstName = newFirstName;
          myUpdate.status = newStatus;
          myUpdate.type = newType;
          myUpdate.email = newEmail;
          myUpdate.phone = newPhone;
        })
      );

      if (addedGuest != null) {
        const myGst1 = await DataStore.query(GuestList, addedGuest);
        await DataStore.save(
          GuestList.copyOf(myGst1, (myUpdate) => {
            myUpdate.status = newStatus;
          })
        );
      }

      setMessage("Update Successful");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Error", error);
    }
  }

  const handleOpenDelete = (idz) => {
    setOpenDelete(true);
    setDeleteId(idz);
  };

  async function deleteGuest() {
    try {
      const todelete = await DataStore.query(GuestList, deleteId);
      DataStore.delete(todelete);
      const todeleteAdd = await DataStore.query(GuestList, addedGuest);
      DataStore.delete(todeleteAdd);
      handleClose();
    } catch (error) {
      console.log("Delete Error", error);
    }
  }

  async function addGuest() {
    try {
      setLoading(true);

      setError(null);

      setMessage(null);

      const userId = localStorage.getItem("userId");

      const { titleName, firstName, type, phone, email } = formState;

      const newGuest = {
        userId: userId,
        eventId: eventId,
        title: titleName,
        firstName: firstName,
        status: "Pending",
        type: type,
        email: email,
        phone: phone,
        mealPreference: "",
        tableNumber: 0,
        checkIn: false,
        createdAt: new Date().toISOString(),
      };

      const newGuest2 = {
        userId: userId,
        eventId: eventId,
        title: "+1",
        firstName: new Date().toISOString(),
        status: "Added",
        type: "",
        email: "",
        phone: "",
        mealPreference: "",
        tableNumber: 0,
        checkIn: false,
        createdAt: new Date().toISOString(),
      };

      if (parseInt(totalGuest) >= parseInt(exGuest)) {
        setError(
          "Oops!!!you have exceeded your expected guest number. Increase or delete guests"
        );
        setLoading(false);
      } else {
        if (account === "Free") {
          if (allGuest.length >= 50) {
            setError(
              "Oops!!!You have exceeded the number of RSVPs you can manage. Upgrade your account to enjoy unlimited RSVP management"
            );
          } else {
            if (type === "Admit two") {
              await DataStore.save(new GuestList(newGuest));
              await DataStore.save(new GuestList(newGuest2));
              setMessage("Guest added successfully");
              setLoading(false);
            } else {
              await DataStore.save(new GuestList(newGuest));
              setMessage("Guest added successfully");
              setLoading(false);
            }
          }
        } else {
          if (type === "Admit two") {
            await DataStore.save(new GuestList(newGuest));
            await DataStore.save(new GuestList(newGuest2));
            setMessage("Guest added successfully");
            setLoading(false);
          } else {
            await DataStore.save(new GuestList(newGuest));
            setMessage("Guest added successfully");
            setLoading(false);
          }
        }
      }
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  let rsvpInfo = event.map((evnt, idx) => {
    const id = evnt.id;
    const rsvpTheme = evnt.rsvpTheme;
    const phoneRequired = evnt.phoneRequired;
    const guestNumber = evnt.guestNumber;
    const newNotAttending = (notAttending.length / parseInt(guestNumber)) * 100;
    const newAttending = (attending.length / parseInt(guestNumber)) * 100;
    const newPending = (pending.length / parseInt(guestNumber)) * 100;
    const newNotSure = (notSure.length / parseInt(guestNumber)) * 100;

    return (
      <Fragment>
        <Dialog open={open} onClose={handleClose} key={idx}>
          <DialogActions>
            <div style={{ borderBottom: "1px solid #e1e1e1", width: "100%" }}>
              <Grid container>
                <Grid item xs={10} style={{ textAlign: "left" }}>
                  <Typography variant="h6" style={{ fontWeight: "bold" }}>
                    Guestlist Settings
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ textAlign: "right", marginTop: "-10px" }}
                >
                  <MyButton onClick={handleClose} tip="Close">
                    <CloseIcon fontSize="large" />
                  </MyButton>
                </Grid>
              </Grid>
            </div>
          </DialogActions>

          <DialogContent>
            <div className={classes.inputSection}>
              <Typography
                variant="caption"
                style={{
                  color: "#707070",
                  fontWeight: "bold",
                  letterSpacing: 1,
                }}
              >
                RSVP Theme
              </Typography>
              <Select
                variant="outlined"
                size="small"
                defaultValue={rsvpTheme}
                name="rsvpTheme"
                fullWidth
              >
                <MenuItem value="Default"> Default</MenuItem>
              </Select>
            </div>

            <div className={classes.inputSection}>
              <Grid
                container
                alignItems="center"
                style={{ textAlign: "center" }}
              >
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch defaultChecked />}
                      label="Email Required"
                      size="small"
                      color="primary"
                      disabled
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch defaultChecked />}
                      label="Phone Required"
                      color="primary"
                      onChange={conChecked}
                      disabled
                      name="phoneRequiredb"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </div>

            <div
              className={classes.inputSection}
              style={{ textAlign: "right" }}
            >
              <Button
                size="large"
                color="primary"
                variant="contained"
                style={{ fontSize: 13, marginBottom: 10 }}
                disabled={loading}
                onClick={() => updateEvent(id, phoneRequired)}
              >
                Save
                {loading && (
                  <CircularProgress
                    color="primary"
                    size={30}
                    className={classes.progress}
                  />
                )}
              </Button>
              {error === null ? (
                <Typography
                  variant="body2"
                  color="primary"
                  gutterBottom
                  component="div"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  {message}
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  color="red"
                  gutterBottom
                  component="div"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  {error}
                </Typography>
              )}
            </div>
          </DialogContent>
        </Dialog>

        <div className={classes.inputSection}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                background: "gray",
                borderRadius: 20,
                height: 10,
                marginTop: 10,
              }}
            >
              <Box
                style={{
                  width: `${newNotAttending}%`,
                  height: 10,
                  backgroundColor: "red",
                  float: "left",
                }}
              />
              <Box
                style={{
                  width: `${newAttending}%`,
                  height: 10,
                  backgroundColor: "green",
                  float: "left",
                }}
              />
              <Box
                style={{
                  width: `${newPending}%`,
                  height: 10,
                  backgroundColor: "orange",
                  float: "left",
                }}
              />

              <Box
                style={{
                  width: `${newNotSure}%`,
                  height: 10,
                  backgroundColor: "gray",
                  float: "left",
                }}
              />
            </Grid>

            <Grid
              container
              alignItems="center"
              style={{
                textAlign: "center",
              }}
            >
              <Grid item xs={3}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    cursor: "pointer",
                  }}
                  component="div"
                  onClick={() => showList(showNotAttending)}
                >
                  {notAttending.length}
                </Typography>
                <Typography variant="caption" component="div">
                  Absent
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    color: "green",
                    cursor: "pointer",
                  }}
                  component="div"
                  onClick={() => showList(showAttending)}
                >
                  {attending.length}
                </Typography>
                <Typography variant="caption" component="div">
                  Attending
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    color: "gray",
                    cursor: "pointer",
                  }}
                  component="div"
                  onClick={() => showList(showNotSure)}
                >
                  {notSure.length}
                </Typography>
                <Typography variant="caption" component="div">
                  Not Sure
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    color: "orange",
                    cursor: "pointer",
                  }}
                  component="div"
                  onClick={() => showList(showPending)}
                >
                  {pending.length}
                </Typography>
                <Typography variant="caption" component="div">
                  Pending
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    );
  });

  let guestStruct =
    eventGuest.length === 0 ? (
      <TableBody style={{ background: "#e1e1e1" }} key={0}>
        <TableRow>
          <TableCell align="center" colSpan={5}>
            You have no guests RSVP
          </TableCell>
        </TableRow>
      </TableBody>
    ) : (
      eventGuest
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((gest, idx) => {
          const id = gest.id;
          const titleName = gest.title;
          const firstName = gest.firstName;
          const type = gest.type;
          const status = gest.status;
          const email = gest.email;
          const phone = gest.phone;
          const meal = gest.mealPreference;
          const table = gest.tableNumber;
          const createdAt = gest.createdAt;

          return (
            <TableBody key={idx}>
              <TableRow hover role="none" tabIndex={-1}>
                <TableCell align="left">
                  <Typography variant="body2">{titleName}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2">{firstName}</Typography>
                </TableCell>

                <TableCell align="left">
                  <Typography variant="body2">{email}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body2">{phone}</Typography>
                </TableCell>
                <TableCell align="right">
                  {status === "Pending" ? (
                    <Button
                      onClick={() =>
                        editGuestInfo(
                          id,
                          titleName,
                          firstName,
                          type,
                          status,
                          email,
                          phone,
                          meal,
                          table,
                          createdAt
                        )
                      }
                      variant="outlined"
                      style={{
                        width: "100%",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                      size="small"
                      color="warning"
                    >
                      {status}
                    </Button>
                  ) : status === "Absent" ? (
                    <Button
                      onClick={() =>
                        editGuestInfo(
                          id,
                          titleName,
                          firstName,
                          type,
                          status,
                          email,
                          phone,
                          meal,
                          table,
                          createdAt
                        )
                      }
                      variant="outlined"
                      style={{
                        width: "100%",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                      size="small"
                      color="error"
                    >
                      {status}
                    </Button>
                  ) : status === "Attending" ? (
                    <Button
                      onClick={() =>
                        editGuestInfo(
                          id,
                          titleName,
                          firstName,
                          type,
                          status,
                          email,
                          phone,
                          meal,
                          table,
                          createdAt
                        )
                      }
                      variant="outlined"
                      style={{
                        width: "100%",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                      size="small"
                      color="success"
                    >
                      {status}
                    </Button>
                  ) : (
                    <Button
                      onClick={() =>
                        editGuestInfo(
                          id,
                          titleName,
                          firstName,
                          type,
                          status,
                          email,
                          phone,
                          meal,
                          table,
                          createdAt
                        )
                      }
                      variant="outlined"
                      style={{
                        width: "100%",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                      size="small"
                      color="inherit"
                    >
                      {status}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          );
        })
    );

  return getData ? (
    <Fragment>
      <Grid container>
        <Grid item md={4} align="center"></Grid>
        <Grid item md={4} align="center" style={{ padding: "7%" }}>
          <img
            src={logo}
            className={classes.icon}
            style={{ width: "100%" }}
            alt="loading"
          />
          <LinearProgress
            color="primary"
            style={{ width: "100%", marginTop: 10 }}
          />
        </Grid>
        <Grid item md={4} align="center"></Grid>
      </Grid>
    </Fragment>
  ) : (
    <Grid container>
      <Hidden lgDown>
        <Grid
          item
          md={12}
          lg={2}
          style={{ height: "100vh", background: "#6665A6" }}
        >
          <EventMenu />
        </Grid>
      </Hidden>
      <Grid item xs={12} lg={10} style={{ padding: 30 }}>
        <Hidden lgUp>
          <EventMenulg />
          <br />
          <br />
        </Hidden>
        <Grid
          container
          alignItems="center"
          className={classes.contentContainer}
        >
          <Grid item sm={12} xs={12}>
            <div style={{ textAlign: "right", marginBottom: 10 }}>
              <Link to={`/mealpreference/${eventId}`}>
                <Typography
                  variant="caption"
                  style={{ letterSpacing: 1, fontWeight: "bold" }}
                >
                  {checkSeat === "true" ||
                  checkMeal === "true" ||
                  checkList === "true"
                    ? 1 + 1 + 1 + 1
                    : checkMeal === "true" ||
                      checkSeat === "false" ||
                      checkList === "false"
                    ? 1 + 1 + 0 + 0
                    : checkList === "false" ||
                      checkSeat === "false" ||
                      checkMeal === "false"
                    ? 1 + 0 + 0 + 0
                    : 0}
                  OF 4 COMPLETE | NEXT - MEAL PREFERENCE
                </Typography>
              </Link>
            </div>

            <Paper
              elevation={2}
              style={{ height: "80vh", padding: 30, overflow: "auto" }}
            >
              <Grid
                container
                style={{
                  borderBottom: "1px solid #e1e1e1",
                }}
              >
                <Grid item xs={8}>
                  <div>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bolder", marginBottom: 10 }}
                    >
                      <GroupAddIcon fontSize="small" /> {eventName} - GuestList
                      <MyButton onClick={handleOpen} tip="Edit Info">
                        <SettingsIcon color="error" fontSize="medium" />
                      </MyButton>
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div style={{ textAlign: "right" }}>
                    <Link to={`/rsvp/${eventUrl}`} target="_blank">
                      <Button variant="contained" size="small">
                        Preview
                      </Button>
                    </Link>
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={12} style={{ padding: 20 }}>
                  {rsvpInfo}
                  <div
                    className={classes.inputSection}
                    style={{ marginTop: 50 }}
                  >
                    <Grid container>
                      <Grid item xs={6} lg={8} style={{ padding: 10 }}>
                        <TextField
                          id="search"
                          variant="outlined"
                          size="small"
                          name="search"
                          type="text"
                          onChange={onChangeSearch}
                          color="primary"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon fontSize="small" />
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Enter guest name"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={3} lg={2} style={{ padding: 10 }}>
                        <div style={{ textAlign: "center" }}>
                          <Button
                            onClick={handleOpenAdd}
                            variant="contained"
                            color="inherit"
                            size="small"
                            //disabled={eventState === "true" ? true : false}
                            fullWidth
                            disabled
                          >
                            <ArticleIcon
                              fontSize="small"
                              style={{ marginRight: 10 }}
                            />
                            Upload File
                          </Button>
                        </div>
                      </Grid>

                      <Grid item xs={3} lg={2} style={{ padding: 10 }}>
                        <div style={{ textAlign: "center" }}>
                          <Button
                            onClick={handleOpenAdd}
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={eventState === "true" ? true : false}
                            fullWidth
                          >
                            <PersonAddIcon
                              fontSize="small"
                              style={{ marginRight: 10 }}
                            />
                            Add Guest
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={{
                      height: "45vh",
                      overflow: "hidden",
                      width: "100%",
                      background: "#f5f5f5",
                    }}
                  >
                    <TableContainer style={{ height: "40vh" }}>
                      <Table stickyHeader sx={{ width: "100%" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                fontSize: 13,
                                color: "#707070",
                              }}
                            >
                              TITLE
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                fontSize: 13,
                                color: "#707070",
                              }}
                            >
                              FULL NAME
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                fontSize: 13,
                                color: "#707070",
                              }}
                            >
                              EMAIL
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                fontSize: 13,
                                color: "#707070",
                              }}
                            >
                              PHONE
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{
                                fontWeight: "bold",
                                fontSize: 13,
                                color: "#707070",
                              }}
                            >
                              REPLY
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {guestStruct}
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      component="div"
                      count={eventGuest.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={openAdd}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogActions>
          <div style={{ borderBottom: "1px solid #e1e1e1", width: "100%" }}>
            <Grid container>
              <Grid item xs={10} style={{ textAlign: "left" }}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Guestlist Addition
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ textAlign: "right", marginTop: "-10px" }}
              >
                <MyButton onClick={handleClose} tip="Close">
                  <CloseIcon fontSize="large" />
                </MyButton>
              </Grid>
            </Grid>
          </div>
        </DialogActions>

        <DialogContent>
          <div className={classes.inputSection}>
            <Grid container alignItems="center" style={{ textAlign: "center" }}>
              <Grid item xs={4}>
                <Typography
                  variant="caption"
                  style={{
                    color: "#707070",
                    fontWeight: "bold",
                    letterSpacing: 1,
                  }}
                >
                  Title
                </Typography>
                <TextField
                  size="small"
                  color="primary"
                  variant="outlined"
                  type="text"
                  name="titleName"
                  placeholder="Mr. & Mrs"
                  onChange={onChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={8}>
                <div style={{ padding: 5 }}>
                  <Typography
                    variant="caption"
                    style={{
                      color: "#707070",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      letterSpacing: 1,
                    }}
                  >
                    Full Name
                  </Typography>
                  <TextField
                    size="small"
                    color="primary"
                    variant="outlined"
                    type="text"
                    name="firstName"
                    onChange={onChange}
                    fullWidth
                    required
                  />
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={classes.inputSection}>
            <Typography
              variant="caption"
              style={{
                color: "#707070",
                textTransform: "uppercase",
                fontWeight: "bold",
                letterSpacing: 1,
              }}
            >
              Invite Type
            </Typography>
            <Select
              variant="outlined"
              size="small"
              name="type"
              onChange={onChange}
              required
              fullWidth
            >
              <MenuItem value="Admit one">Admit one</MenuItem>
              <MenuItem value="Admit two">Admit two</MenuItem>
            </Select>
          </div>

          <div className={classes.inputSection}>
            <Typography
              variant="caption"
              style={{
                color: "#707070",
                textTransform: "uppercase",
                fontWeight: "bold",
                letterSpacing: 1,
              }}
            >
              Phone Number (Optional)
            </Typography>
            <TextField
              size="small"
              color="primary"
              variant="outlined"
              type="text"
              name="phone"
              onChange={onChange}
              fullWidth
            />
          </div>

          <div className={classes.inputSection}>
            <Typography
              variant="caption"
              style={{
                color: "#707070",
                textTransform: "uppercase",
                fontWeight: "bold",
                letterSpacing: 1,
              }}
            >
              Email (Optional)
            </Typography>
            <TextField
              size="small"
              color="primary"
              variant="outlined"
              type="text"
              name="email"
              onChange={onChange}
              fullWidth
            />
          </div>

          <div className={classes.inputSection} style={{ textAlign: "right" }}>
            <Button
              size="large"
              color="primary"
              variant="contained"
              style={{ fontSize: 13, marginBottom: 10 }}
              disabled={loading}
              onClick={addGuest}
            >
              Save
              {loading && (
                <CircularProgress
                  color="primary"
                  size={30}
                  className={classes.progress}
                />
              )}
            </Button>

            {error === null ? (
              <Typography
                variant="body2"
                color="primary"
                gutterBottom
                component="div"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                {message}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                color="red"
                gutterBottom
                component="div"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                {error}
              </Typography>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={openDelete} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this guest?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={deleteGuest} color="error">
            Yes
          </Button>
          <Button onClick={handleDeleteClose}>NO</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editOpen} onClose={handleClose}>
        <DialogActions>
          <div style={{ borderBottom: "1px solid #e1e1e1", width: "100%" }}>
            <Grid container>
              <Grid item xs={10} style={{ textAlign: "left" }}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Edit Guest Info
                  {edit ? (
                    <MyButton onClick={editClick} tip="Edit Info">
                      <EditIcon color="primary" fontSize="small" />
                    </MyButton>
                  ) : (
                    <MyButton onClick={stopEdit} tip="Stop Edit">
                      <CancelIcon color="error" fontSize="small" />
                    </MyButton>
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                style={{ textAlign: "right", marginTop: "-10px" }}
              >
                <MyButton onClick={handleClose} tip="Close">
                  <CloseIcon fontSize="large" />
                </MyButton>
              </Grid>
            </Grid>
          </div>
        </DialogActions>
        <DialogContent>
          <Fragment>
            <div className={classes.inputSection}>
              <Grid container alignItems="center">
                <Grid item xs={4}>
                  <Typography
                    variant="caption"
                    style={{
                      color: "#707070",
                      fontWeight: "bold",
                      letterSpacing: 1,
                    }}
                  >
                    TITLE
                  </Typography>
                  <TextField
                    size="small"
                    color="primary"
                    variant="outlined"
                    type="text"
                    name="titleName"
                    disabled={edit}
                    defaultValue={guestTitle}
                    onChange={onChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={8}>
                  <div style={{ padding: 5 }}>
                    <Typography
                      variant="caption"
                      style={{
                        color: "#707070",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      Full Name
                    </Typography>
                    <TextField
                      size="small"
                      color="primary"
                      variant="outlined"
                      type="text"
                      name="firstName"
                      disabled={edit}
                      defaultValue={guestName}
                      onChange={onChange}
                      fullWidth
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={classes.inputSection}>
              <Typography
                variant="caption"
                style={{
                  color: "#707070",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  letterSpacing: 1,
                }}
              >
                Status
              </Typography>
              <Select
                variant="outlined"
                size="small"
                name="status"
                disabled={edit}
                onChange={onChange}
                defaultValue={guestStatus}
                required
                fullWidth
              >
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Attending">Attending</MenuItem>
                <MenuItem value="Not Sure">Not Sure</MenuItem>
                <MenuItem value="Absent">Absent</MenuItem>
              </Select>
            </div>

            <div className={classes.inputSection}>
              <Typography
                variant="caption"
                style={{
                  color: "#707070",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  letterSpacing: 1,
                }}
              >
                Invite Type
              </Typography>
              <Select
                variant="outlined"
                size="small"
                name="type"
                disabled
                onChange={onChange}
                defaultValue={guestType}
                required
                fullWidth
              >
                <MenuItem value="Admit one">Admit one</MenuItem>
                <MenuItem value="Admit two">Admit two</MenuItem>
              </Select>
            </div>

            <div className={classes.inputSection}>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <div style={{ padding: 5 }}>
                    <Typography
                      variant="caption"
                      style={{
                        color: "#707070",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      Email Address
                    </Typography>
                    <TextField
                      size="small"
                      color="primary"
                      variant="outlined"
                      type="email"
                      disabled={edit}
                      defaultValue={guestEmail}
                      name="email"
                      onChange={onChange}
                      fullWidth
                      required
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ padding: 5 }}>
                    <Typography
                      variant="caption"
                      style={{
                        color: "#707070",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      Phone Number
                    </Typography>
                    <TextField
                      size="small"
                      color="primary"
                      variant="outlined"
                      type="text"
                      disabled={edit}
                      defaultValue={guestPhone}
                      name="phone"
                      onChange={onChange}
                      fullWidth
                      required
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            {edit ? (
              <Fragment>
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <div className={classes.inputSection}>
                      <div style={{ padding: 5 }}>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#707070",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            letterSpacing: 1,
                          }}
                        >
                          Meal Preference
                        </Typography>
                        <TextField
                          size="small"
                          color="primary"
                          variant="outlined"
                          type="text"
                          disabled
                          defaultValue={guestMeal}
                          fullWidth
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.inputSection}>
                      <div style={{ padding: 5 }}>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#707070",
                            textTransform: "uppercase",
                            fontWeight: "bold",
                            letterSpacing: 1,
                          }}
                        >
                          Table Number
                        </Typography>
                        <TextField
                          size="small"
                          color="primary"
                          variant="outlined"
                          type="text"
                          disabled
                          defaultValue={guestTable}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <span style={{ letterSpacing: 2 }}>Table</span>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Fragment>
            ) : null}

            {edit ? (
              <div style={{ textAlign: "center", marginTop: "-20px" }}>
                <hr />
                <MyButton
                  onClick={() => handleOpenDelete(guestId)}
                  tip="Delete Guest"
                >
                  <DeleteIcon color="error" fontSize="medium" />
                </MyButton>
              </div>
            ) : null}

            {edit ? null : (
              <div
                className={classes.inputSection}
                style={{ textAlign: "right" }}
              >
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  style={{ fontSize: 13, marginBottom: 10 }}
                  disabled={loading}
                  onClick={updateGuest}
                >
                  Save
                  {loading && (
                    <CircularProgress
                      color="primary"
                      size={30}
                      className={classes.progress}
                    />
                  )}
                </Button>

                {error === null ? (
                  <Typography
                    variant="body2"
                    color="primary"
                    gutterBottom
                    component="div"
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    {message}
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    color="red"
                    gutterBottom
                    component="div"
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    {error}
                  </Typography>
                )}
              </div>
            )}
          </Fragment>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

Guestlists.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Guestlists);

/*
function onChangeSearch(e) {
    const conde = e.target.value;
    const newSearch = allVisitors.filter(
      (news) =>
        news.firstName
          .toLocaleLowerCase()
          .includes(conde.toLocaleLowerCase()) ||
        news.email.toLocaleLowerCase().includes(conde.toLocaleLowerCase()) ||
        news.phone.toLocaleLowerCase().includes(conde.toLocaleLowerCase()) ||
        news.title.toLocaleLowerCase().includes(conde.toLocaleLowerCase())
    );
    setEventGuest(newSearch);
  }
*/
