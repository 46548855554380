import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
//MUI
import withStyles from "@mui/styles/withStyles";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import EmailIcon from "@mui/icons-material/Email";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import InfoIcon from "@mui/icons-material/Info";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import SellIcon from "@mui/icons-material/Sell";
//Imports
import logowhite from "../images/logowhite.png";

const styles = (theme) => ({
  ...theme.spreadThis,
  logo: { height: 60 },
  contentContainer: { marginTop: 50 },
  inputSection: { textAlign: "left", marginBottom: 30 },
  labelTxt: { paddingBottom: 10 },
});

const EventMenu = (props) => {
  const { eventId } = useParams();

  return (
    <Fragment>
      <div
        style={{
          textAlign: "center",
          padding: 20,
          marginTop: 30,
        }}
      >
        <img src={logowhite} style={{ width: "75%" }} alt="logo" />
      </div>
      <div>
        <List>
          <ListItem
            style={{ color: "#fff", marginBottom: 20 }}
            button
            component={Link}
            to={`/eventinfo/${eventId}`}
          >
            <ListItemIcon>
              <InfoIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Event Info" />
          </ListItem>

          <ListItem
            style={{ color: "#fff", marginBottom: 20 }}
            button
            component={Link}
            to={`/guestList/${eventId}`}
          >
            <ListItemIcon>
              <GroupAddIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Guestlist" />
          </ListItem>

          <ListItem
            style={{ color: "#fff", marginBottom: 20 }}
            button
            component={Link}
            to={`/mealpreference/${eventId}`}
          >
            <ListItemIcon>
              <RestaurantIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Meal Preference" />
          </ListItem>

          <ListItem
            style={{ color: "#fff", marginBottom: 20 }}
            button
            component={Link}
            to={`/seatchart/${eventId}`}
          >
            <ListItemIcon>
              <EventSeatIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Seat Charting" />
          </ListItem>

          <ListItem
            style={{ color: "#fff", marginBottom: 20 }}
            button
            component={Link}
            to={`/checklist/${eventId}`}
          >
            <ListItemIcon>
              <FactCheckIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Checklist" />
          </ListItem>

          <ListItem
            style={{ color: "#fff", marginBottom: 20 }}
            button
            disabled
            component={Link}
            to={`/einvite/${eventId}`}
          >
            <ListItemIcon>
              <EmailIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="E-Invite" />
          </ListItem>

          <ListItem
            style={{ color: "#fff", marginBottom: 20 }}
            button
            disabled
            component={Link}
            to={`/sales/${eventId}`}
          >
            <ListItemIcon>
              <SellIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Sales" />
          </ListItem>

          <ListItem
            button
            component={Link}
            style={{ color: "#F1F97A", marginBottom: 20 }}
            to="/pricing"
          >
            <ListItemIcon>
              <ArrowCircleUpOutlinedIcon style={{ color: "#F1F97A" }} />
            </ListItemIcon>
            <ListItemText primary="Upgrade" />
          </ListItem>

          <ListItem
            style={{ color: "#fff" }}
            button
            component={Link}
            to="/dashboard"
          >
            <ListItemIcon>
              <ExitToAppIcon style={{ color: "#fff" }} />
            </ListItemIcon>
            <ListItemText primary="Exit Event" />
          </ListItem>
        </List>
      </div>
    </Fragment>
  );
};

EventMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventMenu);
