// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Menu, SeatChart, GuestList, Event, User } = initSchema(schema);

export {
  Menu,
  SeatChart,
  GuestList,
  Event,
  User
};