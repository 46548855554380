import React from "react";
import "./App.css";
//old file
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import createTheme from "@mui/material/styles/createTheme";
import themeFile from "./utility/theme";
//Pages
import Home from "./components/Home";
import Signup from "./components/Signup";
import Signin from "./components/Signin";
import Features from "./components/Features";
import Pricing from "./components/Pricing";
import ForgotPassword from "./components/ForgotPassword";
import Dashboard from "./components/Dashboard";
import Settings from "./components/Settings";
import CreateEvent from "./components/CreateEvent";
import EventInfo from "./components/EventInfo";
import Guestlists from "./components/Guestlists";
import MealPreference from "./components/MealPreference";
import SeatCharting from "./components/SeatCharting";
import Checklist from "./components/Checklist";
import Rsvp from "./components/Rsvp";

//Components
const theme = createTheme(themeFile);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="container">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/signin" element={<Signin />} />
            <Route exact path="/features" element={<Features />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/forgotpassword" element={<ForgotPassword />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/settings" element={<Settings />} />
            <Route exact path="/eventcreation" element={<CreateEvent />} />
            <Route exact path="/eventinfo/:eventId" element={<EventInfo />} />
            <Route exact path="/guestlist/:eventId" element={<Guestlists />} />
            <Route
              exact
              path="/mealpreference/:eventId"
              element={<MealPreference />}
            />
            <Route
              exact
              path="/seatchart/:eventId"
              element={<SeatCharting />}
            />
            <Route exact path="/checklist/:eventId" element={<Checklist />} />
            <Route exact path="/rsvp/:eventUrl" element={<Rsvp />} />
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
