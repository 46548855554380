import React, { Fragment, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
//MUI
import withStyles from "@mui/styles/withStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import SellIcon from "@mui/icons-material/Sell";
import EmailIcon from "@mui/icons-material/Email";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import InfoIcon from "@mui/icons-material/Info";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

//utility
import MyButton from "../utility/MyButton";
//Images
import logowhite from "../images/logowhite.png";
//AWS
import { Auth } from "aws-amplify";

const drawerWidth = 300;

const styles = (theme) => ({
  ...theme.spreadThis,

  //New
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    //marginRight: drawerWidth,
  },
  logo: { height: 40 },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
});

const EventMenulg = (props) => {
  const { eventId } = useParams();
  const { classes } = props;
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  function drawOpen() {
    setOpen(true);
  }

  function drawClose() {
    setOpen(false);
  }

  return (
    <Fragment>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <Link to="/dashboard">
                <img
                  src={logowhite}
                  className={classes.logo}
                  style={{ flexGrow: 1 }}
                  alt="loading"
                />
              </Link>
            </Grid>

            <Grid item xs={10}>
              <Grid container justifyContent={"end"}>
                <MyButton onClick={drawOpen} tip="Drawer">
                  <MenuIcon
                    aria-label="open drawer"
                    edge="end"
                    className={clsx(open && classes.hide)}
                  />
                </MyButton>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        color="primary"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <MyButton onClick={drawClose} tip="Close Menu">
            <ChevronLeftIcon />
          </MyButton>
          <Typography onClick={drawClose} style={{ cursor: "pointer" }}>
            Menu
          </Typography>
        </div>
        <Divider />
        <List>
          <ListItem
            style={{ marginBottom: 20 }}
            button
            component={Link}
            to={`/eventinfo/${eventId}`}
          >
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="Event Info" />
          </ListItem>

          <ListItem
            style={{ marginBottom: 20 }}
            button
            component={Link}
            to={`/guestList/${eventId}`}
          >
            <ListItemIcon>
              <GroupAddIcon />
            </ListItemIcon>
            <ListItemText primary="Guestlist" />
          </ListItem>

          <ListItem
            style={{ marginBottom: 20 }}
            button
            component={Link}
            to={`/mealpreference/${eventId}`}
          >
            <ListItemIcon>
              <RestaurantIcon />
            </ListItemIcon>
            <ListItemText primary="Meal Preference" />
          </ListItem>

          <ListItem
            style={{ marginBottom: 20 }}
            button
            component={Link}
            to={`/seatchart/${eventId}`}
          >
            <ListItemIcon>
              <EventSeatIcon />
            </ListItemIcon>
            <ListItemText primary="Seat Charting" />
          </ListItem>

          <ListItem
            style={{ marginBottom: 20 }}
            button
            component={Link}
            to={`/checklist/${eventId}`}
          >
            <ListItemIcon>
              <FactCheckIcon />
            </ListItemIcon>
            <ListItemText primary="Check List" />
          </ListItem>

          <ListItem
            style={{ marginBottom: 20 }}
            button
            disabled
            component={Link}
            to={`/einvite/${eventId}`}
          >
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="E-Invite" />
          </ListItem>

          <ListItem
            style={{ marginBottom: 20 }}
            button
            disabled
            component={Link}
            to={`/sales/${eventId}`}
          >
            <ListItemIcon>
              <SellIcon />
            </ListItemIcon>
            <ListItemText primary="Sales" />
          </ListItem>

          <ListItem
            button
            component={Link}
            style={{ marginBottom: 20 }}
            to="/pricing"
          >
            <ListItemIcon>
              <ArrowCircleUpOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Upgrade" />
          </ListItem>

          <ListItem button component={Link} to="/dashboard">
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Exit Event" />
          </ListItem>
        </List>
      </Drawer>
    </Fragment>
  );
};

EventMenulg.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventMenulg);
