import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
//MUI
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Typography from "@mui/material/Typography";
import LiquorIcon from "@mui/icons-material/Liquor";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
//Imports
import NavInner from "./NavInner";
import logo from "../images/logo.png";
import noevent from "../images/noevent.png";
//AWS
import { Auth } from "aws-amplify";
import { DataStore } from "aws-amplify";
import { Event } from "../models";
import { User } from "../models";
import { GuestList } from "../models";

const styles = (theme) => ({
  ...theme.spreadThis,
  logo: { height: 60 },
  contentContainer: { marginTop: 50 },
  inputSection: { textAlign: "left", marginBottom: 30 },
  labelTxt: { paddingBottom: 10 },
});

const Dashboard = (props) => {
  const { classes } = props;
  const [getData, setgetDate] = useState(true);
  const [error, setError] = useState(null);
  const [myEvent, setMyEvent] = useState([]);
  const [account, setAccount] = useState(null);
  const [numEvent, setNumevents] = useState(null);
  const [guestList, setGuestList] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const allUsers = await DataStore.query(User);
      const allEvent = await DataStore.query(Event);
      const allGuests = await DataStore.query(GuestList);
      const user = await Auth.currentAuthenticatedUser();
      if (user == null) {
        setgetDate(false);
        navigate("/");
      } else {
        await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
          .then((user) => {
            localStorage.setItem("userId", user.attributes.sub);
            const filterEvents = allEvent.filter(
              (evn) => evn.userId === user.attributes.sub
            );
            setMyEvent(filterEvents);

            const filteredAccount = allUsers
              .filter((use) => use.email === user.attributes.email)
              .map((amm) => amm.account);
            setAccount(String(filteredAccount));

            const filterEventsNum = allEvent.filter(
              (evn) =>
                evn.userId === user.attributes.sub && evn.creator === true
            );
            setNumevents(filterEventsNum.length);

            const filterGuests = allGuests.filter(
              (evn) => evn.userId === user.attributes.sub
            );
            setGuestList(filterGuests);
          })
          .catch((err) => setError(err));
        setgetDate(false);
      }
    } catch (error) {
      console.log("error confirming:", error);
    }
  }

  let eventMockup =
    myEvent.length === 0 ? (
      <div style={{ marginTop: 10, textAlign: "center" }}>
        <img src={noevent} style={{ width: "50%" }} alt="loading" />
        <Typography variant="body1">You have no event created</Typography>
      </div>
    ) : (
      myEvent.map((evnt, idx) => {
        const id = evnt.id;
        const eventName = evnt.eventName;
        const eventType = evnt.eventType;
        const startDate = evnt.startDate;
        const endDate = evnt.endDate;
        const guestNumber = evnt.guestNumber;
        const publicState = evnt.publicState;
        const active = evnt.active;
        const newStartDate = moment(startDate).format("MMM Do YYYY");
        const newEndDate = moment(endDate).format("MMM Do YYYY");

        return active ? (
          <Fragment>
            <Card
              variant="elevation"
              component={Link}
              to={`/guestlist/${id}`}
              style={{ width: 490, margin: 10, maxWidth: "100%" }}
              key={idx}
            >
              <CardContent>
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography
                      variant="h6"
                      style={{ fontWeight: "bold" }}
                      component="div"
                    >
                      {eventName}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="caption"
                      color="primary"
                      component="div"
                    >
                      {eventType}
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="caption"
                      color="gray"
                      component="div"
                    >
                      {publicState ? "Public" : "Private"}
                    </Typography>
                  </Grid>

                  <Grid item xs={8}>
                    <Grid container style={{ textAlign: "left" }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        style={{ margin: "10px 0px" }}
                      >
                        <Typography variant="caption" component="div">
                          Start Date
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold" }}
                          component="div"
                        >
                          {newStartDate.toString()}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        style={{ margin: "10px 0px" }}
                      >
                        <Typography variant="caption" component="div">
                          End Date
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold" }}
                          component="div"
                        >
                          {newEndDate.toString()}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        style={{ margin: "10px 0px" }}
                      >
                        <Typography variant="caption" component="div">
                          No Guests
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold" }}
                          component="div"
                        >
                          {guestNumber} Guests
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fragment>
        ) : null;
      })
    );

  return getData ? (
    <Fragment>
      <Grid container>
        <Grid item md={4} align="center"></Grid>
        <Grid item md={4} align="center" style={{ padding: "7%" }}>
          <img
            src={logo}
            className={classes.icon}
            style={{ width: "100%" }}
            alt="loading"
          />
          <LinearProgress
            color="primary"
            style={{ width: "100%", marginTop: 10 }}
          />
        </Grid>
        <Grid item md={4} align="center"></Grid>
      </Grid>
    </Fragment>
  ) : (
    <Fragment>
      <NavInner />
      <Container maxWidth="lg">
        <Grid
          container
          alignItems="center"
          className={classes.contentContainer}
          style={{ marginTop: 100 }}
        >
          <Grid item sm={12} xs={12}>
            <Paper elevation={2} style={{ minHeight: 50, padding: 50 }}>
              <div style={{ marginBottom: "10px", textAlign: "left" }}>
                <Typography variant="h5" style={{ fontWeight: "bolder" }}>
                  <InfoOutlinedIcon /> Dashboard
                </Typography>
              </div>
              <hr />

              <Grid container alignItems="center">
                <Grid item sm={4} xs={12} style={{ padding: 10 }}>
                  <div style={{ marginBottom: "10px", textAlign: "left" }}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold" }}
                    >
                      Current Plan
                    </Typography>
                    <Typography
                      variant="h4"
                      style={{ fontWeight: "bolder" }}
                      color="primary"
                    >
                      {account}
                    </Typography>
                    {account === "Free" ? (
                      <Fragment>
                        <Typography variant="caption" color="GrayText">
                          You have no active paid subscription.
                        </Typography>
                        <div style={{ marginTop: 10 }}>
                          <Button
                            size="small"
                            variant="contained"
                            component={Link}
                            to="/pricing"
                            color="primary"
                            style={{ fontSize: 13, marginBottom: 20 }}
                          >
                            VIEW & SWITCH PLANS
                          </Button>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Typography variant="caption" color="GrayText">
                          You have an active paid subscription.
                        </Typography>
                        <div style={{ marginTop: 10 }}>
                          <Button
                            size="small"
                            variant="contained"
                            component={Link}
                            to="/pricing"
                            color="primary"
                            style={{ fontSize: 13, marginBottom: 20 }}
                          >
                            VIEW & SWITCH PLANS
                          </Button>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </Grid>

                <Grid
                  item
                  sm={8}
                  xs={12}
                  style={{ padding: 10, borderLeft: "1px solid #E1E1E1" }}
                >
                  <div style={{ textAlign: "left" }}>
                    <Typography
                      variant="h6"
                      style={{
                        color: "#333",
                        fontSize: 13,
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      Active Events
                    </Typography>

                    {account === "Free" ? (
                      <Fragment>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#707070",
                            float: "right",
                            fontSize: 13,
                            marginTop: "-25px",
                            letterSpacing: 1,
                          }}
                        >
                          {numEvent} of 1 used
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={numEvent === 1 ? "100" : "0"}
                          color="primary"
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#707070",
                            float: "right",
                            fontSize: 13,
                            marginTop: "-25px",
                            letterSpacing: 1,
                          }}
                        >
                          {numEvent} of Unlimited used
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={numEvent}
                          color="primary"
                        />
                      </Fragment>
                    )}
                  </div>

                  <div
                    style={{
                      textAlign: "left",
                      marginTop: 30,
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        color: "#333",
                        fontSize: 13,
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      RSVPs
                    </Typography>

                    {account === "Free" ? (
                      <Fragment>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#707070",
                            float: "right",
                            fontSize: 13,
                            marginTop: "-25px",
                            letterSpacing: 1,
                          }}
                        >
                          {guestList.length} of 50 used
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={guestList.length * 2}
                          color="primary"
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#707070",
                            float: "right",
                            fontSize: 13,
                            marginTop: "-25px",
                            letterSpacing: 1,
                          }}
                        >
                          {guestList.length} of Unlimited
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={guestList.length / 100}
                          color="primary"
                        />
                      </Fragment>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          className={classes.contentContainer}
          style={{ marginBottom: 50 }}
        >
          <Grid item lg={12} xs={12}>
            <Paper elevation={2} style={{ minHeight: "50vh", padding: 50 }}>
              <div
                style={{
                  textAlign: "right",
                  borderBottom: "1px solid #e1e1e1",
                }}
              >
                <Typography
                  variant="h5"
                  color="primary"
                  style={{
                    fontWeight: "bolder",
                    float: "left",
                    marginTop: "20px",
                  }}
                >
                  <LiquorIcon /> My Events
                </Typography>
                <Button
                  size="large"
                  variant="contained"
                  component={Link}
                  to="/eventcreation"
                  color="primary"
                  style={{
                    fontSize: 13,
                    marginBottom: 20,
                    fontWeight: "bold",
                    letterSpacing: 1,
                  }}
                >
                  <AddCircleOutlineIcon style={{ marginRight: 5 }} /> Create
                  Event
                </Button>
              </div>

              <div>
                <Grid container>
                  <Grid
                    container
                    item
                    style={{ justifyContent: "center" }}
                    xs={12}
                  >
                    {eventMockup}
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
