import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

//MUI
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
//Imports
import MyButton from "../utility/MyButton";
import logo from "../images/logo.png";
//AWS
import { Auth } from "aws-amplify";

const styles = (theme) => ({
  ...theme.spreadThis,
  logo: { height: 60 },
  contentContainer: { marginTop: 50 },
  inputSection: { textAlign: "left", marginBottom: 30 },
  labelTxt: { paddingBottom: 10 },
});

const initialFormState = {
  email: "",
  password: "",
  show: false,
};

const Signin = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [getData, setgetDate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formState, updateFormState] = useState(initialFormState);

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user != null) {
        setgetDate(false);
        navigate("/dashboard");
      } else {
        setgetDate(false);
      }
    } catch (error) {
      setgetDate(false);
      console.log("error confirming:", error);
    }
  }

  function onChange(e) {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  }

  function showMe() {
    setShow(!show);
  }

  async function signIn(e) {
    try {
      e.preventDefault();
      setLoading(true);
      setError(null);
      const { email, password } = formState;
      await Auth.signIn(email, password);
      navigate("/dashboard");
    } catch (error) {
      setLoading(false);
      setError(" Wrong username or password");
      console.log("Sign Error", error);
    }
  }

  return getData ? (
    <Fragment>
      <Grid container>
        <Grid item md={4} align="center"></Grid>
        <Grid item md={4} align="center" style={{ padding: "7%" }}>
          <img
            src={logo}
            className={classes.icon}
            style={{ width: "100%" }}
            alt="loading"
          />
          <LinearProgress
            color="primary"
            style={{ width: "100%", marginTop: 10 }}
          />
        </Grid>
        <Grid item md={4} align="center"></Grid>
      </Grid>
    </Fragment>
  ) : (
    <Fragment>
      <Container maxWidth="lg">
        <Grid
          container
          alignItems="center"
          className={classes.contentContainer}
        >
          <Grid item sm={3} xs={3}></Grid>
          <Grid item sm={6} xs={12}>
            <div style={{ textAlign: "center" }}>
              <Link to="/">
                <img src={logo} className={classes.logo} alt="loading" />
              </Link>
            </div>

            <div style={{ marginTop: "10px", textAlign: "center" }}>
              <Typography variant="h5" style={{ fontWeight: "bolder" }}>
                Sign in to your account
              </Typography>
            </div>

            <div style={{ marginTop: "5px", textAlign: "center" }}>
              <Typography variant="body1">
                Don't have an account?{" "}
                <Link
                  to="/signup"
                  style={{ color: "#6665A6", fontWeight: "bold" }}
                >
                  Sign up free
                </Link>
              </Typography>
            </div>

            <div style={{ marginTop: "30px" }}>
              <Paper elevation={2} style={{ minHeight: 50, padding: 50 }}>
                <form onSubmit={signIn}>
                  <div className={classes.inputSection}>
                    <Typography
                      variant="caption"
                      style={{
                        color: "#707070",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      Email Address
                    </Typography>
                    <TextField
                      size="small"
                      color="primary"
                      type="email"
                      name="email"
                      onChange={onChange}
                      variant="outlined"
                      autoFocus
                      fullWidth
                      required
                    />
                  </div>

                  <div className={classes.inputSection}>
                    <Typography
                      variant="caption"
                      style={{
                        color: "#707070",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        letterSpacing: 1,
                      }}
                    >
                      Password
                    </Typography>
                    <TextField
                      size="small"
                      name="password"
                      color="primary"
                      variant="outlined"
                      type={show ? "text" : "password"}
                      onChange={onChange}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {show ? (
                              <MyButton onClick={showMe} tip="View Password">
                                <VisibilityOffIcon color="disabled" />
                              </MyButton>
                            ) : (
                              <MyButton onClick={showMe} tip="View Password">
                                <VisibilityIcon color="disabled" />
                              </MyButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      required
                    />
                  </div>

                  {error === null ? null : (
                    <div
                      style={{
                        marginBottom: 20,
                        marginTop: "-10px",
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="caption" color="error">
                        {error}
                      </Typography>
                    </div>
                  )}

                  <div className={classes.inputSection}>
                    <Button
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ fontSize: 13, marginBottom: 20 }}
                      disabled={loading}
                      fullWidth
                    >
                      Login
                      {loading && (
                        <CircularProgress
                          color="primary"
                          size={30}
                          className={classes.progress}
                        />
                      )}
                    </Button>

                    <Button
                      size="large"
                      variant="contained"
                      disableElevation
                      color="tertiary"
                      style={{ fontSize: 13 }}
                      fullWidth
                      component={Link}
                      to="/forgotpassword"
                    >
                      Forgot Password
                    </Button>
                  </div>
                </form>
              </Paper>
            </div>
          </Grid>
          <Grid item sm={3} xs={3}></Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

Signin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Signin);
