import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//MUI
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
//Images
import logo from "../images/logo.png";
import MyButton from "../utility/MyButton";
//AWS
import { Auth } from "aws-amplify";
import { DataStore } from "aws-amplify";
import { User } from "../models";

const styles = (theme) => ({
  ...theme.spreadThis,
  logo: { height: 60 },
  contentContainer: { marginTop: 10, marginBottom: 10 },
  inputSection: { textAlign: "left", marginBottom: 30 },
  labelTxt: { paddingBottom: 10 },
});

const initialFormState = {
  email: "",
  fullname: "",
  password: "",
  confirmPassword: "",
  authCode: "",
  planner: "",
  show: false,
  formType: "signUp",
};

const Signup = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [getData, setgetDate] = useState(true);
  const [handleOpen, setHandleOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formState, updateFormState] = useState(initialFormState);

  useEffect(() => {
    checkUser();
  }, []);

  const { formType } = formState;

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user != null) {
        setgetDate(false);
        navigate("/dashboard");
      } else {
        setgetDate(false);
      }
    } catch (error) {
      setgetDate(false);
      console.log("error confirming:", error);
    }
  }

  function onChange(e) {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  }

  function showMe() {
    setShow(!show);
  }

  async function signUp() {
    try {
      setError(null);
      setLoading(true);
      const { email, fullname, password, confirmPassword, planner } = formState;

      const booPlanner = planner === "true" ? true : false;

      if (password != confirmPassword) {
        setLoading(false);
        setError("Password does not match");
      } else {
        await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email,
          },
        });

        const newUser = {
          fullname: fullname,
          email: email.toLocaleLowerCase(),
          planner: booPlanner,
          account: "Free",
          active: true,
          createdAt: new Date().toISOString(),
        };

        await DataStore.save(new User(newUser));
        setLoading(false);
        updateFormState(() => ({ ...formState, formType: "confirmation" }));
      }
    } catch (error) {
      setLoading(false);
      setError(
        " An error occuried, check that all fields are filled correctly"
      );
      console.log("error signing up:", error);
    }
  }

  async function confirm() {
    try {
      setError(null);
      setLoading(true);
      const { email, authCode } = formState;
      await Auth.confirmSignUp(email, authCode);
      setHandleOpen(true);
      // navigate("/signin");
    } catch (error) {
      setLoading(false);
      setError(" An error occuried, try again");
      console.log("error signing up:", error);
    }
  }

  return getData ? (
    <Fragment>
      <Grid container>
        <Grid item md={4} align="center"></Grid>
        <Grid item md={4} align="center" style={{ padding: "7%" }}>
          <img
            src={logo}
            className={classes.icon}
            style={{ width: "100%" }}
            alt="loading"
          />
          <LinearProgress
            color="primary"
            style={{ width: "100%", marginTop: 10 }}
          />
        </Grid>
        <Grid item md={4} align="center"></Grid>
      </Grid>
    </Fragment>
  ) : (
    <Fragment>
      <Dialog open={handleOpen} style={{ padding: 30 }}>
        <DialogTitle>Welcome to Guestpin</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have successfully signed up, click on the button below to login
            to your account
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            component={Link}
            to="/signin"
            color="primary"
            style={{ fontSize: 13, marginBottom: 20 }}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      <Container maxWidth="lg">
        <Grid
          container
          alignItems="center"
          className={classes.contentContainer}
        >
          <Grid item sm={3} xs={3}></Grid>
          <Grid item sm={6} xs={12}>
            <div style={{ textAlign: "center" }}>
              <Link to="/">
                <img src={logo} className={classes.logo} alt="loading" />
              </Link>
            </div>

            <div style={{ marginTop: "10px", textAlign: "center" }}>
              <Typography variant="h5" style={{ fontWeight: "bolder" }}>
                Register your account
              </Typography>
            </div>

            <div style={{ marginTop: "5px", textAlign: "center" }}>
              <Typography variant="body1">
                Already have an account?{" "}
                <Link
                  to="/signin"
                  style={{ color: "#6665A6", fontWeight: "bold" }}
                >
                  Sign in now
                </Link>
              </Typography>
            </div>

            {formType === "signUp" && (
              <Fragment>
                <div style={{ marginTop: "30px" }}>
                  <Paper elevation={2} style={{ minHeight: 50, padding: 50 }}>
                    <div className={classes.inputSection}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#707070",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        Name
                      </Typography>
                      <TextField
                        size="small"
                        color="primary"
                        variant="outlined"
                        type="text"
                        name="fullname"
                        onChange={onChange}
                        fullWidth
                        required
                        autoFocus
                      />
                    </div>

                    <div className={classes.inputSection}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#707070",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        Email Address
                      </Typography>
                      <TextField
                        size="small"
                        color="primary"
                        variant="outlined"
                        type="email"
                        name="email"
                        onChange={onChange}
                        fullWidth
                        required
                      />
                    </div>

                    <div className={classes.inputSection}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#707070",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        Password
                      </Typography>
                      <TextField
                        size="small"
                        name="password"
                        color="primary"
                        variant="outlined"
                        type={show ? "text" : "password"}
                        onChange={onChange}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {show ? (
                                <MyButton onClick={showMe} tip="View Password">
                                  <VisibilityOffIcon color="disabled" />
                                </MyButton>
                              ) : (
                                <MyButton onClick={showMe} tip="View Password">
                                  <VisibilityIcon color="disabled" />
                                </MyButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        required
                      />
                    </div>

                    <div className={classes.inputSection}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#707070",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        Confirm Password
                      </Typography>
                      <TextField
                        size="small"
                        color="primary"
                        variant="outlined"
                        name="confirmPassword"
                        type="password"
                        onChange={onChange}
                        fullWidth
                        required
                      />
                    </div>

                    <div className={classes.inputSection}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#707070",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        I'm an event planner
                      </Typography>

                      <Select
                        variant="outlined"
                        size="small"
                        name="planner"
                        onChange={onChange}
                        fullWidth
                      >
                        <MenuItem value="true">Yes, I'm</MenuItem>
                        <MenuItem value="false">No, I'm not</MenuItem>
                      </Select>
                    </div>

                    {error === null ? null : (
                      <div
                        style={{
                          marginBottom: 10,
                          marginTop: "-10px",
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="caption" color="error">
                          {error}
                        </Typography>
                      </div>
                    )}

                    <div className={classes.inputSection}>
                      <Typography variant="caption" color="gray">
                        By completing sign up, you agree to{" "}
                        <Link
                          to="/terms"
                          style={{ color: "#6665A6", fontWeight: "bold" }}
                        >
                          {" "}
                          Guestpin’s Terms of Service
                        </Link>
                        . You may receive emails on platform update or other
                        informations from time to time.
                      </Typography>
                    </div>

                    <div className={classes.inputSection}>
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        style={{ fontSize: 13 }}
                        disabled={loading}
                        onClick={signUp}
                        fullWidth
                      >
                        Register
                        {loading && (
                          <CircularProgress
                            color="primary"
                            size={30}
                            className={classes.progress}
                          />
                        )}
                      </Button>
                    </div>
                  </Paper>
                </div>
              </Fragment>
            )}

            {formType === "confirmation" && (
              <Fragment>
                <div style={{ marginTop: "30px" }}>
                  <Paper elevation={2} style={{ minHeight: 50, padding: 50 }}>
                    <div className={classes.inputSection}>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#707070",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          letterSpacing: 1,
                        }}
                      >
                        Confirmation Code
                      </Typography>
                      <TextField
                        size="small"
                        color="primary"
                        variant="outlined"
                        type="text"
                        name="authCode"
                        onChange={onChange}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 6);
                        }}
                        fullWidth
                        required
                        autoFocus
                      />
                    </div>

                    {error === null ? null : (
                      <div
                        style={{
                          marginBottom: 30,
                          marginTop: "-30px",
                          textAlign: "right",
                        }}
                      >
                        <Typography
                          variant="caption"
                          style={{ textAlign: "center" }}
                          color="error"
                        >
                          {error}
                        </Typography>
                      </div>
                    )}

                    <div style={{ marginBottom: 20, marginTop: "-30px" }}>
                      <Typography variant="caption" color="gray">
                        Enter verification code sent to your mail. i.e Check
                        your spam box if code is not found in inbox
                      </Typography>
                    </div>

                    <div className={classes.inputSection}>
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        style={{ fontSize: 13 }}
                        disabled={loading}
                        onClick={confirm}
                        fullWidth
                      >
                        Confirm
                        {loading && (
                          <CircularProgress
                            color="primary"
                            size={30}
                            className={classes.progress}
                          />
                        )}
                      </Button>
                    </div>
                  </Paper>
                </div>
              </Fragment>
            )}
          </Grid>
          <Grid item sm={3} xs={3}></Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

Signup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Signup);
