import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

//MUI
import Hidden from "@mui/material/Hidden";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import DialogActions from "@mui/material/DialogActions";

//Imports
import logo from "../images/logo.png";
import checklist from "../images/checklist.png";
import EventMenu from "./EventMenu";
import EventMenulg from "./EventMenulg";

//AWS
import { Auth } from "aws-amplify";
import { DataStore } from "aws-amplify";
import { Event, GuestList, User } from "../models";

const styles = (theme) => ({
  ...theme.spreadThis,
  logo: { height: 60 },
  contentContainer: { marginTop: 20 },
  inputSection: { textAlign: "left", marginBottom: 30 },
  labelTxt: { paddingBottom: 10 },
});

const Checklist = (props) => {
  const { classes } = props;
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [getData, setgetDate] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [tableDetails, setTableDetails] = useState(false);
  const [guestTable, setGuestTable] = useState(null);
  const [guestName, setGuestName] = useState(null);
  const [guestTitle, setGuestTitle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [eventName, setEventName] = useState(null);
  const [checkActive, setCheckActive] = useState(null);
  const [guestId, setGuestId] = useState(null);
  const [eventGuests, setEventGuests] = useState([]);
  const [attendingGuests, setAttendingGuests] = useState([]);
  const [allVisitors, setAllVisitors] = useState([]);
  const [account, setAccount] = useState(null);
  const [error, setError] = useState(null);
  const [sorted, setSort] = useState("des");

  const [checkMeal, setCheckMeal] = useState(null);
  const [checkSeat, setCheckSeat] = useState(null);
  const [checkList, setCheckList] = useState(null);

  useEffect(() => {
    checkUser();
    obersation();
  }, []);

  function obersation() {
    DataStore.observe(GuestList).subscribe((msg) => {
      checkUser();
    });

    DataStore.observe(Event).subscribe((msg) => {
      checkUser();
    });
  }

  async function checkUser() {
    try {
      const allUsers = await DataStore.query(User);
      const allEvent = await DataStore.query(Event);
      const allGuests = await DataStore.query(GuestList);
      const user = await Auth.currentAuthenticatedUser();

      if (user == null) {
        setgetDate(false);
        navigate("/");
      } else {
        await Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
          .then((user) => {
            const filteredAccount = allUsers
              .filter((use) => use.email === user.attributes.email)
              .map((amm) => amm.account);
            setAccount(String(filteredAccount));

            const filterEventGuest = allGuests.filter(
              (evn) => evn.eventId === eventId && evn.status === "Attending"
            );
            setEventGuests(filterEventGuest);

            const filterAssignGuest = allGuests.filter(
              (evn) =>
                evn.eventId === eventId &&
                evn.title !== "+1" &&
                evn.status === "Attending"
            );

            const sortedGuest = filterAssignGuest.sort((a, b) => {
              const mySort = sorted === "des" ? 1 : -1;
              return mySort * a.firstName.localeCompare(b.firstName);
            });
            setAttendingGuests(sortedGuest);
            setAllVisitors(sortedGuest);

            const filteredEventName = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.eventName);
            setEventName(String(filteredEventName));

            const filteredCheckActive = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.checkList);
            setCheckActive(String(filteredCheckActive));

            const filteredMeal = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.mealRequired);
            setCheckMeal(String(filteredMeal));

            const filteredSeat = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.seatChart);
            setCheckSeat(String(filteredSeat));

            const filteredList = allEvent
              .filter((evn) => evn.id === eventId)
              .map((amm) => amm.checkList);
            setCheckList(String(filteredList));
          })
          .catch((err) => setError(err));
        setgetDate(false);
      }
    } catch (error) {
      console.log("error confirming:", error);
    }
  }

  async function updateGuest() {
    try {
      const myGuest = await DataStore.query(GuestList, guestId);
      await DataStore.save(
        Event.copyOf(myGuest, (myUpdate) => {
          myUpdate.checkIn = true;
        })
      );
      setConfirm(false);
      setTableDetails(true);
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  async function updateChecklist() {
    try {
      setLoading(true);
      setError(null);

      if (checkActive === "false") {
        const myEvt = await DataStore.query(Event, eventId);
        await DataStore.save(
          Event.copyOf(myEvt, (myUpdate) => {
            myUpdate.checkList = true;
          })
        );
      } else {
        const myEvt = await DataStore.query(Event, eventId);
        await DataStore.save(
          Event.copyOf(myEvt, (myUpdate) => {
            myUpdate.checkList = false;
          })
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Failed to update, Try again");
      console.log("Sign Error", error);
    }
  }

  //Working Search Code
  function onChangeSearch(e) {
    const conde = e.target.value;
    const newSearch = allVisitors.filter((news) =>
      news.firstName.toLocaleLowerCase().includes(conde.toLocaleLowerCase())
    );
    setAttendingGuests(newSearch);
  }

  function handelConfirm(id, name, title, tableNumber) {
    setConfirm(true);
    setGuestId(id);
    setGuestName(name);
    setGuestTitle(title);
    setGuestTable(tableNumber);
  }

  function handleClose() {
    setConfirm(false);
    setTableDetails(false);
  }

  return getData ? (
    <Fragment>
      <Grid container>
        <Grid item md={4} align="center"></Grid>
        <Grid item md={4} align="center" style={{ padding: "7%" }}>
          <img
            src={logo}
            className={classes.icon}
            style={{ width: "100%" }}
            alt="loading"
          />
          <LinearProgress
            color="primary"
            style={{ width: "100%", marginTop: 10 }}
          />
        </Grid>
        <Grid item md={4} align="center"></Grid>
      </Grid>
    </Fragment>
  ) : (
    <Grid container>
      {checkActive === "false" ? (
        <Fragment>
          <Hidden lgDown>
            <Grid
              item
              md={12}
              lg={2}
              style={{ height: "100vh", background: "#6665A6" }}
            >
              <EventMenu />
            </Grid>
          </Hidden>
          <Grid item xs={12} lg={10} style={{ padding: 30 }}>
            <Hidden lgUp>
              <EventMenulg />
              <br />
              <br />
            </Hidden>
            <Grid
              container
              alignItems="center"
              className={classes.contentContainer}
            >
              <Grid item xs={12}>
                <div style={{ textAlign: "right", marginBottom: 10 }}>
                  <Link to={"#"}>
                    <Typography
                      variant="caption"
                      style={{ letterSpacing: 1, fontWeight: "bold" }}
                    >
                      {checkSeat === "true" ||
                      checkMeal === "true" ||
                      checkList === "true"
                        ? 1 + 1 + 1 + 1
                        : checkMeal === "true" ||
                          checkSeat === "false" ||
                          checkList === "false"
                        ? 1 + 1 + 0 + 0
                        : checkList === "false" ||
                          checkSeat === "false" ||
                          checkMeal === "false"
                        ? 1 + 0 + 0 + 0
                        : 0}
                      OF 4 COMPLETE
                    </Typography>
                  </Link>
                </div>

                <Paper elevation={2} style={{ height: "80vh", padding: 30 }}>
                  <Grid
                    container
                    alignItems="center"
                    style={{ minHeight: "80vh", padding: 30 }}
                  >
                    <Grid item xs={0} md={3}></Grid>
                    <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                      <Typography variant="h5" style={{ fontWeight: "bold" }}>
                        {eventName} Checklist
                      </Typography>
                      <img
                        src={checklist}
                        style={{ width: "90%" }}
                        alt="loading"
                      />

                      <Typography variant="h1" style={{ fontWeight: "bold" }}>
                        {eventGuests.length}
                      </Typography>
                      <Typography
                        component="div"
                        variant="subtile1"
                        style={{ letterSpacing: 2 }}
                      >
                        ATTENDING GUESTS
                      </Typography>

                      {account === "Free" ? (
                        <div style={{ marginTop: 20 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={updateChecklist}
                            style={{ letterSpacing: 2 }}
                            disabled
                          >
                            Launch Checklist
                          </Button>
                          <Typography
                            style={{ marginTop: 10 }}
                            variant="body2"
                            color="error"
                          >
                            Oops!!! You have to upgrade your subscription plan
                            to use this feature
                          </Typography>
                        </div>
                      ) : (
                        <div style={{ marginTop: 20 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={updateChecklist}
                            disabled={loading}
                            style={{ letterSpacing: 2 }}
                          >
                            Launch Checklist
                            {loading && (
                              <CircularProgress
                                color="primary"
                                size={30}
                                className={classes.progress}
                              />
                            )}
                          </Button>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={0} md={3}></Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Fragment>
          <Grid container style={{ padding: 20 }}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <div>
                <img src={logo} style={{ height: 60 }} alt="loading" />
              </div>
            </Grid>
            <Grid item xs={0} md={1}></Grid>
            <Grid item xs={12} md={10} style={{ marginTop: 20 }}>
              <Paper
                elevation={2}
                style={{ height: "75vh", padding: 20, overflow: "none" }}
              >
                <Grid
                  container
                  style={{
                    marginBottom: 10,
                    borderBottom: "1px solid #e1e1e1",
                    height: "7vh",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <Grid item xs={10}>
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                      {eventName} Checklist
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ textAlign: "center" }}>
                      <Button
                        onClick={updateChecklist}
                        variant="contained"
                        color="error"
                        size="medium"
                        disabled={loading}
                        fullWidth
                      >
                        <ExitToAppRoundedIcon
                          fontSize="small"
                          style={{ marginRight: 10 }}
                        />
                        Exit
                        {loading && (
                          <CircularProgress
                            color="primary"
                            size={30}
                            className={classes.progress}
                          />
                        )}
                      </Button>
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  container
                  style={{
                    marginBottom: 10,
                    height: "7vh",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <Grid item xs={12}>
                    <TextField
                      id="search"
                      variant="filled"
                      size="small"
                      name="search"
                      type="text"
                      onChange={onChangeSearch}
                      color="primary"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Enter guest name"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  alignContent="center"
                  style={{
                    marginBottom: 20,
                    background: "#707070",
                    borderBottom: "3px solid #333",
                    padding: 5,
                    color: "#fff",
                    height: "5vh",
                    position: "sticky",
                    top: 10,
                  }}
                >
                  <Grid item xs={4} sm={6}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold" }}
                    >
                      Name
                    </Typography>
                  </Grid>

                  <Grid item xs={4} sm={3}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Access
                    </Typography>
                  </Grid>

                  <Grid item xs={4} sm={3}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      Check-In | Table No
                    </Typography>
                  </Grid>
                </Grid>

                <div
                  style={{
                    height: "53vh",
                    position: "sticky",
                    top: 150,
                    overflow: "auto",
                  }}
                >
                  {attendingGuests.map((det, idx) => {
                    const id = det.id;
                    const title = det.title;
                    const name = det.firstName;
                    const type = det.type;
                    const tableNumber = det.tableNumber;
                    const checkState = det.checkIn;

                    return (
                      <Grid
                        container
                        alignContent="center"
                        style={{
                          height: "10vh",
                          borderBottom: "1px solid #e1e1e1",
                        }}
                        key={idx}
                      >
                        <Grid item xs={4} sm={6}>
                          <Typography variant="subtitle1">
                            {title} {name}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          sm={3}
                          style={{ textAlign: "center" }}
                        >
                          <Button
                            variant="outlined"
                            size="small"
                            color={checkState ? "success" : "error"}
                            style={{ letterSpacing: 1, fontWeight: "bold" }}
                          >
                            {type}
                          </Button>
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          sm={3}
                          style={{ textAlign: "center", padding: "0px 20px" }}
                        >
                          {checkState ? (
                            <Button
                              variant="outlined"
                              size="small"
                              color="success"
                              style={{ letterSpacing: 1, fontWeight: "bold" }}
                              fullWidth
                            >
                              TABLE {tableNumber}
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() =>
                                handelConfirm(id, name, title, tableNumber)
                              }
                              color="primary"
                              style={{ letterSpacing: 1, fontWeight: "bold" }}
                              fullWidth
                            >
                              Check-in
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                </div>
              </Paper>
            </Grid>
            <Grid item xs={0} md={1}></Grid>
          </Grid>

          <Dialog open={confirm} onClose={handleClose}>
            <DialogContent>
              <Typography variant="subtitle1">
                Confirm guest check-in?
              </Typography>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={updateGuest}
                variant="contained"
                color="success"
                style={{ marginRight: 70 }}
              >
                Yes
              </Button>
              <Button onClick={handleClose} variant="contained" color="error">
                NO
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={tableDetails} onClose={handleClose}>
            <DialogContent>
              <Typography variant="subtitle1">
                {guestTitle} {guestName} table number is {guestTable}
              </Typography>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} variant="contained" color="error">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    </Grid>
  );
};

Checklist.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Checklist);
